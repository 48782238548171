import { Box, Container, Typography } from "@mui/material";
import { useState } from "react";
import logo from '../../../assets/cdc-logo.svg';

export default function AboutUs() {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    }

    /*const autoScrollcontact = (e) =>{
        e.preventDefault();
        const element = document.getElementById("contact-component");
        element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }*/

    return(
        <Container className="public-section-about" maxWidth={false}>
            <figure>
                <img src={logo} alt="Logo Club de Caza"/>
            </figure>
            <Box className="public-section-about-box">
                <Typography variant="h1">Nosotros</Typography>
                <Box className="public-section-about-text">
                    <Typography component='div' style={{ wordWrap: "break-word", fontSize: "17px"}}>
                        <Box fontWeight='700' display='inline'>
                            Club Tierra de Caza es una iniciativa innovadora y líder en digitalización en el sector de la caza. Creemos firmemente que la
                            tecnología cuando respeta la tradición puede ser un gran aliado para los cazadores y los profesionales del sector, ayudándoles a
                            ser más eficientes, precisos y sostenibles.
                        </Box>
                    </Typography>
                    <br/>
                    {open &&
                        <>
                            <Typography>
                                Gracias a nuestra amplia experiencia en el sector de la caza, conocemos las necesidades y los desafíos que enfrentan los cazadores en su
                                día a día. Por eso, hemos desarrollado una amplia gama de soluciones digitales que están diseñadas específicamente para cubrir esas
                                necesidades y superar esos desafíos.
                            </Typography>
                            <br/>
                            <Typography>
                                Una de nuestras soluciones de la que mas orgullosos nos sentimos es nuestra aplicación móvil para cazadores (App) que les permite
                                gestionar sus cacerías de manera mucho más efectiva. La aplicación ofrece una amplia gama de funcionalidades, desde la inscripción en
                                los Sorteos de la Oferta Pública de Caza en la Reservas Nacionales acompañando a nuestros socios durante todo el proceso, acceso a la
                                oferta de caza del Club a precios directos y negociados en bloque, incluso gratuitos, la tramitación de licencias y seguros sin costes
                                adicionales y muchos mas servicios que irás descubriendo.
                            </Typography>
                            <br/>
                            <Typography>
                                También creemos que la comunidad nos fortalece y prueba de ello es que todos nuestros socios reciben la revista Trofeo Caza y
                                Conservación en su domicilio particular, cada mes del año, incluida en la cuota de socio. Ponernos al día sobre nuestra actividad favorita
                                tomando un café después de una larga jornada no tiene precio
                            </Typography>
                            <br/>
                            <Typography>
                                Además de estas soluciones específicas, también hemos creado una amplia gama de productos y servicios que están diseñados para
                                mejorar la experiencia de caza en general. Por ejemplo, hemos desarrollado un sistema para que dispongas de tu documentación de caza
                                en formato digital en todo momento, nuestra herramienta te avisará de su caducidad y te ofrecerá renovarla.
                            </Typography>
                            <br/>
                            <Typography>
                                También hemos creado un sistema, diseñado con herramientas de última generación, de obtención de Puntos Premium que puedes
                                obtener y acumular con tu actividad en el Club, canjeándolos por productos o descuentos en las distintas actividades o servicios que te
                                propondremos. Sólo por ser socio ya tienes tus primeros 20 puntos.
                            </Typography>
                            <br/>
                            <Typography>
                                En nuestra empresa, creemos que la digitalización puede ser un gran aliado para el sector de la caza, ayudándolo a ser más eficiente,
                                preciso y sostenible. Por eso, nos hemos comprometido a seguir desarrollando soluciones digitales innovadoras que estén diseñadas
                                específicamente para el sector de la caza, y que ayuden a los cazadores y a los profesionales del sector a superar los desafíos y
                                aprovechar las oportunidades que se presentan.
                            </Typography>
                            <br/>
                        </>
                    }
                </Box>
            </Box>
            <button className="public-section-about-button" onClick={handleOpen}>{open ? "Cerrar" : "Saber más"}</button>
        </Container>
    );
}