import { Box } from "@mui/material";

export default function Search({defaultValue, categories, text, setText, category, setCategory, searchBlogs}) {

    const changeCategory = (e) => {
        e.persist();
        setCategory(e.target.value);
        searchBlogs(e, text, e.target.value);
    }

    const changeText = (e) => {
        e.persist();
        setText(e.target.value);
    }

    const handleSubmit = (e) => {
        searchBlogs(e, text, category);
    }


    return(
        <>
            <Box component="form" className="public-blog-search" onSubmit={handleSubmit}>
                <div className="public-blog-search-input">
                    <div>
                        {/*<input type="image" src={lupa} border="0" alt="Submit" value="Submit"/>*/}
                        <input type="submit" id="search-submit" value="" />
                        <input name="text" type="text" placeholder="Buscar" defaultValue={text} onChange={changeText}/>
                    </div>
                    <select className="public-blog-search-input-select" name="categories" id="categories" value={category ? category : ""} onChange={changeCategory}>
                        <option value="">{defaultValue ? defaultValue : "Todas las categorías"}</option>
                        {categories?.map((option) => (
                            <option key={"option-" + option.id} value={option.id}>{option.name}</option>
                        ))}
                    </select>
                </div>
            </Box>
        </>
    );
}