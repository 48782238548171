import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import {  Box, Container, Typography, Dialog, DialogActions, DialogContent, DialogContentText, Link as MuiLink} from "@mui/material";

export const CookiesPopUp = () => {
    const navigate = useNavigate();

    const [showPopup, setShowPopup] = useState(false);
    const [open, setOpen] = useState(true);
    const scale = window.innerWidth < 767 ? 1 : 4.1666;
    const location = useLocation();

    useEffect(() => {
        if(location.pathname !== '/documentos-legal'){
            const cookieAccepted = localStorage.getItem("cookieAccepted");
            setShowPopup(!cookieAccepted);
        }   
    }, [location.pathname]);

    function acceptCookies() {
        localStorage.setItem("cookieAccepted", true);
        setShowPopup(false);
    }

    const handleClose = () => {
        setOpen({open: false, msg: ""});
    }
    const handleLinkClick = (pdfSrc) => {
        setShowPopup(false);
        navigate('/documentos-legal', {state:  pdfSrc});
    }

    return(
        <>
        {showPopup  && (
            <Dialog open={showPopup} onClose={handleClose} className="cdc-documents-modal-success">
                <DialogContent className="cdc-documents-modal">
                    <Box className="cdc-documents-modal-cookies">
                    <Typography variant="h3">Aviso de Cookies</Typography>
                        <DialogContentText style={{marginBottom: "20px"}}>{open.msg}</DialogContentText>
                        <Typography style={{marginBottom: "10px"}}>Nosotros y nuestros socios almacenamos o accedemos a información en un dispositivo,
                          tales como cookies, y procesamos datos personales, tales como identificadores únicos e información
                          estándar enviada por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido
                          e información sobre el público, así como para desarrollar y mejorar productos. Con su permiso,
                          nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e identificación
                          mediante las características de dispositivos. Puede hacer clic para otorgarnos su consentimiento a nosotros
                          y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. 
                        </Typography>
                          <div className="checkbox" style={{marginBottom: "20px"}}>
                            <label htmlFor="terms">Al aceptar declaras estar de acuerdo con las 
                                <MuiLink onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/POLITICA+DE+COOKIES.pdf")}> Políticas de Cookies</MuiLink>
                            </label>
                          </div>
                        <DialogActions className="cdc-documents-modal-close">
                            <button type="button" onClick={acceptCookies} variant="contained" style={{background: "#619428",borderColor: "#619428"}}>Aceptar Cookies Necesarias</button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
          )}
        </>
    );
}