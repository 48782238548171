import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import { Edit, SimpleForm, TextInput, DateField, required, TextField, Toolbar, SaveButton, DeleteWithConfirmButton, useRecordContext, useNotify, useRedirect, SelectInput, DateInput } from 'react-admin';
import { useEffect, useState } from "react";
import PreviewIcon from '@mui/icons-material/Preview';
import PreviewDoc from "../dialogs/PreviewDoc";
import { useAuth } from "../../../firebase/Auth";
import { instance } from "../../../utils/axios-config";
import { useParams } from "react-router-dom";

export const DocumentoEdit= () => {

    const params = useParams();
    const [ document, setDocument ] = useState();
    const [ typeFile, setTypeFile ] = useState();
    const [ newFile, setNewFile ] = useState({
        text: "",
        files: [],
    });
    const [ errors, setErrors ] = useState({});
    const [ openPreview, setOpenPreview ] = useState(false);
    const { backofficeToken } = useAuth();

    useEffect(()=>{
        handleLoadFiles();
    },[]);

    const handleLoadFiles = async () => {
        instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
        try{
            //https://b8bxmklrd4.execute-api.eu-west-1.amazonaws.com/pre/documentos/docuemnto/:idDocuemto(URL Encode)
            await instance.get(`/documentos/documento/${encodeURIComponent(params.id)}`).then(function(resp){
                let arr = params.id.split("/"); 
                let txt = arr[arr.length -1];
                let arrTxt = txt.split(".");
                setTypeFile(arrTxt[arrTxt.length -1]);
                setDocument(resp.data);
            });
        }catch(error){
            console.log(error);
        }
    }

    const handleDeleteFile = (fileName) => {
        let temp = newFile.files.filter(function(f) { return f.name !== fileName });
        setNewFile({...newFile, files: temp});
    };

    const validFileType = (file) => {
        let fileType = null;
        let validType = false;
        try{
            fileType = file.type.split("/").pop();
            if(fileType === "pdf" || fileType === "jpg" || fileType === "png" || fileType === "jpeg"){
                validType = true;
            }
        }catch(error){
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "Se ha producido un error al comprobar el archivo."}));
        }finally{
            return validType;
        }
    }

    const handleChangeFile = (e) => {
        if(validFileType(e.target.files[0])){
            let exist = false;
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function () {
                exist = newFile.files.some((f) => {
                    return f.name === e.target.files[0].name;
                });
                if(exist){
                    setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "Ya has adjuntado un archivo con ese nombre."}));
                }else{
                    let temp = [];
                    temp = newFile.files;
                    temp.push({
                        content: reader.result.split(",")[1],
                        formato: "." + e.target.files[0].type.split("/").pop(),
                        name: e.target.files[0].name
                    });
                    setNewFile({...newFile, files: temp});
                    setErrors(errors => ({...errors, errorFile: false, errorFileMsg: ""}));
                }
            };
            reader.onerror = function (error) {
                setErrors(errors => ({...errors, errorFile: true, errorfileMsg: "Vaya, parece que el algo ha salido mal..."}));
            };
        }else{
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "El formato del archivo debe ser jpeg, png o pdf."}));
        }
    };

    const GestionEditToolbar = props => {
        const redirect = useRedirect();
        const notify = useNotify();
        return(<Toolbar {...props} sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <SaveButton alwaysEnable={newFile.files.length > 0}
                mutationOptions={{
                    onSuccess: (resp) => {
                        notify(`El documento se ha actualizado correctamente`, { type: 'success' });
                        redirect('/backoffice-home/documentos');
                    },onError: (error) => {
                        notify(`Ha habido un problema actualizando el documento`, { type: 'error' });
                    },
                }}
                type="button"
            />        
            <DeleteWithConfirmButton label="Eliminar documento" className="cdc-backoffice-button-delete" confirmTitle="¿Borrar documento?"
                confirmContent="Una vez borres este documento se eliminará para siempre, ¿Estás seguro?"
                mutationOptions={{
                    onSuccess: (resp) => {
                        notify(`El documento se ha eliminado correctamente`, { type: 'success' });
                        redirect('/backoffice-home/documentos');
                    },onError: (error) => {
                        notify(`Ha habido un problema eliminando el documento`, { type: 'error' });
                    },
                }}
                type="button"
            />
        </Toolbar>);
    };

    const StatusDocument = () => {
        const record = useRecordContext();
        let estado = "revision";
        if(record.estado.toLowerCase() === "aprobado"){
            estado = "nuevo";
        }else if(record.estado.toLowerCase() === "rechazado"){
            estado = "cerrada";
        }
        return (<TextField className={"edicion-title-status " + estado} source="estado" />);
    }

    const TypeDocument = () => {
        const record = useRecordContext();
        return (
            <>
                {record.tipoDocumento !== "Foto" && record.tipoDocumento !== "Otro" &&
                    <>
                        <DateInput label="Fecha de caducidad" source="fechaCaducidad" validate={[required()]} sx={{marginRight: "1%"}}/>
                        <DateInput label="Fecha de expedición" source="fechaExpedicion" validate={[required()]} sx={{marginLeft: "1%"}}/>
                    </>
                }
            </>
        );
    };

    return(
        <>
            <Edit title="Edición de Documentos" mutationMode="pessimistic" mutationOptions={{ meta: { file: newFile,  } }} sx={{marginLeft: '2%',marginRight : '10%'}}>
                <SimpleForm toolbar={<GestionEditToolbar/>}>
                    <div className="edicion-title">
                        <StatusDocument />
                        <div>
                            <div>
                                <TextField className="edicion-title-titulo" source="tipoDocumento" />
                                <span> - </span>
                                <TextField className="edicion-title-id" id="id-ticket" source="nombre"/>
                            </div>
                        </div>
                    </div>
                    <div className="edicion-ticket-inputs">
                        <div className="edicion-ticket-inputs-0">
                            <span style={{fontWeight:"500"}}>Fecha de subida: </span>
                            <DateField showTime={true} className="edicion-title-fecha" source="fechaSubida" options={{ year: 'numeric', month: 'numeric', day: 'numeric' , hour: 'numeric', minute: 'numeric'}} />
                        </div>
                        
                        <div className="edicion-ticket-inputs-1">
                            <div>
                                <Typography variant="subtitle1" className="edicion-ticket-inputs-typo">Información del documento:</Typography>
                                <div className="edicion-ticket-inputs-1-2">
                                    <TextInput disabled label="Remitente" source="idUsuario" sx={{marginRight: "2%", width:"415px"}}/>
                                </div> 
                                <div className="edicion-ticket-inputs-1-2">
                                    <TextInput label="Nombre del archivo" id="nombre" source="nombre" validate={required()} sx={{marginRight: "2%", width:"415px"}}/>
                                </div>
                                {document ?
                                    <>
                                    <Button label="Previsualizar documento" icon={<PreviewIcon/>} onClick={() => setOpenPreview(true)}>Previsualizar documento</Button>
                                    {typeFile !== "pdf" &&
                                        <Link href={document} className="cdc-backoffice-button-download" underline="none" download>Descargar documento</Link>
                                    }
                                    </>
                                    :
                                    <Box>
                                        <CircularProgress />
                                    </Box>
                                }
                            </div>
                        </div>
                        <div>
                            <div className="edicion-ticket-inputs-1" style={{marginTop: "20px"}}>
                                <SelectInput label="Modificación de estado" source="estado" className="noMarginImp" validate={required()} choices={[
                                    { id: 'Revision', name: 'En Revisión' },
                                    { id: 'Aprobado', name: 'Aprobado' },
                                    { id: 'Rechazado', name: 'Rechazado' },
                                ]}/>
                            </div>
                            <div className="edicion-ticket-inputs-1-2">
                                <TypeDocument />
                            </div>
                        </div>
                    </div>
                </SimpleForm>
            </Edit>
            <PreviewDoc open={openPreview} setOpen={setOpenPreview} document={document} type={typeFile}/>
        </>
    );
};
