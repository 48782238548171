import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { CircularProgress} from "@mui/material";

export const PaymentResult = () => {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    
    const [response, setResponse] = useState();
    useEffect(() => {
        if(!searchParams.get('Ds_MerchantParameters')){
            //navigate("/home");
            let defaultBody={
                Ds_Response: '0000',
                Ds_Date:'',
                Ds_Hour:'',
                Ds_Amount:'9900',
                Ds_Order:''
            };
            setResponse(defaultBody);
            setLoading(false);
        }else{
            decodeSignature();
        }
      }, []);
    

    
    const decodeSignature =  () =>{
        let encode = searchParams.get('Ds_MerchantParameters') ? searchParams.get('Ds_MerchantParameters') : "";
        if(encode !== "" || encode !== undefined){
             let decode = JSON.parse(window.atob(encode));
             if(decode){
                setResponse(decode);
                setLoading(false);
             }
        }
    };
    
    return(
        <>
        
            {loading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <div className="payment">
                        {response.Ds_Response !== "0000" && response.Ds_Response !== undefined && 
                            <h1> Su pago ha sido rechazado, puede contactar con nuestro equipo de soporte si necesita ayuda.</h1>  
                        }
                        {response.Ds_Response === "0000" && 
                            <h1> Su pago ha sido autorizado. A continuación le dejamos la información de su cobro.</h1>
                        }
                        <h3>DATOS DE SU COBRO:</h3>
                        <ul>
                            <li><strong>Fecha de Compra:</strong> {response.Ds_Date.replaceAll('%2F', '-')}</li>
                            <li><strong>Hora de Compra:</strong> {response.Ds_Hour.replace('%3A', ':')}</li>
                            <li><strong>Cantidad:</strong> {response.Ds_Amount.substring(0,response.Ds_Amount.length-2)+","+ response.Ds_Amount.substring(response.Ds_Amount.length-2)+"€"}</li>
                            <li><strong>Código de transacción:</strong> {response.Ds_Order}</li>
                        </ul>
                    </div>
                )
            }
        </>
            
    );

}
