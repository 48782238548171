import { Card } from "@mui/material";
import { ListBase, useRecordContext } from "ra-core";
import { DatagridConfigurable, ExportButton, Pagination, SearchInput, SelectInput, TextInput, TopToolbar, DateField, TextField, SelectColumnsButton, FilterButton, ListToolbar, CreateButton } from "react-admin";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const DocumentosList = (props) => {

    const documentosFilters = [
        <SearchInput label="" className="cdc-backoffice-filters-search" source="q" alwaysOn/>,
        <TextInput label="Nombre" source="nombre" />,
        <SelectInput label="Estado" source="estado" emptyText="-" choices={[
            { id: 'Revision', name: 'En Revisión' },
            { id: 'Aprobado', name: 'Aprobado' },
            { id: 'Rechazado', name: 'Rechazado' },
        ]} />,
        <TextInput label="Tipo de documento" source="tipoDocumento" />
    ];

    const DocumentosListActions = () => (
        <TopToolbar sx={{alignItems:"flex-end", padding: "0 !important"}}>
                <CreateButton />
                <SelectColumnsButton />
                <FilterButton filters={documentosFilters}/>
                <ExportButton/>
        </TopToolbar>
    );

    const MyList = ({children, ...props}) => {
        return (
            <ListBase {...props} perPage={15} hasCreate={true}>
                <ListToolbar className="cdc-backoffice-filters" sx={{alignItems:"center"}}
                    filters={documentosFilters}
                    actions={<DocumentosListActions/>}
                />
                <Card sx={{marginTop: "20px", paddingRight: "16px", paddingLeft: "8px"}}>
                    {children}
                </Card>
                <Pagination rowsPerPageOptions={[5, 10, 15, 25, 50]} />
            </ListBase>
        );
    };

    const MyButton = () => {
        const record = useRecordContext();
        const handleClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let url = window.location.href;
            window.open(url + "/" + encodeURIComponent(record.id), '_blank');
        };
        return <button className="cdc-backoffice-newtab" onClick={(e) => handleClick(e)} type="button"><><OpenInNewIcon /></></button>;
    }

    return(
        <MyList title="Listado de Documentos">
            <DatagridConfigurable bulkActionButtons={false} rowClick="edit" omit={['id']} sx={{
                '& .column-nombre': { overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }
            }}>
                <MyButton label="Abrir nueva pestaña" />
                <DateField label="Fecha de Subida" source="fechaSubida" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
                <TextField label="Tipo de documento" source="tipoDocumento"/>
                <TextField label="Estado" source="estado"/>
                <TextField label="Propietario" source="idUsuario"/>
                <TextField label="Nombre" source="nombre"/>
                <TextField label="Id" source="id"/>
            </DatagridConfigurable>
        </MyList>
    );
}