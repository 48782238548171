import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useDelete, useNotify, useRecordContext, useRefresh } from "ra-core";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "react-admin";
import { useState } from "react";

export default function CloseTicket({open, setOpen, ticket}){

    const [ messageClose, setMessageClose ] = useState("");
    const [ errors, setErrors ] = useState({});

    const changeMessageClose = (e) => {
        setMessageClose(e.target.value);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const validateForm = () => {
        let valid = true;
        if(messageClose === "" || messageClose === undefined || messageClose === null){
            valid = false;
            setErrors(errors => ({...errors, errorMessageClose: true, errorMessageCloseMsg: "Requerido"}));
        }
        return valid;
    }

    const DeleteButton = () => {
        const refresh = useRefresh();
        const [deleteOne, { isLoading }] = useDelete();
        const notify = useNotify();
        const handleClick = () => {
            if(validateForm()){
                deleteOne(
                    'gestiones',
                    { id: ticket.id , previousData: {...ticket, message: messageClose} },
                    {
                        onSuccess: (data) => {
                            // success side effects go here
                            handleClose();
                            refresh();
                            notify('Ticket cerrado correctamente', { type: 'success' });
                        },
                        onError: (error) => {
                            handleClose();
                            notify(`Ha habido un problema cerrando el ticket`, { type: 'error' });
                        },
                    }
                );
            }
        }
        return <Button label="Cerrar Ticket" icon={<CloseIcon/>} className="cdc-backoffice-button-delete" disabled={isLoading} onClick={handleClick}></Button>;
    };
    
    return(
        <Dialog open={open} onClose={handleClose} className="cdc-backoffice-modal-close">
            <DialogContent>
                <Box>
                    <DialogTitle>Por favor, indique el mensaje de cierre para el ticket.</DialogTitle>
                    <TextField InputLabelProps={{ shrink: true }} label="Respuesta de cierre*" placeholder="" multiline minRows={2} maxRows={8} value={messageClose} onChange={changeMessageClose} fullWidth
                        error={errors.errorMessageClose}
                        helperText={errors.errorMessageCloseMsg}/>
                    <DialogActions>
                        <DeleteButton />
                        <Button className="button-toolbar" label="Volver" variant="contained" onClick={handleClose}></Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}