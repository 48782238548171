import { Box, Container, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import unete from "../../../assets/unete.jpg";
import { useState } from "react";
import { instance } from "../../../utils/axios-config";

export default function JoinIn() {

    const [open, setOpen] = useState(false);
    const [ error, setError ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ accepted, setAccepted ] = useState(false);

    const handleFormSubmit = () => {
        let errors = false;
        setError(null);

        if ( !email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors = true;
            setError( 'Por favor, introduce un correo válido.' );
        }else{
            setError(null);
        }

        if(!errors){
            subscribe();
        }
    }

    const subscribe = async () => {
        setLoading(true);
        try{
            await instance.post(`/clubCaza/mailchimpsubscribe`, {email: email}).then(function(resp){
                setAccepted(true);
            });
        }catch(error){
            console.log(error);
        }finally{
            setLoading(false);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleCloseCancel = () => {
        setOpen(false);
        setAccepted(false);
        setError(null);
      };
    

    return(
        <>
            <Container className="public-section-join" maxWidth={false} style={{ backgroundImage: `url(${unete})` }}>
                <Box className="public-section-join-box">
                    <Typography variant="h1">Suscríbete a nuestra newsletter</Typography>
                    <Box>
                        <Box className="public-section-join-text">
                            <Typography sx={{fontWeight: "700"}}>
                                Ingresa al club y empieza a disfrutar de nuestra pasión.
                            </Typography>
                            <br/>
                            <Typography>
                                Estamos comprometidos en mantener a nuestros socios informados sobre nuestras novedades,
                                eventos y promociones relacionados con el mundo de la caza. Una de las herramientas más
                                efectivas que utilizamos para mantener una comunicación fluida y cercana con nuestros
                                seguidores es nuestra newsletter. En ella, compartimos información relevante y actualizada sobre
                                nuestros productos y servicios, así como artículos de interés sobre la caza y la conservación de la
                                fauna. Exploraremos las ventajas que nuestros socios pueden obtener al suscribirse a nuestra
                                newsletter, y cómo esto puede mejorar su experiencia y su relación con el club.
                            </Typography>
                        </Box>
                        <div>
                            <button className="public-section-join-button" onClick={handleClickOpen}>Suscribirme</button>
                            {/*<Dialog open={open} onClose={handleCloseCancel} className="subscribe-newsletter">

                            <MailchimpSubscribe
                                url={ MAILCHIMP_URL }
                                render={ ( props ) => {
                                    const { subscribe, status, message } = props || {};
                                    return (
                                    <NewsletterForm
                                        status={ status }
                                        message={ message }
                                        onValidated={ formData => subscribe( formData ) }
                                        close={handleCloseCancel}
                                    />
                                    );
                                } }
                                />

                            </Dialog>*/}
                        </div>
                    </Box>
                </Box>
            </Container>
            <Dialog open={open} onClose={handleCloseCancel} className="subscribe-newsletter">
                <DialogTitle className="subscribe-newsletter-title">Suscríbete a la Newsletter</DialogTitle>
                <DialogContent className="subscribe-newsletter-content">
                    <DialogContentText>
                        Para poder recibir toda la información relacionada con el club,
                        por favor, introduce los siguientes datos.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Correo Electronico"
                        type="email"
                        fullWidth
                        variant="standard"
                        required
                        onChange={(event) => setEmail(event?.target?.value ?? '')}
                    />
                    {error &&
                        <div className="newsletter-dialog-form-error">
                            {error}
                        </div>
                    }
                    {loading &&
                        <div className="newsletter-dialog-form-inprocess" align="right">Enviando...</div>
                    }
                    {accepted &&
                        <div className="newsletter-dialog-form-inprocess" align="right">¡Te has suscrito correctamente!</div>
                    }
                </DialogContent>
                <DialogActions className="subscribe-newsletter-actions">
                    
                    {accepted &&
                        <Button onClick={handleCloseCancel} className="subscribe-newsletter-button" variant="contained">Cerrar</Button>
                    }
                    {!loading && !accepted &&
                        <>
                            <Button onClick={handleCloseCancel} className="subscribe-newsletter-button" variant="contained">Cerrar</Button>
                            <Button onClick={handleFormSubmit} className="subscribe-newsletter-button" variant="contained">Subscribirme</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}