import { useEffect, useState } from "react";
import { useInstallation } from "../../routes/InstallationProvider";
import appleStore from "../../assets/apple-store.svg";
import InstallAppIos from "../public-components/dialogs/InstallAppIos";

export const InstallAppStore = () => {

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [open, setOpen] = useState(false);
  const [ isIos, setIos ] = useState(false);
  const { setInstalled } = useInstallation();

  const showInstall = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  useEffect(() => {
    if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream){
      setIos(true);
    }
  },[]);


  return(
    <>
      <div>
        <button className="cdc-install-button" type="button" onClick={showInstall}>
            <figure className="apple-store">
                <img src={appleStore} alt="Imagen Apple Store"/>
            </figure>
        </button>
      </div>
      <InstallAppIos open={open} setOpen={setOpen} ios={isIos}/>
    </>
  );
}