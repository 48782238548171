import { Card, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React from 'react';
import { SearchInput, Pagination, TextInput, DatagridConfigurable, TextField, DateField, TopToolbar, SelectColumnsButton, FilterButton, ListBase, ExportButton, ListToolbar, useRecordContext, CreateButton} from 'react-admin';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAdminContext } from '../context/ContextProvider';


export const UsuariosList = () => {

    const {state, setState} = useAdminContext();

    const handleChangeEstado = (event) => {
        setState({...state, estado: event.target.value});
    };

    const usuarioFilters = [
        <SearchInput className="cdc-backoffice-filters-search" source="q" alwaysOn/>,
        <TextInput label="Nombre" source="nombre" defaultValue="" />,
        <TextInput label="Apellido" source="apellido" defaultValue="" />,
        <TextInput label="Email" source="id" defaultValue="" />
    ];
    
    const UsuarioListActions = () => (
        <TopToolbar sx={{alignItems:"flex-end", padding: "0 !important"}}>
                <div>
                    <FormControl className="noMarginImp" sx={{ m: 1, minWidth: 200 }} size="small">
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{borderColor:"#1976d2"}}>Estado</InputLabel>
                        <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        defaultValue={state.estado}
                        autoWidth
                        label="Estado"
                        onChange={handleChangeEstado}
                        >
                        <MenuItem value="">
                        _
                        </MenuItem>
                        <MenuItem value={"Revisión"}>Revisión</MenuItem>
                        <MenuItem value={"Activo"}>Activo</MenuItem>
                        <MenuItem value={"Desactivado"}>Desactivado</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <CreateButton />
                <SelectColumnsButton />
                <FilterButton filters={usuarioFilters}/>
                <ExportButton/>
        </TopToolbar>
    );
    
    const MyList = ({children, ...props}) => (
        <ListBase {...props} perPage={15} filter={{estado: state.estado}}>
            <ListToolbar className="cdc-backoffice-filters" sx={{alignItems:"center"}}
                filters={usuarioFilters}
                actions={<UsuarioListActions/>}
            />
            <Card sx={{marginTop: "20px", paddingRight: "16px", paddingLeft: "8px"}}>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[5, 10, 15, 25, 50]}/>
        </ListBase>
    );

    const MyButton = () => {
        const record = useRecordContext();
        const handleClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let url = window.location.href;
            window.open(url + "/" + record.id,'_blank');
        };
        return <button className="cdc-backoffice-newtab" onClick={(e) => handleClick(e)} type="button"><><OpenInNewIcon /></></button>;
    }


    return(
        <MyList title="Lista de Usuarios">
            <DatagridConfigurable bulkActionButtons={false} rowClick="edit" omit={['id', 'dni', 'provincia', 'poblacion', 'codigoPostal']}>
                <MyButton label="Abrir nueva pestaña" />
                <DateField label="Fecha Modificación" source="fechaModificacion" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
                <TextField disabled label="Email" source="id" />
                <TextField label="Estado" source="estado" />
                <TextField label="Nombre" source="nombre" />
                <TextField label="Apellidos" source="apellido" />
                <TextField label="Telefono" source="nTelefono" />
                <TextField label="Tipo" source="tipo" />
                <TextField label="DNI" source="dni" />
                <TextField label="Nº Socio" source="nroSocio" />
                <TextField label="Provincia" source="provincia" />
                <TextField label="Población" source="poblacion" />
                <TextField label="Codigo Postal" source="codigoPostal" />
                <DateField label="Fecha Creación" source="fechaCreacion" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
            </DatagridConfigurable>
        </MyList>
    );
}
