import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { AutocompleteInput, Create, DateInput, FileField, FileInput, required, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useInput, useNotify, useRecordContext} from "react-admin";
import { useAuth } from "../../../firebase/Auth";
import { instance } from "../../../utils/axios-config";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";
import { compressImageUpload, validFileSize, validFileType } from "../../../services/Commons";
import { useForm } from 'react-hook-form';

export const DocumentoCreate= () => {
    const [ loading, setLoading ] = useState(true);
    const [ users, setUsers ] = useState([]);
    const { backofficeToken } = useAuth();
    const [ newFile, setNewFile ] = useState({
        text: "",
        files: [],
    });
    //const [errors, setErrors] = useState({});
    const [ showDates, setShowDates ] = useState(true);
    const [loadingCompress, setLoadingCompress] = useState(false);


    useEffect(() =>{
        loadUsersData();
    },[]);

    const loadUsersData = async () => {
        setLoading(true);
        instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
        try{
            await instance.get(`/users`).then(function(resp){
                //{ id: 'Revision', name: 'En Revisión' }
                let fixUsers = [];
                resp.data.data.map((u) => {
                    fixUsers.push({id: u.pk, name: u.nombre + " " + u.apellido + " - " + u.pk});
                })
                setUsers(fixUsers);
            });
        }catch(error){
            console.log(error);
        }finally{
            setLoading(false);
        }
    }

    const handleChangeTipo = (event) => {
        if ((event.target.value === "Foto" || event.target.value === "Otro") && showDates === true){
            setShowDates(false);
        }else if((event.target.value !== "Foto" && event.target.value !== "Otro") && showDates === false){
            setShowDates(true);
        }
    }

    const PostCreateToolbar = props => {
        const notify = useNotify();
        const navigate = useNavigate();
    
        return (
            <Toolbar {...props}>
                <SaveButton
                    label="Guardar"
                    mutationOptions={{
                        onSuccess: () => {
                            notify(`El documento se ha creado correctamente`, { type: 'success' });
                            navigate(-1);
                        },
                        onError: (error) => {
                            notify(`Ha habido un problema creando el documento`, { type: 'error' });
                        },
                    }}
                    type="button"
                    disabled={loadingCompress}
                />
            </Toolbar>
        );
    };


    const FileInput = (props) => {

        //const { setValue } = useFormContext();
        //const { ...rest } = props;
        const { setError, formState: {errors} } = useForm();
        const { id, field, fieldState, isRequired } = useInput({ ...props });

        const handleDeleteFile = () => {
            //let temp = newFile.files.filter(function(f) { return f.name !== fileName });
            setNewFile({...newFile, files: [], text: ""});
            field.onChange("");
        };

        const handleChangeFile = async (e) => {
            let result = e.target.files[0];
            let validType = validFileType(e.target.files[0]);
            let validSize = validFileSize(e.target.files[0]);
            if(!validType){
                setError('file', {type: 'custom', message: 'El formato del archivo debe ser png, jpg o pdf.'});
                //setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El formato del archivo debe ser png, jpg o pdf."}));
            }
            else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
                if(validSize){
                    result = readFile(e.target.files[0]);
                }else{
                    setError('file', {type: 'custom', message: 'El tamaño del archivo es demasiado grande. Max: 2MB.'});
                    //setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El tamaño del archivo es demasiado grande. Max: 2MB."}));
                }
            }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
                if(validSize){
                    result = readFile(e.target.files[0]);
                }else{
                    try{
                        setLoadingCompress(true);
                        result = await compressImageUpload(e.target.files[0]);
                    }catch(error){

                    }finally{
                        setLoadingCompress(false);
                        readFile(result);
                    }
                }
            }
        }

        const readFile = (file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                //setErrors(errors => ({...errors, errorContent: false, errorContentMsg: ""}));
                let temp = {
                    content: reader.result.split(",")[1],
                    formato: "." + file.type.split("/").pop(),
                    name: file.name
                };
                console.log(temp);
                setNewFile({
                    text: temp.name,
                    files: [temp],
                });
                field.onChange(temp);
            };
            reader.onerror = function (error) {
                setError('file', {type: 'custom', message: 'Vaya, parece que el algo ha salido mal...'});
                //setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "Vaya, parece que el algo ha salido mal..."}));
            };
        }

    
        return (
            <>
                {loadingCompress ?
                    <Box className="loading-inline">
                        <CircularProgress align="center"/>
                        <p>Comprimiendo imagen...</p>
                    </Box>
                :
                <>

                    {field.value && newFile.files ? 
                        <>
                            <div className="flex-container align-center">
                                <div style={{marginBottom: "12px"}}>
                                    <div className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)"}}>
                                        <div className="flex-container align-center">
                                            <FilePresentIcon style={{ fill: '#1565c0', width: '30px', height: '30px' }}/>
                                            <p>{newFile.text}</p>
                                        </div>
                                        <button className="cdc-backoffice-file-delete" type="button" style={{marginLeft: "5px"}} onClick={() => handleDeleteFile()}><ClearIcon style={{ fill: '#1565c0'}}/></button>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <button type="button" className="cdc-backoffice-file">
                                <input id={id} type="file" {...field} className="cdc-backoffice-file-upload" onChange={handleChangeFile} />
                                
                                <label htmlFor="cdc-backoffice-file-upload" className="cdc-backoffice-file-label" style={{pointerEvents: "none"}}>
                                    <DriveFolderUploadIcon alt={"Subir nuevo documento"}/>
                                    Añadir archivo
                                </label>
                            </button>
                            {fieldState.error && <span style={{color: "#d32f2f", fontSize: "0.75rem"}}>{fieldState.error.message}</span>}
                            <span style={{color: "#d32f2f", fontSize: "0.75rem"}}>{errors.file?.message}</span>
                            {/*errors.errorContent && <span style={{color: "#d32f2f", fontSize: "0.75rem"}}>{errors.errorContentMsg}</span>*/}
                        </>
                    }
                </>
                }
            </>
        );
    };


    const LoadedFile = () => {
        //const record = useRecordContext();
        return(
            <>
                <FileInput label="Añadir archivo" source='file' validate={required()}/>   
            </>
        );
    }

    return(
        <>
            {loading ? (
                <Box align="center">
                    <CircularProgress />
                </Box>
                )
                : 
                (
                    <Create>
                        <SimpleForm toolbar={<PostCreateToolbar />}>
                            <div className="edicion-title">
                                <div className="edicion-title-titulo">
                                    Creación nuevo documento
                                </div>
                            </div>
                            <TextInput source="nombre" validate={[required()]} sx={{width:"50%", marginTop: "20px", minWidth:"415px"}} />
                            <div className="edicion-ticket-inputs">
                                <SelectInput label="Tipo de documento" source="tipo" onChange = {(e) => handleChangeTipo(e)} className="noMarginImp" sx={{width:"50%", marginBottom: "10px", minWidth:"415px"}} validate={required()} choices={[
                                    { id: 'Licencia de armas', name: 'Licencia de armas' },
                                    { id: 'Licencia de caza', name: 'Licencia de caza' },
                                    { id: 'Seguro', name: 'Seguro' },
                                    { id: 'Otro', name: 'Otro' },
                                    { id: 'Foto', name: 'Foto' },
                                    { id: 'DNI', name: 'DNI' },
                                ]}/>
                            </div>
                            
                            <div className="edicion-ticket-inputs">
                                {/*<SelectInput label="Usuario" source="idUsuario" sx={{width:"50%", marginBottom: "10px", minWidth:"415px"}} validate={[required()]} choices={users}/>*/}
                                <AutocompleteInput source="idUsuario" sx={{width:"50%", marginBottom: "10px", minWidth:"415px"}} validate={[required()]} choices={users} emptyText="Sin coincidencias"/>
                            </div>
                            {showDates && 
                                <div className="edicion-ticket-inputs-1-2">
                                    <DateInput label="Fecha de caducidad" source="fechaCaducidad" validate={[required()]} sx={{marginRight: "1%"}}/>
                                    <DateInput label="Fecha de expedición" source="fechaExpedicion" validate={[required()]} sx={{marginLeft: "1%"}}/>
                                </div>
                            }
                            <div className="edicion-ticket-inputs-1">
                                <LoadedFile />                 
                            </div>
                        </SimpleForm>
                    </Create>
                )
            }
        </>
    );
};
