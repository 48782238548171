import { Box, Container, Grid, Link, Typography } from "@mui/material";
import sorteos from "../../../assets/sorteosmin.jpg";

export default function Raffles() {

    return(
        <Container className="public-section-raffles" maxWidth={false}>
            <Box className="public-section-raffles-box">
                <Grid container justifyContent="center">
                    <Grid item xs={12} className="public-section-raffles-grid" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${sorteos})` }}>
                        <Box className="public-section-raffles-grid-container">
                            <Typography variant="h1">SORTEOS</Typography>
                                <Box className="public-section-raffles-text">
                                <Typography component='div' style={{ wordWrap: "break-word", color: "#FFFF", fontSize: "1.1rem"}}>
                                En nuestro &nbsp;
                                    <Box fontWeight='700' display='inline'>
                                    club de caza, &nbsp;
                                    </Box>
                                nos enorgullece ofrecer una amplia gama de servicios y beneficios para
                                nuestros socios, y uno de ellos son nuestros &nbsp; 
                                    <Box fontWeight='700' display='inline'>
                                        Sorteos Tierra de Caza. &nbsp; 
                                    </Box>
                                Estos sorteos son una
                                excelente manera &nbsp;
                                    <Box fontWeight='700' display='inline'>
                                    de recompensar a nuestros socios por su lealtad y su compromiso con el club, &nbsp;
                                    </Box>
                                y de brindarles la oportunidad de ganar increíbles premios relacionados con el mundo de la
                                caza. En esta descripción, profundizaremos en cómo funcionan y en qué consisten los premios que
                                ofrecemos. &nbsp;
                                </Typography>
                            </Box>
                            <Link href="/sorteos">
                                <button className="public-section-raffles-button">Sorteos</button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}