import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import SearchComponent from "../../../components/public-components/search/Search";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Related from "../../../components/public-components/related/Related";
import { RRSS } from "../../../components/public-components/rrss/RRSS";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import {FacebookShareButton,FacebookIcon, WhatsappShareButton,WhatsappIcon, TwitterShareButton, TwitterIcon} from "react-share";
import parse from "html-react-parser";

export const PRafflesDetails = () => {

  const params = useParams();
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [ raffle, setRaffle ] = useState();
  const [ categories, setCategories ] = useState([]);
  const [ category, setCategory ] = useState();
  const [ text, setText ] = useState();
  const [ categorySearch, setCategorySearch ] = useState();
  const [ error, setError ] = useState(false);

  useEffect(() => {
    setCategorySearch(searchParams.get('categories') ? searchParams.get('categories') : "");
    setText(searchParams.get('text') ? searchParams.get('text') : "");
    loadRaffleDetails(params.id);
  },[params, searchParams]);

  const loadRaffleDetails = async (id) => {
    try {
        await instanceWP.get(`/sorteo/${id}?acf_format=standard`).then((response) => {
            let r;
            r = {...response.data.acf, title: response.data.title.rendered, sorteo_categoria: response.data.sorteo_categoria[0]};
            return r;
        }).then(async (r) => {
            if(r.sorteo_categoria){
                await instanceWP.get(`/sorteo_categoria`).then((cat) =>{
                    for (var i = 0; i < cat.data.length; i++) {
                        if(r.sorteo_categoria === cat.data[i].id){
                            r.sorteo_categoria = cat.data[i].name;
                            setCategory(cat.data[i]);
                            break;
                        }
                    }
                    setCategories(cat.data);
                    setRaffle(r);
                });
            }else{
                setRaffle(r);
            }
        });
            
    } catch (error) {
        console.warn(error);
        setError(true);
    } finally {
        setLoading(false);
    }
  };

  const searchRafflesData = (e, textInput, categoryInput) => {
    navigate('/sorteos' + "?text=" + textInput + "&categories=" + categoryInput);
};
  
  return (
    <>
        <Box className="public-blog-details">
            <Box className="public-blog-details-title">
                <Typography variant="h1">Sorteos</Typography>
                <SearchComponent defaultValue={"Todos los sorteos"} categories={categories} text={text} setText={setText} category={categorySearch} setCategory={setCategorySearch} searchBlogs={searchRafflesData}/>
            </Box>
            <Grid className="public-blog-details-container" container justifyContent="center">
                {loading ? (
                            <Box p={3}>
                                <CircularProgress />
                            </Box>
                            )
                            : 
                            (
                                <>
                                {!error ?
                                    <Grid item xs={12}>
                                        {raffle.image &&
                                        <figure>
                                            <img src={raffle.image ? raffle.image : ""} alt={"Imagen " + raffle.title}/>
                                        </figure>
                                        }
                                        <Link href={"/sorteos" + "?categories=" + categorySearch}>
                                            <Typography className="public-blog-details-category">{raffle.sorteo_categoria}</Typography>
                                        </Link>
                                        <Typography variant="h2">{raffle.title}</Typography>
                                        <div className="public-blog-details-container-content">
                                            {parse(raffle.summary)}
                                        </div>
                                        <div className="public-blog-details-container-content">
                                            {parse(raffle.content)}
                                        </div>
                                    </Grid>
                                    :
                                    <div>Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                                }
                                </>
                            )
                }
            </Grid>
        </Box>
        <div class="social-buttons">
        <FacebookShareButton
            url={window.location.href}
        ><FacebookIcon size={36} />
        </FacebookShareButton>
        <WhatsappShareButton
            url={window.location.href}
        ><WhatsappIcon size={36} />
        </WhatsappShareButton>
        <TwitterShareButton
            url={window.location.href}
        ><TwitterIcon size={36} />
        </TwitterShareButton>
        </div>
        <Related type={"sorteo"} excludeId={params.id} catId={category?.id} text={text} category={categorySearch}/>
        <JoinIn />
        <Contact />
    </>
  );
}
