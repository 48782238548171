import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import SearchComponent from "../../../components/public-components/search/Search";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Related from "../../../components/public-components/related/Related";
import { RRSS } from "../../../components/public-components/rrss/RRSS";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import parse from "html-react-parser";

export const POfferDetails = () => {

  const params = useParams();
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [ offer, setOffer ] = useState();
  const [ category, setCategory ] = useState();
  const [ categories, setCategories ] = useState();
  const [ text, setText ] = useState();
  const [ categorySearch, setCategorySearch ] = useState();
  const [ error, setError ] = useState(false);

  useEffect(() => {
    setCategorySearch(searchParams.get('categories') ? searchParams.get('categories') : "");
    setText(searchParams.get('text') ? searchParams.get('text') : "");
    loadOfferDetails(params.id);
  },[params, searchParams]);

  const loadOfferDetails = async (id) => {
    try {
        await instanceWP.get(`/oferta/${id}?acf_format=standard`).then((response) => {
            let o;
            o = {...response.data.acf, title: response.data.title.rendered, oferta_categoria: response.data.oferta_categoria[0], tag_id: response.data.oferta_tag[0]};
            return o;
        }).then(async (o) => {
            const dataCategory = instanceWP.get(`/oferta_categoria`);
            const dataOfferType = instanceWP.get(`/oferta_tag`);
            await Promise.all([dataCategory,dataOfferType]).then(function(values) {
                if(o.oferta_categoria){
                    for (var i = 0; i < values[0].data.length; i++) {
                        if(o.oferta_categoria === values[0].data[i].id){
                            o.oferta_categoria = values[0].data[i].name;
                            setCategory(values[0].data[i]);
                            break;
                        }
                    }
                    setCategories([...values[0].data, ...values[1].data]);
                    setOffer(o);
                }else{
                    setOffer(o);
                }
            });
        });
            
    } catch (error) {
        console.warn(error);
        setError(true);
    } finally {
        setLoading(false);
    }
  };

  const searchOffersData = (e, textInput, categoryInput) => {
    navigate('/ofertas' + "?text=" + textInput + "&categories=" + categoryInput);
  };
  
  return (
    <>
        <Box className="public-blog-details">
            <Box className="public-blog-details-title">
                <Typography variant="h1">{category?.slug === "oferta_publica" ? "Oferta pública" : "Oferta Privada"}</Typography>
                <SearchComponent defaultValue={"Todas las ofertas"} categories={categories} text={text} setText={setText} category={categorySearch} setCategory={setCategorySearch} searchBlogs={searchOffersData}/>
            </Box>
            <Grid className="public-blog-details-container" container justifyContent="center">
                {loading ? (
                        <Box p={3}>
                            <CircularProgress />
                        </Box>
                        )
                        : 
                        (
                            <>
                            {!error ?
                                <Grid item xs={12}>
                                    {offer.image &&
                                    <figure>
                                        <img src={offer.image ? offer.image : ""} alt={"Imagen " + offer.title}/>
                                    </figure>
                                    }
                                    <Link href={"/ofertas" + "?categories=" + category.id}>
                                        <Typography className="public-blog-details-category">{offer.oferta_categoria}</Typography>
                                    </Link>
                                    <Typography variant="h2">{offer.title}</Typography>
                                    <div className="public-blog-details-container-content">
                                        {parse(offer.content)}
                                    </div>
                                </Grid>
                                :
                                <div>Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                            }
                            </>
                        )
                }
            </Grid>
        </Box>
        <RRSS />
        <Related type={"oferta"} excludeId={params.id} catId={category?.id} text={text} category={categorySearch}/>
        <JoinIn />
        <Contact />
    </>
  );
}
