import { AppBar, Box, Button, Link, Toolbar, Typography, Hidden, IconButton, SwipeableDrawer, Divider, List, ListItem} from "@mui/material";
import logo from '../../../assets/cdc-logo.svg';
import logoBlanco from '../../../assets/cdc-logo-footer.svg';
import { useNavigate } from 'react-router-dom';
import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function  Header(){

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const autoScrollcontact = (e) =>{
        e.preventDefault();
        const element = document.getElementById("contact-component");
        element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }

    return(
        <AppBar position="static" className="public-header">
                <Toolbar className="public-toolbar">
                    <figure className="public-toolbar-logo">
                        <Link href="/">
                            <img src={logoBlanco} alt="Logo Club de Caza" className="public-logo"/>
                        </Link>
                    </figure>
                    <Hidden mdDown>
                        <Box className="public-header-box">
                            <div className="public-header-links flex-container row-direction">
                                <Link href="/">
                                    <Typography>NOSOTROS</Typography>
                                </Link>
                                <Link href="/ofertas">
                                    <Typography>OFERTAS DE CAZA</Typography>
                                </Link>
                                <Link href="/blogs">
                                    <Typography>BLOG</Typography>
                                </Link>
                                <Link href="#" onClick={autoScrollcontact}>
                                    <Typography>CONTACTO</Typography>
                                </Link>
                            </div>
                            <Box>
                                <Button variant="contained" className="button-join" onClick={() => navigate("/hacerse-socio")}>HAZTE SOCIO</Button>
                            </Box>
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <Box className="public-header-box-md">
                            <button variant="contained" className="public-header-buttonb-join" onClick={() => navigate("/hacerse-socio")}>Hazte socio</button>
                            <IconButton onClick={() => setOpen(true)}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        
                    </Hidden>
                </Toolbar>
                <SwipeableDrawer anchor="right" open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} PaperProps={{
                    sx:{
                        width: "50%"
                    }
                }}>
                    <div>
                        <IconButton onClick={() => setOpen(false)}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <div className="public-header-linksb">
                            <ListItem>
                                <Link href="/">
                                    <Typography>Nosotros</Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="/ofertas">
                                    <Typography>Ofertas de caza</Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="/blogs">
                                    <Typography>Blog</Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="#" onClick={autoScrollcontact}>
                                    <Typography>Contacto</Typography>
                                </Link>
                            </ListItem>
                        </div>
                    </List>
                </SwipeableDrawer>
            </AppBar>
    );
}