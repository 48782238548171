import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import SearchComponent from "../../../components/public-components/search/Search";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import CPagination from "../../../components/public-components/pagination/Pagination";
import parse from "html-react-parser";

export const POffers = () => {

    const [ searchParams ] = useSearchParams();

    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    const [ offers, setOffers ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ categories, setCategories ] = useState([]);
    const [ text, setText ] = useState();
    const [ category, setCategory ] = useState();

    useEffect(() => {
        setCategory(searchParams.get('categories') ? searchParams.get('categories') : "");
        setText(searchParams.get('text') ? searchParams.get('text') : "");
        loadOffersData(1, searchParams.get('text'), searchParams.get('categories'));
      }, [searchParams]);

    const handleOpen = () => {
        setOpen(!open);
    }

    const loadOffersData = async (startPage = 1, text, categories) => {
        try {
            const dataCategory = instanceWP.get(`/oferta_categoria`);
            const dataOfferType = instanceWP.get(`/oferta_tag`);
            Promise.all([dataCategory,dataOfferType]).then(function(values) {
                let categoryList = [];
                let typeList = [];
                categoryList = values[0].data;
                typeList = values[1].data;
                return {categoryList, typeList};
            }).then(async (args) => {
                let buildUrl = `/oferta?per_page=8&page=${startPage}&acf_format=standard`;
                buildUrl = buildUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);

                if(categories !== null && categories !== "" && categories !== undefined){
                    if(args.categoryList.find(c => categories.includes(c.id))){
                        buildUrl = buildUrl + `&oferta_categoria=${categories}`;
                    }
                    if(args.typeList.find(t => categories.includes(t.id))){
                        buildUrl = buildUrl + `&oferta_tag=${categories}`;
                    }
                }
                await instanceWP.get(buildUrl).then((response) => {
                    let acf = [];
                    response.data.map(r => (
                        acf.push({...r.acf, id: r.id, title: r.title.rendered, categoria_id: r.oferta_categoria[0], oferta_categoria: r.oferta_categoria[0], tag_id: r.oferta_tag[0]})
                    ));
                    let acfCopy = JSON.parse(JSON.stringify(acf));
                    setTotalPages(parseInt(response.headers['x-wp-totalpages']));
    
                    acf.forEach((item, index) => {
                        for (var i = 0; i < args.categoryList.length; i++) {
                            if(item.oferta_categoria === args.categoryList[i].id){
                                acfCopy[index]["oferta_categoria"] = args.categoryList[i].name;
                            }
                        }
                    });
                    setCategories([...args.categoryList, ...args.typeList]);
                    setOffers(acfCopy);
    
                });
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    const searchOffersData = (e, text, category) => {
        e.preventDefault();
        setLoading(true);
        loadOffersData(1, text, category);
    };
  
  
  return (
    <>
        <Box className="public-blog-container">
            <Box className="public-blog-container-text">
                <Typography variant="h1">Ofertas de caza</Typography>
                <Typography>
                    <Box fontWeight='700'>
                        Bienvenido al Club Tierra de de Caza, donde nuestros socios pueden encontrar una amplia
                        variedad de ofertas de caza para disfrutar de su pasión. En nuestro club, ofrecemos diversas
                        opciones para los cazadores, que van desde la caza mayor hasta la caza menor.
                    </Box>
                </Typography>
                <br/>
                {open &&
                <>
                    <Typography>
                    Para aquellos que disfrutan de la emoción de la caza mayor, ofrecemos la oportunidad de cazar
                    todo el abanico de las especies cinegéticas de España, tanto a rececho como en montería, a través
                    de las diferentes formas de contratación del club: Sorteos en Reservas del Estado, subastas de la
                    Oferta Pública de Caza, adjudicación por sorteo de la oferta de grandes fincas con acuerdos con el
                    club, sorteos gratuitos o compra directa.
                    </Typography>
                    <br/>
                    <Typography>
                        Para aquellos que prefieren la caza menor ofrecemos diferentes posibilidades, tanto para ojeos y
                        tiradas como caza en mano.
                    </Typography>
                    <br/>
                    <Typography>
                        Todos los procesos están digitalizados a través de las diferentes herramientas que tienes a tu
                        disposición en nuestra App, por lo que tanto la seguridad como la transparencia de los procesos
                        están garantizadas.
                    </Typography>
                    <br/>
                    <Typography>
                        En resumen, en Club Tierra de Caza ofrecemos una amplia variedad de opciones de caza para
                        nuestros socios. Cada cacería que adquieres tiene un trato personalizado para garantizar la mejor
                        experiencia de caza posible.
                    </Typography>
                    <br/>
                </>
                }
                <button className="public-section-about-button" onClick={handleOpen}>{open ? "Cerrar" : "Saber más"}</button>
                <SearchComponent defaultValue={"Todas las ofertas"} categories={categories} text={text} setText={setText} category={category} setCategory={setCategory} searchBlogs={searchOffersData}/>
            </Box>
            <Grid className="public-blog-container-list" container justifyContent="center" spacing={3}>
                {loading ? (
                    <Box p={3}>
                        <CircularProgress />
                    </Box>
                    )
                    : 
                    (
                        <>
                        {offers.length === 0 ?
                            <div className="public-blog-container-content"><strong>No hay ofertas disponibles.</strong></div>
                            :
                            (
                                offers.map((o) => {
                                    return (
                                        <Grid className="public-blog-container-card" item xs={12} md={offers.length < 3 ? 6 : 3} key={"offer-id-" + o.id}>
                                            <a href={"/oferta/" + o.id + "?text=" + text + "&categories=" + category}>
                                                {o.image &&
                                                <figure>
                                                    <img src={o.image ? o.image : ""} alt={"Imagen " + o.title}/>
                                                </figure>
                                                }
                                                {o.oferta_categoria && 
                                                    <Link className="public-blog-container-item" href="#">
                                                        <Typography className="public-blog-category" onClick={(e) => {
                                                        setCategory(o.categoria_id.toString());
                                                        searchOffersData(e , "", o.categoria_id.toString());
                                                    }
                                                        }>{o.oferta_categoria}</Typography>
                                                    </Link>
                                                }
                                                <Typography variant="h2" title={o.title}>{o.title}</Typography>
                                                <div className="public-blog-container-content">{parse(o.summary)}</div>
                                                <Box className="public-blog-container-read" align="left">
                                                    <Link href={"/oferta/" + o.id + "?text=" + text + "&categories=" + category}>Leer más...</Link>
                                                </Box>
                                            </a>
                                        </Grid>
                                    );
                                  })
                            )
                        }
                        </>
                    )
                }
            </Grid>
            <CPagination setLoading={setLoading} loadData={loadOffersData} totalPages={totalPages} text={text} category={category}/>
        </Box>
        <JoinIn />
        <Contact />
    </>
  );
}






// CODIGO ANTIGUO

/*

import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import SearchComponent from "../../../components/public-components/search/Search";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import CPagination from "../../../components/public-components/pagination/Pagination";
import parse from "html-react-parser";

export const POffers = () => {

    const params = useParams();
    const [ searchParams ] = useSearchParams();

    const [loading, setLoading] = useState(true);
    const [ offers, setOffers ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ categories, setCategories ] = useState([]);
    const [ text, setText ] = useState();
    const [ category, setCategory ] = useState();
    const [ offerType, setOfferType ] = useState();

    useEffect(() => {
        setCategory(searchParams.get('categories') ? searchParams.get('categories') : "");
        setText(searchParams.get('text') ? searchParams.get('text') : "");
        setOfferType(params.type);
        loadOffersData(1, searchParams.get('text'), searchParams.get('categories'));
      }, [searchParams, params]);

    const loadOffersData = async (startPage = 1, text, categories) => {
        try {
            await instanceWP.get(`/oferta_categoria`).then((cat) =>{
                let categoryList = [];
                categoryList = cat.data;

                let type = params.type?.toLowerCase();
                let categoryDefault;
                if((type === "publicas" || type === "privadas") && (categories !== "" && !categories)){
                        categoryDefault = cat.data.find(c => {
                        return c.name.toLowerCase().includes(type.slice(0, -1)) || c.slug.toLowerCase().includes(type.slice(0, -1));
                    });
                    console.log("categoryDefault: " + categoryDefault);
                    setCategory(categoryDefault.id);
                }
                return {categoryList, categoryDefault};
            }).then(async (args) => {
                let buildUrl = `/oferta?per_page=8&page=${startPage}&acf_format=standard`;
                buildUrl = buildUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);
                if(args.categoryDefault){
                    buildUrl = buildUrl + `&oferta_categoria=${args.categoryDefault.id}`;
                }else{
                    buildUrl = buildUrl + (categories !== null && categories !== "" && categories !== undefined ? `&oferta_categoria=${categories}`: ``);
                }
                await instanceWP.get(buildUrl).then((response) => {
                    let acf = [];
                    response.data.map(r => (
                        acf.push({...r.acf, id: r.id, title: r.title.rendered, categoria_id: r.oferta_categoria[0], oferta_categoria: r.oferta_categoria[0]})
                    ));
                    let acfCopy = JSON.parse(JSON.stringify(acf));
                    setTotalPages(parseInt(response.headers['x-wp-totalpages']));
    
                    acf.forEach((item, index) => {
                        for (var i = 0; i < args.categoryList.length; i++) {
                            if(item.oferta_categoria === args.categoryList[i].id){
                                acfCopy[index]["oferta_categoria"] = args.categoryList[i].name;
                            }
                        }
                    });
                    setCategories(args.categoryList);
                    setOffers(acfCopy);
    
                });
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    const searchOffersData = (e, text, category) => {
        e.preventDefault();
        setLoading(true);
        loadOffersData(1, text, category);
    };
  
  
  return (
    <>
        <Box className="public-blog-container">
            <Box className="public-blog-container-text">
                {!offerType ?
                    <Typography variant="h1">Ofertas</Typography>
                    :
                    <Typography variant="h1">Ofertas {offerType === "publicas" && "Públicas"}{offerType === "privadas" && "Privadas"}</Typography>
                }
                
                <Typography>
                    Lorem ipsum dolor sit amet consectetur. Dignissim lorem velit vel aliquam netus scelerisque tempor. Purus integer consequat elementum hendrerit. Integer eleifend mi urna etiam mollis aenean ut at.
                </Typography>
                <SearchComponent defaultValue={"Todas las ofertas"} categories={categories} text={text} setText={setText} category={category} setCategory={setCategory} searchBlogs={searchOffersData}/>
            </Box>
            <Grid className="public-blog-container-list" container justifyContent="center" spacing={3}>
                {loading ? (
                    <Box p={3}>
                        <CircularProgress />
                    </Box>
                    )
                    : 
                    (
                        <>
                        {offers.length === 0 ?
                            <div className="public-blog-container-content"><strong>No hay ofertas disponibles.</strong></div>
                            :
                            (
                                offers.map((o) => {
                                    return (
                                        <Grid className="public-blog-container-card" item xs={12} md={offers.length < 3 ? 6 : 3} key={"offer-id-" + o.id}>
                                            {o.image &&
                                            <figure>
                                                <img src={o.image ? o.image : ""} alt={"Imagen " + o.title}/>
                                            </figure>
                                            }
                                            {o.oferta_categoria && 
                                                <Link className="public-blog-container-item" href="#">
                                                    <Typography className="public-blog-category" onClick={(e) => {
                                                    setCategory(o.categoria_id);
                                                    searchOffersData(e , "", o.categoria_id);
                                                }
                                                    }>{o.oferta_categoria}</Typography>
                                                </Link>
                                            }
                                            <Typography variant="h2" title={o.title}>{o.title}</Typography>
                                            <div className="public-blog-container-content">{parse(o.summary)}</div>
                                            <Box className="public-blog-container-read" align="left">
                                                <Link href={"/oferta/" + o.id + "?text=" + text + "&categories=" + category}>Leer más...</Link>
                                            </Box>
                                        </Grid>
                                    );
                                  })
                            )
                        }
                        </>
                    )
                }
            </Grid>
            <CPagination setLoading={setLoading} loadData={loadOffersData} totalPages={totalPages} text={text} category={category}/>
        </Box>
        <JoinIn />
        <Contact />
    </>
  );
}


*/