import { Box, Button, CircularProgress, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Edit, SimpleForm, TextInput, DateInput, Toolbar, SaveButton, useNotify, useRedirect, TextField, useRecordContext } from 'react-admin';
import { useAuth } from "../../../firebase/Auth";
import { instance } from "../../../utils/axios-config";
import { useFormContext } from "react-hook-form";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

export const SorteosEdit= () => {

    const params = useParams();
    const [ users, setUsers ] = useState([]);
    const [ usersRest, setUsersRest ] = useState([]);
    const [ originalUsers, setOriginalUsers ] = useState([]);
    const [ winners, setWinners ] = useState([]);
    const [ statusRaffle, setStatusRaffle ] = useState();
    const [ selectAllNotIns, setSelectAllNotIns ] = useState(false);
    const [ selectAllIns, setSelectAllIns ] = useState(false);
    const [ enableButton, setEnableButton ] = useState(false);
    const [ buttonAdd, setButtonAdd ] = useState(true);
    const [ buttonRemove, setButtonRemove ] = useState(true);
    const { backofficeToken } = useAuth();
    const [ loading, setLoading ] = useState(true);

    useEffect(() =>{
        loadUsersData();
    },[]);

    const loadUsersData = async () => {
        setLoading(true);
        let allUsers = [];
        let insUsers = [];
        instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
        try{
            Promise.all([instance.get(`/sorteos/unsubscribers/${params.id}`), instance.get(`/sorteos/subscribers/${params.id}`), instance.get(`/sorteos/ganadores/${params.id}`)]).then(function(values) {
                
                values[0].data.map((u) => {
                    if(u.nroSocio !== "" && u.nroSocio !== undefined){
                        allUsers.push({id: u.nroSocio, name: u.nombre + " " + u.apellido + " - " + u.pk, check: false});
                    }
                });
                values[1].data.map((u) => {
                    if(u){
                        insUsers.push({id: u.nroSocio, name: u.nombre + " " + u.apellido + " - " + u.pk, check: false});
                    }
                });
                let sortedInsUsers = insUsers.length > 0 ? insUsers.sort((a, b) => sortByName(a,b)) : [];
                let sortedAllUsers = allUsers.length > 0 ? allUsers.sort((a, b) => sortByName(a,b)) : [];
                setUsers(sortedInsUsers);
                setUsersRest(sortedAllUsers);
                setOriginalUsers(JSON.parse(JSON.stringify(sortedInsUsers)));
                if(values[2].data.length > 0){

                    values[2].data.map((w) => {
                        let find = undefined;
                        find = values[1].data.find((u) => {
                            return (w ? w.idUsuario : null) === (u ? u.pk : null);
                        });
                        if(find){
                            w.nombre = find.nombre;
                            w.apellido = find.apellido;
                            w.nroSocio = find.nroSocio;
                            return w;
                        }
                    });
                    setWinners(values[2].data);
                }
            }).catch(function(err) {
                Swal.fire({
                    icon: "error",
                    title: "error",
                    text: "Se ha producido un error inesperado durante la carga de datos, vuelve a intentarlo más tarde.",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: "Aceptar"
                });
              });
        }catch(error){
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });
        }finally{
            setLoading(false);
        }
    }

    const sortByName = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase(); 
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    const changeCheckIn = (user) => {
        let checkedUsers = users.map((u) => {
            if(u?.id === user?.id){
                u.check = !u.check;
            }
            return u;
        });
        setButtonRemove(!checkedUsers.some((u) => u.check === true));
        setUsers(checkedUsers);
    }

    const changeCheckInUsersNotIns = (user) => {
        let checkedUsers = usersRest.map((u) => {
            if(u?.id === user?.id){
                u.check = !u.check;
            }
            return u;
        });
        setButtonAdd(!checkedUsers.some((u) => u.check === true));
        setUsersRest(checkedUsers);
    }


    const handleSelectAllNotIns = () => {
        setSelectAllNotIns(!selectAllNotIns);
        setUsersRest(usersRest.map((u) => {
            u.check = !selectAllNotIns;
            return u;
        }));
        if(!selectAllNotIns && usersRest.length > 0){
            setButtonAdd(false);
        }else{
            setButtonAdd(true);
        }
    }

    const handleSelectAllIns = () => {
        setSelectAllIns(!selectAllIns);
        setUsers(users.map((u) => {
            u.check = !selectAllIns;
            return u;
        }));
        if(!selectAllIns && users.length > 0){
            setButtonRemove(false);
        }else{
            setButtonRemove(true);
        }
    }

    const areNotEqual = (arr1, arr2) =>{
        let N = arr1.length;
        let M = arr2.length;

        if (N != M){
            return true;
        }
 
        arr1.sort();
        arr2.sort();
 
        for (let i = 0; i < N; i++){
            if (arr1[i] != arr2[i]){
                return true;
            }
        }
            
        return false;
    }

    /*const handleChangeSearchIns = (e) => {
        setSearchIns(e.target.value);
    }*/

    const SorteoEditToolbar = props => {
        const redirect = useRedirect();
        const notify = useNotify();
        return(<Toolbar {...props} sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <SaveButton alwaysEnable={enableButton}
                mutationOptions={{
                    onSuccess: (resp) => {
                        notify(`El sorteo se ha actualizado correctamente`, { type: 'success' });
                        redirect('/backoffice-home/sorteos');
                    },onError: (error) => {
                        notify(`Ha habido un problema actualizando el sorteo`, { type: 'error' });
                    },
                }}
                type="button"
            />
        </Toolbar>);
    };

    const StatusSorteo = () => {
        const record = useRecordContext();
        let estado = "celebrado";
        if(record.estadoSorteo.toLowerCase() === "activo"){
            estado = "activo";
        }
        setStatusRaffle(estado);
        return (<TextField className={"edicion-title-status " + estado} source="estadoSorteo" />);
    }

    const ListUsers = () => {
        const {setValue} = useFormContext();

        const [ searchIns, setSearchIns ] = useState("");

        useEffect(() => {
            setValue("usuarios", users);
        }, [users]);


        return(
            <div className="cdc-sorteos-users-container">
                <Typography variant="subtitle1" className="cdc-sorteos-users-title">Usuarios inscritos:</Typography>
                <div>
                    <div className="flex-container align-center marginBottom10">
                        <figure className="cdc-sorteos-users-check" onClick={handleSelectAllIns}>{selectAllIns ? <CheckBoxIcon className="cdc-sorteos-users-check-img"/>:<CheckBoxOutlineBlankIcon className="cdc-sorteos-users-check-img"/>}</figure>
                        <span className="item" align="left">Seleccionar todos</span>
                    </div>
                    <div>
                        <input type="text" className="cdc-sorteos-users-in-search" placeholder="Buscar..." value={searchIns} onChange={(e) => setSearchIns(e.target.value)}/>
                    </div>
                </div>
                <div className="cdc-sorteos-users-in">
                        {users.length > 0 ? (users.some((u) => u.name.toLowerCase().includes(searchIns.toLowerCase())) ? users.map(u => 
                             {
                                if(searchIns === "" || u.name.toLowerCase().includes(searchIns.toLowerCase())){
                                    return(
                                        <div className="flex-container align-center" key={u.id + "-" + u.name} >
                                            <figure onClick={() => changeCheckIn(u)} className="cdc-sorteos-users-check">{u.check ? <CheckBoxIcon className="cdc-sorteos-users-check-img"/>:<CheckBoxOutlineBlankIcon className="cdc-sorteos-users-check-img"/>}</figure>
                                            <span className="item" align="left">{u.name}</span>
                                        </div>
                                    )
                                }
                             }
                            )
                            :
                            <span style={{color: "grey"}}>No hay coincidencias...</span>
                        )
                        :
                            <span style={{color: "grey"}}>Ningún usuario inscrito...</span>
                        }
                </div>
            </div>
        );
    }

    const ListUsersToAdd = () => {

        const [ searchNotIns, setSearchNotIns ] = useState("");

        return(
            <div className="cdc-sorteos-users-container">
                <Typography variant="subtitle1" className="cdc-sorteos-users-title">Usuarios no inscritos:</Typography>
                <div>
                    <div className="flex-container align-center marginBottom10">
                        <figure className="cdc-sorteos-users-check" onClick={handleSelectAllNotIns}>{selectAllNotIns ? <CheckBoxIcon className="cdc-sorteos-users-check-img"/>:<CheckBoxOutlineBlankIcon className="cdc-sorteos-users-check-img"/>}</figure>
                        <span className="item" align="left">Seleccionar todos</span>
                    </div>
                    <div>
                        <input type="text" className="cdc-sorteos-users-in-search" placeholder="Buscar..." value={searchNotIns} onChange={(e) => setSearchNotIns(e.target.value)}/>
                    </div>
                </div>
                <div className="cdc-sorteos-users-in">
                        {usersRest.length > 0 ? (usersRest.some((u) => u.name.toLowerCase().includes(searchNotIns.toLowerCase())) ? usersRest.map(u => {
                            if(searchNotIns === "" || u.name.toLowerCase().includes(searchNotIns.toLowerCase())){
                                return(
                                    <div className="flex-container align-center" key={u.id + "-" + u.name} >
                                        <figure onClick={() => changeCheckInUsersNotIns(u)} className="cdc-sorteos-users-check">{u.check ? <CheckBoxIcon className="cdc-sorteos-users-check-img"/>:<CheckBoxOutlineBlankIcon className="cdc-sorteos-users-check-img"/>}</figure>
                                        <span className="item" align="left">{u.name}</span>
                                    </div>
                                )
                            }
                            })
                            :
                            <span style={{color: "grey"}}>No hay coincidencias...</span>
                        )
                        :
                            <span style={{color: "grey"}}>Ningún usuario encontrado...</span>
                        }
                </div>
            </div>
        );
    }

    const ButtonsUsers = () => {
        const {setValue} = useFormContext();
        

        const handlePassToIns = () => {
            let usersNotIns = usersRest;
            let usersIns = users;

            let filterUsers = [];
            usersNotIns.map((u) => {
                if(u.check === true){
                    u.check = false;
                    usersIns.push(u);
                }else{
                    filterUsers.push(u);
                }
            });
            usersIns = usersIns.sort((a,b) => sortByName(a,b));
            filterUsers = filterUsers.sort((a,b) => sortByName(a,b));
            setUsers(usersIns);
            setUsersRest(filterUsers);
            setButtonAdd(true);
            setSelectAllIns(false);
            setSelectAllNotIns(false);
            setValue("usuarios",usersIns);
            setEnableButton(areNotEqual(usersIns, originalUsers));
        }

        const handlePassToNotIns = () => {
            let usersNotIns = usersRest;
            let usersIns = users;

            let filterUsers = [];
            usersIns.map((u) => {
                if(u.check === true){
                    u.check = false;
                    usersNotIns.push(u);
                }else{
                    filterUsers.push(u);
                }
            });
            usersNotIns = usersNotIns.sort((a,b) => sortByName(a,b));
            filterUsers = filterUsers.sort((a,b) => sortByName(a,b));
            setUsers(filterUsers);
            setUsersRest(usersNotIns);
            setButtonRemove(true);
            setSelectAllIns(false);
            setSelectAllNotIns(false);
            setValue("usuarios",filterUsers);
            setEnableButton(areNotEqual(filterUsers, originalUsers));
        }

        return(
            <div className="cdc-sorteos-users-buttons">
                <Button disabled={buttonAdd} label="Añadir a inscripción" variant="contained" onClick={handlePassToIns} startIcon={<KeyboardArrowLeftIcon/>}>Añadir</Button>
                <Button disabled={buttonRemove} label="Quitar inscripción" variant="contained" onClick={handlePassToNotIns} endIcon={<KeyboardArrowRightIcon />}>Quitar</Button>
            </div>
        );
    }

    return (
        <>
        {loading ? (
                <Box align="center">
                    <CircularProgress />
                </Box>
            )
            : 
            (
                <Edit title="Detalle del Sorteo" mutationMode="pessimistic" sx={{marginLeft: '2%', marginRight : '10%'}}>
                    <SimpleForm toolbar={<SorteoEditToolbar/>}>
                        <div className="edicion-title">
                            <StatusSorteo />
                            <div>
                                <TextField className="edicion-title-category" source="category" />
                                <span> - </span>
                                <TextField className="edicion-title-id" id="id-ticket" source="titulo"/>
                            </div>
                        </div>
                        <div className="width100">
                            <Typography variant="subtitle1" className="edicion-ticket-inputs-typo">Información del sorteo:</Typography>
                            <div className="edicion-sorteos-inputs">
                                <div className="edicion-sorteos-inputs-1">
                                    <div className="edicion-sorteos-inputs-1-2">
                                        <TextInput disabled label="Nombre del sorteo" source="titulo" sx={{width: "100%"}}/>
                                    </div> 
                                    <div className="edicion-sorteos-inputs-1-2">
                                        <DateInput disabled label="Fecha límite" source="fechaFin" sx={{width: "100%"}}/>
                                    </div>
                                    <div className="edicion-sorteos-inputs-1-2">
                                        <DateInput disabled label="Fecha de creación" source="fechaCreacion" sx={{width: "100%"}}/>
                                    </div>
                                </div>
                                <div className="edicion-sorteos-inputs-2">
                                    <div className="edicion-sorteos-inputs-1-2">
                                        <TextInput disabled label="Categoría del sorteo" id="category" source="category" sx={{width: "100%"}}/>
                                    </div>
                                    <div className="edicion-sorteos-inputs-1-2">
                                        <TextInput disabled label="Número de ganadores" source="adjudicatarios" sx={{width: "100%"}}/>
                                    </div>
                                    <div className="edicion-sorteos-inputs-1-2">
                                        <TextInput disabled label="Precio de inscripción" source="precio" sx={{width: "100%"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row-directionImp edicion-ticket-inputs justify-space break-word">
                            {statusRaffle !== "celebrado" ?
                                <>
                                    <ListUsers />
                                    <ButtonsUsers />
                                    <ListUsersToAdd />
                                </>
                                :
                                <div className="cdc-sorteos-users-container">
                                    <Typography variant="subtitle1" className="cdc-sorteos-users-title">Usuarios inscritos:</Typography>
                                    
                                    {users.length > 0 ?
                                        <ul>
                                            {users.map((u,index) => {
                                                return(
                                                    <li key={"usersins-" + index}>
                                                        {u.name + " - (Nº de socio: " + u.id + ")"}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <span style={{color: "grey"}}>No hay usuarios inscritos</span>
                                    }
                                </div>
                            }
                        </div>
                        {statusRaffle === "celebrado" &&
                            <div className="cdc-sorteos-users-container">
                                <Typography variant="subtitle1" className="cdc-sorteos-users-title">Ganadores:</Typography>
                                
                                    {winners.length > 0 ?
                                        <ul>
                                            {winners.map((w,index) => {
                                                return(
                                                    <li key={"winners-" + index}>
                                                        {w.nombre + " " + w.apellido + " - " + w.idUsuario + " - (Nº de socio: " + w.nroSocio + ")"}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <span style={{color: "grey"}}>No hay ganadores</span>
                                    }
                                    
                            </div>
                        }
                    </SimpleForm>
                </Edit>
            )
        }
        </>
    )
};