import * as React from 'react';
import { Typography, AppBar} from '@mui/material';
import logo from '../../../assets/cdc-logo.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../../firebase/Auth';
import { useNavigate } from "react-router-dom";
import { instance } from '../../../utils/axios-config';



  export function MyAppBar(props){

    const { logoutBackoffice, backofficeToken } = useAuth();
    const navigate = useNavigate();

    const handleLogout = (event) => {
        event.preventDefault();
        logoutBackoffice();
        navigate("/backoffice-login");
    };

    React.useEffect(() => {
      checkToken();
    },[]);

    const checkToken = async () => {
      //Llamamos a un endpoint para comprobar que no nos ha caducado el token
      let buildUrl = `/gestiones/gestionesByStatus/New`;
      instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
      
      await instance.get(buildUrl).then(function(resp) {
      }).catch(function(error){
          console.log(error);
          if(error.response.status === 403){
              handleLogout();
          }
      });
  };

    return (
      <AppBar className="back-appbar">
            <div className="back-container">
              <div className="back-container-left">
                <div>
                  <figure>
                    <a href="/backoffice-home">
                      <img src={logo} alt="Logo Club de Caza" className="back-logo"/>
                    </a>
                  </figure>
                </div>
                <div>
                  <Typography
                      variant="h6"
                      color="inherit"
                    >Gestiones Administrativas</Typography>
                </div>
              </div>
              <div className="back-container-right" onClick={handleLogout} href="#">
                <figure>
                  <LogoutIcon sx={{cursor: "pointer"}}/>
                </figure>
              </div>
            </div> 
      </AppBar>
    );
  } 
