import { Box, CircularProgress, Grid, Link, Typography, Paper } from "@mui/material";
import SearchComponent from "../../../components/public-components/search/Search";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import CPagination from "../../../components/public-components/pagination/Pagination";
import parse from "html-react-parser";
import { useSearchParams } from "react-router-dom";

export const PBlogs = () => {

    const [ searchParams ] = useSearchParams();
    const [ loading, setLoading ] = useState(true);
    const [ blogs, setBlogs ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ categories, setCategories ] = useState([]);
    const [ text, setText ] = useState();
    const [ category, setCategory ] = useState();

    useEffect(() => {
        setCategory(searchParams.get('categories') ? searchParams.get('categories') : "");
        setText(searchParams.get('text') ? searchParams.get('text') : "");
        loadBlogsData(1, searchParams.get('text'), searchParams.get('categories'));
      }, [searchParams]);

    const loadBlogsData = async (startPage = 1, text, categories) => {
        let buildUrl = `/noticia?per_page=8&page=${startPage}&acf_format=standard`;
        buildUrl = buildUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);
        buildUrl = buildUrl + (categories !== null && categories !== "" && categories !== undefined ? `&noticia_categoria=${categories}`: ``);
        try {
            await instanceWP.get(buildUrl).then((response) => {
                let acf = [];
                response.data.map(r => (
                    acf.push({...r.acf, id: r.id, title: r.title.rendered, categoria_id: r.noticia_categoria[0], noticia_categoria: r.noticia_categoria[0]})
                ));
                let acfCopy = JSON.parse(JSON.stringify(acf));
                setTotalPages(parseInt(response.headers['x-wp-totalpages']));

                return {acf, acfCopy};

            }).then(async (args) => {
                await instanceWP.get(`/noticia_categoria`).then((cat) =>{
                    args.acf.forEach((item, index) => {
                        for (var i = 0; i < cat.data.length; i++) {
                            if(item.noticia_categoria === cat.data[i].id){
                                args.acfCopy[index]["noticia_categoria"] = cat.data[i].name;
                            }
                        }
                    });
                    setCategories(cat.data);
                    setBlogs(args.acfCopy);
                });
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    const searchBlogsData = (e, text, category) => {
        e.preventDefault();
        setLoading(true);
        loadBlogsData(1, text, category);
    };
  
  return (
    <>
        <Box className="public-blog-container">
            <Box className="public-blog-container-text">
                <Typography variant="h1">Blog / Noticias</Typography>
                <Typography>
                Te ofrecemos la mayor oferta cinegética de España sin ánimo de lucro en la venta de caza.
                </Typography>
                <Typography>
                Desde el mismo momento de tu alta como socio del club tienes acceso a la oferta privada del club de recechos y monterías en las mejores fincas del panorama nacional, tanto por el procedimiento de compra directa como de sorteo.
                </Typography>
                <SearchComponent defaultValue={"Todas las categorias"} categories={categories} text={text} setText={setText} category={category} setCategory={setCategory} searchBlogs={searchBlogsData}/>
            </Box>
            <Grid className="public-blog-container-list" container justifyContent="center" spacing={3}>
                {loading ? (
                    <Box p={3}>
                        <CircularProgress />
                    </Box>
                    )
                    : 
                    (
                        <>
                        {blogs.length === 0 ?
                            <div className="public-blog-container-content"><strong>No se han encontrado noticias/blogs.</strong></div>
                            :
                            (
                                blogs.map((b) => {
                                    return (
                                            <Grid className="public-blog-container-card" item xs={12} md={blogs.length < 3 ? 6 : 3} key={"blog-id-" + b.id}>
                                                <a href={"/blog/" + b.id + "?text=" + text + "&categories=" + category}>
                                                    {b.image &&
                                                    <figure>
                                                        <img src={b.image ? b.image : ""} alt={"Imagen " + b.title}/>
                                                    </figure>
                                                    }
                                                    {b.noticia_categoria && 
                                                        <Link className="public-blog-container-item" href="#">
                                                            <Typography className="public-blog-category" onClick={(e) => {
                                                                setCategory(b.categoria_id);
                                                                searchBlogsData(e , "", b.categoria_id);
                                                            }
                                                                }>{b.noticia_categoria}</Typography>
                                                        </Link>
                                                    }
                                                    <Typography variant="h2" title={b.title}>{b.title}</Typography>
                                                    <div className="public-blog-container-content">{parse(b.summary)}</div>
                                                    <Box className="public-blog-container-read" align="left">
                                                        <Link href={"/blog/" + b.id + "?text=" + text + "&categories=" + category}>Leer más...</Link>
                                                    </Box>
                                                </a>
                                            </Grid>
                                    );
                                })
                            )
                        }
                        </>
                    )
                }
            </Grid>
            <CPagination setLoading={setLoading} loadData={loadBlogsData} totalPages={totalPages} text={text} category={category}/>
        </Box>
        <JoinIn />
        <Contact />
    </>
  );
}
