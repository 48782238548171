import { Container, Grid, Typography } from "@mui/material";
import fondo from "../../../assets/fondo_bloque2.png";
import { InstallAppStore } from "../../installationapp/InstallAppStore";
import { InstallPlayStore } from "../../installationapp/InstallPlayStore";

export default function JoinMember() {
    return (
        <Container className="public-section-joinmembers" maxWidth={false} style={{ backgroundImage: `url(${fondo})`, backgroundSize: "cover" , padding: "0"}}>
            <Grid className="public-section-joinmembers-box" container justifyContent="center" spacing={0.5}>
                <Grid className="public-section-joinmembers-box-appd" >
                    <Typography variant="h1">¡Descarga nuestra App!</Typography>
                    <div className="public-section-joinmembers-box-appd-sum">
                        <div className="public-section-joinmembers-box-appd-sub">
                            <Typography>Utilizamos todas las ventajas que nos proporciona el gran volumen que manejamos.</Typography>
                            <Typography>Gestionamos tu participación en la oferta Pública de Caza y te acompañamos durante todo el proceso. </Typography>
                            <Typography>Negociamos directamente la oferta privada con los propietarios y profesionales, obteniendo las mejores condiciones posibles, que son las tuyas.</Typography>
                        </div>
                        <Grid className="public-section-joinmembers-box-stores" container>
                            <div className="public-section-joinmembers-box-stores-links">
                                <InstallAppStore />
                                <InstallPlayStore />
                                {/*<Link href="https://play.google.com/store/apps/details?id=com.amplifyapp.d3l19eidgr2o8q.main.twa">
                                    <figure className="google-store">
                                        <img src={googleStore} alt="Imagen Google Play"/>
                                    </figure>
                                </Link>*/}
                            </div>
                            
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}