import linkedin from "../../../assets/linkedin_orange.svg";
import twitter from "../../../assets/twitter_orange.svg";
import facebook from "../../../assets/facebook_orange.svg";
import instagram from "../../../assets/instagram_orange.svg";
import { Box, Container, Link } from "@mui/material";

export const RRSS = () => {

    return(
        <Container className="public-rrss" maxWidth={false}>
            <Link href="https://es.linkedin.com/company/club-tierra-de-caza/" target="_blank" rel="noopener noreferrer">
                <figure>
                    <img src={linkedin} alt="Imagen enlace a LinkedIn"/>
                </figure>
            </Link>
            <Link href="https://twitter.com/tierradecaza" target="_blank" rel="noopener noreferrer">
                <figure>
                    <img src={twitter} alt="Imagen enlace a Twitter"/>
                </figure>
            </Link>
            <Link href="https://es-es.facebook.com/clubtierradecaza/" target="_blank" rel="noopener noreferrer">
                <figure>
                    <img src={facebook} alt="Imagen enlace a Facebook"/>
                </figure>
            </Link>
            <Link href="https://www.instagram.com/clubtierradecaza/" target="_blank" rel="noopener noreferrer">
                <figure>
                    <img src={instagram} alt="Imagen enlace a Instagram"/>
                </figure>
            </Link>
        </Container>
    );
}