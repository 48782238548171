import { createContext, useContext, useMemo, useState } from "react";

const AdminContext = createContext();

export default function ContextProvider({children}) {

    const [ state, setState ] = useState({
        estado: "Activo",
    });

    const contextValue = useMemo(() => {
        return {
          state,
          setState
        };
      }, [state]);


    return (
        <AdminContext.Provider value={contextValue}>
            {children}
        </AdminContext.Provider>
    );
}

export const useAdminContext = () => useContext(AdminContext);