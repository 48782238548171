import { Box, Button, Container, Typography } from "@mui/material";
import { useState, useRef } from "react";
import fedora from "../../../assets/fedora.jpg";
import { instanceAWS } from "../../../utils/axios-config";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import Swal from "sweetalert2";

export default function Contact() {

    const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [surname, setSurname] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [token, setToken] = useState("");
    const [error, setError] = useState(false);
    const captcha = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(!token){
            setError(true);
        }else{
            sendForm();
        }

    };


    const sendForm = async () => {
        const body = {
            idUsuario: email,
            nombre: name,
            apellido: surname,
            nTelefono: phone,
            tipoGestion: "Contacto",
            titulo: "Contacto",
            mensaje: message
        };


        setIsPending(true);

        try {
            await instanceAWS.post(`/contacto/`, body).then(() => {
                captcha.current.resetCaptcha();
                setToken("");
                Swal.fire({
                    icon: 'success',
                    title: 'Consulta Enviada correctamente',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Error en la petición. vuelve a intentarlo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
              })
        } finally{
            setIsPending(false);
            setError(false);
        }
    }

    return(
        <Container id="contact-component" className="public-section-contact" maxWidth={false}>
            <Box className="public-section-contact-box" justifyContent="center">
                <figure>
                    <img src={fedora} alt="Imagen formulario de contacto" />
                </figure>
                <Box className="public-section-contact-box-form">
                    <Box component="form" onSubmit={handleSubmit}>
                        <Typography variant="h2">Contacto</Typography>
                        <Box textAlign="center">
                            <div className="public-section-contact-input">
                                <label htmlFor="name">Nombre*</label>
                                <input type="text" id="name"name="name"
                                    value={name} onChange={(e) => setName(e.target.value)} required/>
                            </div>
                            <div className="public-section-contact-input">
                                <label htmlFor="name">Apellidos*</label>
                                <input type="text" id="surname" name="surname"
                                 value={surname} onChange={(e) => setSurname(e.target.value)} required/>
                            </div>
                            <div className="public-section-contact-input">
                                <label htmlFor="phone">Teléfono*</label>
                                <input type="tel" id="phone" name="phone"
                                 value={phone} minLength="9" maxLength="9" pattern="[0-9]{9}" onChange={(e) => setPhone(e.target.value)} required/>
                            </div>
                            <div className="public-section-contact-input">
                                <label htmlFor="email">Email*</label>
                                <input type="email" id="email" name="email"
                                 value={email} onChange={(e) => setEmail(e.target.value)} required/>
                            </div>
                            <div className="public-section-contact-input">
                                <label htmlFor="message">Mensaje*</label>
                                <textarea id="message" name="message"
                                 value={message} onChange={(e) => setMessage(e.target.value)} required/>
                            </div>
                            <HCaptcha
                                ref={captcha}
                                sitekey={CAPTCHA_KEY}
                                onVerify={token => {setToken(token); setError(false)}}
                                onExpire={e => setToken("")}
                            />
                            {error && <span className="public-section-contact-error">Debe completar el Captcha.</span>}
                            { !isPending && <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                Enviar
                            </Button>}
                            { isPending && <Button variant="contained" fullWidth disabled={true}>Enviando...</Button>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}