import { Backdrop, CircularProgress, Typography } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Edit, SimpleForm, TextInput, Labeled, SelectInput, required, TextField, DateField, Toolbar, SaveButton, useEditController, useNotify, DateTimeInput, DeleteWithConfirmButton, DateInput, useRecordContext, NumberInput } from 'react-admin';
import { useAuth } from "../../../firebase/Auth";
import { instance } from "../../../utils/axios-config";
import { useFormContext } from "react-hook-form";

export const UsuariosEdit= () => {

    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    const notify = useNotify();
    const [premium, setPremium] = useState();
    const [open, setOpen] = useState(false);
    const [renewalDate, setRenewalDate] = useState(aYearFromNow);
    const [nroSocio, setNroSocio] = useState();
    const { isLoading } = useEditController({ mutationMode: "pessimistic"});
    const { backofficeToken } = useAuth();

    const UserEditToolbar = props => (
        <Toolbar {...props} className="justify-space">
            <SaveButton 
                mutationOptions={{
                    onSuccess: (resp) => {
                        console.log(resp);
                    }}
                }
            />
            <DeleteWithConfirmButton className="cdc-backoffice-button-delete"
                confirmContent="Una vez borres este usuario será eliminado para siempre, ¿Estás seguro de que quieres borrarlo para siempre?"
            />
        </Toolbar>
    );

    /* isPremium = () => {
        if (document.getElementById("tipo").textContent === "Premium"){
            setPremium(true);
        }else{
            setPremium(false);
        }
    }*/

    /*const isPremium = () => {
        const record = useRecordContext();
        if(record.tipo.toLowerCase() === "premium"){
            setPremium(true);
        }else if(record.tipo.toLowerCase() === "basic"){
            setPremium(false);
        }
    }*/

    const validateCodigoPostal = (codigoPostal) => {
        let result = undefined;
        if(codigoPostal !== "" && codigoPostal !== null && codigoPostal !== undefined && !/^[0-9]{5}$/i.test(codigoPostal)){
            result = "El código postal debe tener 5 dígitos";
        }
        return result;
    }

    const PremiumForm1 = () => {
        const record = useRecordContext();
        if(premium === undefined && record.tipo.toLowerCase() === "premium"){
            setPremium(true);
        }else if(premium === undefined && record.tipo.toLowerCase() === "basic"){
            setPremium(false);
        }
        if(premium === true){
            return(
                <>
                    <TextInput source="dni" validate={[required(), validateDni]}/>
                    <TextInput source="direccion"/>
                    <SelectInput source="provincia" className="noMarginImp" emptyText="-" choices={[
                            { id: 'Álava/Araba', name: 'Álava/Araba' },
                            { id: 'Albacete', name: 'Albacete' },
                            { id: 'Alicante', name: 'Alicante' },
                            { id: 'Almería', name: 'Almería' },
                            { id: 'Asturias', name: 'Asturias' },
                            { id: 'Ávila', name: 'Ávila' },
                            { id: 'Badajoz', name: 'Badajoz' },
                            { id: 'Basic', name: 'Basic' },
                            { id: 'Baleares', name: 'Baleares' },
                            { id: 'Barcelona', name: 'Barcelona' },
                            { id: 'Burgos', name: 'Burgos' },
                            { id: 'Cáceres', name: 'Cáceres' },
                            { id: 'Cádiz', name: 'Cádiz' },
                            { id: 'Cantabria', name: 'Cantabria' },
                            { id: 'Castellón', name: 'Castellón' },
                            { id: 'Ceuta', name: 'Ceuta' },
                            { id: 'Ciudad Real', name: 'Ciudad Real' },
                            { id: 'Córdoba', name: 'Córdoba' },
                            { id: 'Cuenca', name: 'Cuenca' },
                            { id: 'Gerona/Girona', name: 'Gerona/Girona' },
                            { id: 'Granada', name: 'Granada' },
                            { id: 'Guadalajara', name: 'Guadalajara' },
                            { id: 'Guipúzcoa/Gipuzkoa', name: 'Guipúzcoa/Gipuzkoa' },
                            { id: 'Huelva', name: 'Huelva' },
                            { id: 'Huesca', name: 'Huesca' },
                            { id: 'Jaén', name: 'Jaén' },
                            { id: 'La Coruña/A Coruña', name: 'La Coruña/A Coruña' },
                            { id: 'La Rioja', name: 'La Rioja' },
                            { id: 'Las Palmas', name: 'Las Palmas' },
                            { id: 'León', name: 'León' },
                            { id: 'Lérida/Lleida', name: 'Lérida/Lleida' },
                            { id: 'Lugo', name: 'Lugo' },
                            { id: 'Madrid', name: 'Madrid' },
                            { id: 'Málaga', name: 'Málaga' },
                            { id: 'Melilla', name: 'Melilla' },
                            { id: 'Murcia', name: 'Murcia' },
                            { id: 'Navarra', name: 'Navarra' },
                            { id: 'Palencia', name: 'Palencia' },
                            { id: 'Pontevedra', name: 'Pontevedra' },
                            { id: 'Salamanca', name: 'Salamanca' },
                            { id: 'Segovia', name: 'Segovia' },
                            { id: 'Sevilla', name: 'Sevilla' },
                            { id: 'Soria', name: 'Soria' },
                            { id: 'Tarragona', name: 'Tarragona' },
                            { id: 'Tenerife', name: 'Tenerife' },
                            { id: 'Teruel', name: 'Teruel' },
                            { id: 'Toledo', name: 'Toledo' },
                            { id: 'Valencia', name: 'Valencia' },
                            { id: 'Valladolid', name: 'Valladolid' },
                            { id: 'Vizcaya/Bizkaia', name: 'Vizcaya/Bizkaia' },
                            { id: 'Zamora', name: 'Zamora' },
                            { id: 'Zaragoza', name: 'Zaragoza' }
                    ]} />
                    <TextInput source="iban"/>
                    <NumberInput source="puntos" validate={validatePoints}/>
                </>
            );
        }
    }

    const PremiumForm2 = () => {
        const record = useRecordContext();
        if(premium === undefined && record.tipo.toLowerCase() === "premium"){
            setPremium(true);
        }else if(premium === undefined && record.tipo.toLowerCase() === "basic"){
            setPremium(false);
        }
        if(premium === true){
            return(
                <>
                    <TextInput id="user-id-nroSocio" InputLabelProps={{ shrink: true }} disabled source="nroSocio" defaultValue={nroSocio}/>
                    <TextInput validate={validateCodigoPostal} source="codigoPostal"/>
                    <TextInput source="poblacion"/>
                    <ShowFechaRenovacion/>
                </>
            );
        }
    }

    const handleChangeTipo = (event) => {
        if (event.target.value === "Premium"){
            getNroSocio();
            setPremium(true);
        }else{
            setPremium(false);
        }
    }

    const getNroSocio = async () => {
        setOpen(true);
        instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
        try{
            await instance.get(`/clubCaza/nroSocio`).then(function(resp){
                setPremium(true);
                setNroSocio(resp.data);
            });
        }catch(error){
            //firstEvent.target.value = "Basic";
            setPremium(false);
            notify(`Se ha producido un error al intentar obtener el código de usuario`, { type: 'error' });
        }finally{
            setOpen(false);
        }
    }

    useEffect(() => {
        if(!isLoading){
            //isPremium();
        }
    }, []);

    const validatePhone = (value) => {
        const error = {};
        if (!/^[0-9]{9}$/i.test(value) && value) {
            error.message = 'El teléfono debe contener 9 dígitos';
            return error;
        }
        return undefined;
    }

    const validatePoints = (value) => {
        const error = {};
        if (value < 0 && value) {
            error.message = 'Los puntos deben ser valores positivos';
            return error;
        }
        return undefined;
    }

    const validateFechaRenovacion = (fecha) => {
        const inputDate = new Date(fecha);
        const currentDate = new Date();
        if(inputDate.getTime() < currentDate.getTime()){
            return "La fecha de renovación debe ser mayor que la fecha actual";
        }
        return undefined;
    }

    const validateDni = (dni) => {
        let numero;
        let letr;
        let letra;
        let expresion_regular_dni;
        let result = "Debe ser un DNI válido";
       
        expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
       
        if(expresion_regular_dni.test(dni) === true){
           numero = dni.substr(0,dni.length-1);
           letr = dni.substr(dni.length-1,1);
           numero = numero % 23;
           letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
           letra = letra.substring(numero,numero+1);
          if (letra===letr.toUpperCase()) {
             result = undefined;
           }
        }
        return result;
    }

    /*
    const validateIban = (iban) => {
        if(!/^[A-Z]{2}[0-9A-Z]*$/.test(iban)){
            return "El iban no es válido";
        }
        return undefined;
    }
    */

    const ShowFechaRenovacion = () => {
        const {setValue, getValues} = useFormContext();
        useEffect(() => {
            if(!getValues("fechaRenovacion")){
                setValue("fechaRenovacion", renewalDate);
            }
        }, []);


        return(
            <>
            <DateInput label="Fecha de renovación" source="fechaRenovacion" validate={[required(), validateFechaRenovacion]}/>
            </>
        )
    }

    return(
        <>
            <Edit title="Detalle del Usuario" mutationMode="pessimistic" sx={{marginLeft: '2%', marginRight : '10%', marginTop: '50px'}}>
                <SimpleForm toolbar={<UserEditToolbar/>}>
                    <div className="edicion-title">
                        <Typography variant="h4" align="center" sx={{fontSize: "2rem"}}>Edición de Usuario</Typography>
                    </div>
                    <div className="edicion-user-inputs">
                        <div className="edicion-user-inputs-1">
                            <Labeled sx={{marginBottom: "50px"}}>
                                <TextField required label="CORREO:" source="id" validate={[required()]} sx={{borderBottom: "2px solid #F2F2F2", fontWeight:"600", fontSize:"1rem"}}/>
                            </Labeled>
                            <TextInput source="nombre" validate={[required()]} />
                            <SelectInput id="tipo" source="tipo" onChange = {(e) => handleChangeTipo(e)} validate={required()} choices={[
                                { id: 'Premium', name: 'Premium' },
                                { id: 'Basic', name: 'Basic' },
                            ]} />
                            <TextInput label="Telefono "source="nTelefono" validate={[required(), validatePhone]}/>
                            <PremiumForm1 />
                        </div>
                        <div className="edicion-user-inputs-2">
                            <Labeled sx={{marginBottom: "50px"}}>
                                <DateField label="FECHA DE CREACIÓN:" validate={[required()]} source="fechaCreacion" sx={{borderBottom: "2px solid #F2F2F2", fontWeight:"600", fontSize:"1rem"}} showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
                            </Labeled>
                            <TextInput source="apellido" validate={[required()]} />
                            <SelectInput source="estado" validate={[required()]} choices={[
                                    { id: 'Revision', name: 'Revision' },
                                    { id: 'Activo', name: 'Activo' },
                                    { id: 'Desactivado', name: 'Desactivado' },
                                ]} />
                            <DateTimeInput disabled label="Fecha de Modificación" source="fechaModificacion" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
                            <PremiumForm2 />
                            <TextInput source="promoCode"/>
                        </div>
                    </div>
                </SimpleForm>
            </Edit>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress style={{'color': 'white'}}/>
            </Backdrop>
        </>
    );
}
    
