import { Navigate } from "react-router-dom";
import { useAuth } from "../firebase/Auth";

export const BackofficeRoute = ({ children }) => {

    const { backofficeToken } = useAuth();

    if(!backofficeToken){ 
        return <Navigate to="/backoffice-login" />;
    }
    return children;

}