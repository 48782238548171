import { Button, Card, Toolbar } from "@mui/material";
import { ListBase, useRecordContext } from "ra-core";
import { DatagridConfigurable, ExportButton, useGetList, Pagination, SearchInput, SelectInput, TextInput, TopToolbar, DateField, TextField, SelectColumnsButton, FilterButton, ListToolbar } from "react-admin";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { createSearchParams, useNavigate } from "react-router-dom";

export const HilosList = (props) => {

    const navigate = useNavigate();

    const hilosFilters = [
        <SearchInput label="" className="cdc-backoffice-filters-search" source="q" alwaysOn/>,
        <TextInput label="Remitente" source="idRemitente" />,
        <TextInput label="Mensaje" source="mensaje" />
    ];

    const HilosListActions = () => (
        <TopToolbar sx={{alignItems:"flex-end", padding: "0 !important"}}>
                <SelectColumnsButton />
                <FilterButton filters={hilosFilters}/>
                <ExportButton/>
        </TopToolbar>
    );

    const MyList = ({children, ...props}) => {
        return (<ListBase {...props} perPage={15} >
            <ListToolbar className="cdc-backoffice-filters" sx={{alignItems:"center"}}
                filters={hilosFilters}
                actions={<HilosListActions/>}
            />
            <Card sx={{marginTop: "20px", paddingRight: "16px", paddingLeft: "8px"}}>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[5, 10, 15, 25, 50]} />
        </ListBase>);
    };

    const MyButton = () => {
        const record = useRecordContext();
        const handleClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let url = window.location.href;
            window.open(url + "/" + record.id + "?idGestion=" + record.idGestion,'_blank');
        };
        return <button className="cdc-backoffice-newtab" onClick={(e) => handleClick(e)} type="button"><><OpenInNewIcon /></></button>;
    }

    const callEdit = (id, resource, record) => {
        navigate({pathname:`/backoffice-home/${resource}/${id}`, search:`?${createSearchParams({ idGestion: record.idGestion })}`}, { state : record});
    }

    return(
        <MyList title="Lista de Respuestas">
            <DatagridConfigurable bulkActionButtons={false} rowClick={callEdit} omit={['idGestion','id']} sx={{
                '& .column-mensaje': { overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }
            }}>
                <MyButton label="Abrir nueva pestaña" />
                <DateField label="Fecha de Creacion" source="fechaCreacion" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
                <TextField label="Remitente" source="idRemitente"/>
                <TextField label="Mensaje" source="mensaje"/>
                <TextField label="Id Gestión" source="idGestion"/>
                <TextField label="Id Hilo" source="id"/>
            </DatagridConfigurable>
        </MyList>
    );
}