import { alpha, AppBar, Box, IconButton, InputBase, styled, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from "react-router-dom";
import LateralMenu from "./LateralMenu";
import { useEffect, useState } from "react";
import { CONFIG } from "../../utils/pages-config";
import notificacion from "../../assets/notificacion.svg";
import notificacion_in from "../../assets/notificacion_in.svg";
import retroceder from "../../assets/retroceder.svg";
import "../../pages/styles/styleApp.scss";
import "../styles/Components.scss";
import { instance } from "../../utils/axios-config";
import { useAuth } from "../../firebase/Auth";
import logo from '../../assets/cdc-logo-footer.svg';

export default function Header(props) {

    const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
    const [ gotNotifications, setGotNotifications ] = useState(false);
    const { currentUser, sessionToken, logout } = useAuth();
    const navigate = useNavigate();
    const breadcrumbs = CONFIG.pages.find((f) => f.slug === props?.page);

    useEffect(() => {
        if(currentUser.tipo.toLowerCase() === "premium"){
            checkNotifications();
        }
    }, []);

    const checkNotifications = async () => {
        let buildUrl = `/notifications/leidas/${currentUser.pk}`;
        
        instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
        await instance.get(buildUrl).then(function(resp) {
            if(resp.data === true){
                setGotNotifications(true);
            }
        }).catch(function(error){
            console.log(error);
            if(error.response.status === 403){
                logout();
                navigate("/login");
            }
        });
    };

    const renderBreabcrumb = (item) =>
    item.to ? (
      <Link key={item.title} to={item.to}>
        {item.title}
      </Link>
    ) : (
      <Typography key={item.title}>
        {item.title}
      </Typography>
    );

    const backTo = () => {
        if(breadcrumbs.slug !== "home" && breadcrumbs.slug !== "hacerse_socio"){
            navigate(-1);
        }else if(breadcrumbs.slug === "hacerse_socio"){
            navigate("/home");
        }
    }
    
    return (
            <AppBar position="static" className="header-bar">
                <LateralMenu open={isDrawerOpen} setOpen={setIsDrawerOpen}/>
                <Toolbar className="toolbar">
                    {/*<figure>
                        <Link to="/home">
                            <img src={logo} alt="Logo Club de Caza" className="cdc-logo"/>
                        </Link>
                    </figure>*/}
                    <Box className="toolbar-items">
                        <Box className="toolbar-breadcrumbs">
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="Retroceder"
                                style={{color: "#fff"}}
                                onClick={() => backTo()}
                            >
                                <img src={retroceder} alt="Retroceder" className="cdc-logo"/>
                            </IconButton>
                            {breadcrumbs && breadcrumbs.breadcrumbs.map((b) => renderBreabcrumb(b))}
                        </Box>
                        <figure style={{maxHeight: "70px", marginTop: "0", marginBottom: "10px", marginLeft: "0", marginRight: "0"}} >
                            <Link to="/home">
                                <img className="cdc-logo" src={logo} alt="Logo Club de Caza" style={{maxHeight: "70px"}}/>
                            </Link>
                        </figure>
                        <Box style={{display: "flex", justifyContent: "center"}}>
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="Abrir notificaciones"
                                style={{color: "#fff"}}
                            >
                            <Link to="/notificaciones"><img src={gotNotifications ? notificacion_in : notificacion} alt="Notificaciones" className="cdc-logo"/></Link>
                            </IconButton>
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="Abrir menú lateral"
                                style={{color: "#fff"}}
                                onClick={() => {setIsDrawerOpen(true)}}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
    );
}