import  {Button, TextField, Box} from '@mui/material';
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../firebase/Auth";

export default function LoginFormBackoffice() {
    const navigate = useNavigate();
    const { singInBackoffice } = useAuth();

    const [sessionToken, setSessionToken] = useState();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        await singInBackoffice(username, password).then(() => {
            navigate("/backoffice-home");
        }
        ).catch((error) => {
            alert(error);
        });
    };
    
    return(
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Nombre de usuario"
                    name="username"
                    autoComplete="username"
                    onChange={e => 
                        setUsername(e.target.value)
                    }
                    autoFocus
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={e => 
                        setPassword(e.target.value)
                    }
                    />
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    >
                    Acceder
                    </Button>
                </Box>
    )
}