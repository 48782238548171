import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Información personal',
  'Contacto y dirección',
  'Pasarela de pago',
];

export default function StepperComponent({position}) {

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={position} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#D99B72', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#D99B72', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'rgba(0, 0, 0, 0.87)', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
            },
          }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}