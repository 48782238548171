import {Button, CssBaseline, Box, Typography, Container} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import welcomeImage from "../../assets/fondo_bloque1.png";
import logo from '../../assets/cdc-logo.svg';


export default function Welcome() {

    const navigate = useNavigate();
    

    const navigateToLogin = () => {
        navigate('/login');
    }

    const navigateToRegister = () => {
        navigate('/registro');
    }
    
    return(
            <Container component="main" className="welcome-main">
                <CssBaseline />
                <Box component="form"       
                sx={{
                    background: '#FFFFFF',
                    height: "100%",
                    position: "relative"
                }}
                >
                    <figure className='welcome-body-image'>
                        <img src={welcomeImage} alt={"Imagen Cabecera Welcome"}/>
                    </figure>
                    <div className='welcome-body'>
                            <figure className='welcome-body-logo'>
                                <img src={logo} alt={"Imagen Logo Club de Caza"}/>
                            </figure>
                            <Typography component="h1" variant="h2" className="welcome-body-typography">
                            Bienvenido
                            </Typography>
                            <Typography component="h1" variant="body2" className="welcome-body-typography-subtitle">
                                El Club Tierra de Caza le da abre las puertas para disfrutar todas las ventajas que ofrece
                            </Typography>
                            <div className='welcome-body-buttons'>
                                <Button
                                onClick={navigateToLogin}
                                className="welcome-body-buttom"
                                >
                                Entrar
                                </Button>
                                <Button
                                onClick={navigateToRegister}
                                className="welcome-body-buttom"
                                >
                                Registrarse
                                </Button>
                            </div>
                    </div>
                </Box>
            </Container>
    );
}