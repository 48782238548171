import React from "react";
import { Admin, CustomRoutes, Resource} from 'react-admin'
import { GestionesList } from '../../components/backoffice//model/GestionesList';
import { MyLayout } from '../../components/backoffice/layout/MyLayout';
import { i18nProvider } from '../../components/backoffice/translation/i18nProvider';
import { GestionesEdit } from '../../components/backoffice/model/GestionesEdit';
import { DataProvider } from '../../components/backoffice/dataprovider/DataProvider';
import { AuthProvider } from '../../components/backoffice/dataprovider/AuthProvider';
import { UsuariosList } from "../../components/backoffice/model/UsuariosList";
import { UsuariosEdit} from "../../components/backoffice/model/UsuariosEdit";
import { SorteosList } from "../../components/backoffice/model/SorteosList";
import { SorteosEdit } from "../../components/backoffice/model/SorteosEdit";
import { defaultTheme } from 'react-admin';
import { HilosList } from "../../components/backoffice/model/HilosList";
import { HiloEdit } from "../../components/backoffice/model/HiloEdit";
import { Route } from "react-router-dom";
import BOLogin from '../backoffice/Login';
import { DocumentosList } from "../../components/backoffice/model/DocumentosList";
import { DocumentoEdit } from "../../components/backoffice/model/DocumentoEdit";
import { DocumentoCreate } from "../../components/backoffice/model/DocumentoCreate";
import { UsuariosCreate } from "../../components/backoffice/model/UsuariosCreate";


export const HomeBackoffice = () => {

    const myTheme = {
        ...defaultTheme,
        components: {
            ...defaultTheme.components,
            MuiTextField: {
                defaultProps: {
                    variant: 'outlined',
                },
            },
            MuiFormControl: {
                defaultProps: {
                    variant: 'outlined',
                },
            },
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        width: '220px',
                        borderLeft: '3px solid transparent',
                        '&.RaMenuItemLink-active': {
                            borderLeft: '2px solid #4f3cc9',
                            color: '#1976d2',
                        },
                    },
                }
            },

            RaList: {
                styleOverrides: {
                    root: {
                        marginRight : '10%',
                        marginLeft : '2%',
                        '& .RaList-content': {
                            marginTop: '15px',
                            marginBottom: '15px',
                        },
                    }
                }
            },

            RaSidebar: {
                styleOverrides: {
                    root: {
                        minWidth: '190px', 
                        '& .MuiPaper-root':{
                            width:"190px"
                        }
                    }
                }
            },
            
            RaLabeled: {
                styleOverrides: {
                    root:{
                        '& .RaLabeled-label':{
                            fontWeight: '600',
                            fontSize: '0.9em',
                        }
                    }
                }
            },
            RaMenu:{
                styleOverrides: {
                    root:{
                        '&.RaMenu-open':{
                            width:"190px"
                        }
                    }
                }
            },
            RaFilterFormInput:{
                styleOverrides:{
                    root:{
                        alignItems: "center",
                        margin: 0,
                        '.MuiButtonBase-root':{
                            marginBottom: "0",
                        },
                        '.MuiFormControl-root':{
                            margin: "0 !important"
                        }
                    }
                }
            },
            RaLayout:{
                styleOverrides:{
                    root:{
                        '.RaLayout-content':{
                            paddingRight: "8px"
                        }
                    }
                }
            },
            RaDatagrid:{
                styleOverrides:{
                    root:{
                        '.RaDatagrid-table':{
                            tableLayout: "fixed",
                            'tbody':{
                                'td':{
                                    wordBreak: "break-all"
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <Admin title="Funciones de Administrador" basename="/backoffice-home" theme={myTheme} loginPage={BOLogin} dataProvider={DataProvider} authProvider={AuthProvider} layout={MyLayout} i18nProvider={i18nProvider}>
            <Resource name="users" list={UsuariosList} edit={UsuariosEdit} create={UsuariosCreate}/>
            <Resource name="users/premium" list={UsuariosList} edit={UsuariosEdit}/>
            <Resource name="users/basicos" list={UsuariosList} edit={UsuariosEdit}/>
            <Resource name="gestiones/allMessages" list={HilosList}/>
            <CustomRoutes>
                <Route path="/gestiones/allMessages/:id" element={<HiloEdit />} />
            </CustomRoutes>
            <Resource name="gestiones" list={GestionesList} edit={GestionesEdit}/>
            <Resource name="gestiones/nuevas" list={GestionesList} edit={GestionesEdit} />
            <Resource name="gestiones/pendientes" list={GestionesList} edit={GestionesEdit}/>
            <Resource name="gestiones/cerradas" list={GestionesList} edit={GestionesEdit}/>
            <Resource name="documentos" list={DocumentosList} edit={DocumentoEdit} create={DocumentoCreate}/>
            <Resource name="documentos/aprobados" list={DocumentosList} edit={DocumentoEdit} create={DocumentoCreate}/>
            <Resource name="documentos/revision" list={DocumentosList} edit={DocumentoEdit} create={DocumentoCreate}/>
            <Resource name="documentos/rechazados" list={DocumentosList} edit={DocumentoEdit} create={DocumentoCreate}/>
            <Resource name="sorteos" list={SorteosList} edit={SorteosEdit}/>
        </Admin>
    )
}
    


          
   
