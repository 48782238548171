
export const AuthProvider = {

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            return Promise.reject({redirectTo: '/backoffice-login', message: 'Su sesión a caducado. Vuelva a conectarse.'});
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: params => Promise.resolve(),
    logout: () => {
        return Promise.resolve();
    },
    login: params => Promise.resolve(),
    getIdentity: () => Promise.resolve(),
    handleCallback: () => Promise.resolve(), 
    getPermissions: () => Promise.resolve(),
};