import { Box, CircularProgress, Container, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import parse from "html-react-parser";

export default function Blog() {

    const [loading, setLoading] = useState(true);
    const [ blogs, setBlogs ] = useState([]);

    const loadRelatedData = async () => {
        try {
            await instanceWP.get(`/noticia?per_page=4&acf_format=standard`).then((response) => {
                let acf = [];
                response.data.map(r => (
                    acf.push({...r.acf, id: r.id, title: r.title.rendered, categoria_id: r.noticia_categoria[0], noticia_categoria: r.noticia_categoria[0]})
                ));
                let acfCopy = JSON.parse(JSON.stringify(acf));
                return {acf, acfCopy};

            }).then(async (args) => {
                await instanceWP.get(`/noticia_categoria`).then((cat) =>{
                    args.acf.forEach((item, index) => {
                        for (var i = 0; i < cat.data.length; i++) {
                            if(item.noticia_categoria === cat.data[i].id){
                                args.acfCopy[index]["noticia_categoria"] = cat.data[i].name;
                            }
                        }
                    });
                    setBlogs(args.acfCopy);
                });
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadRelatedData();
      }, []);

    return(
        <Container className="public-section-blog" maxWidth={false}>
            <Box className="public-section-blog-box">
                <Typography variant="h1"><Link href="/blogs">Blog / Noticias</Link></Typography>
                <Typography style={{marginBottom: "35px", fontSize: "17px"}}>
                    En nuestro blog, encontrarás una amplia variedad de artículos, guías y noticias sobre la caza, la
                    conservación de la fauna, la seguridad en la caza, así como información sobre eventos exclusivos
                    para socios del club, y mucho más. En esta descripción, exploraremos los temas y recursos que
                    pueden encontrar nuestros socios en nuestro blog de caza, y cómo esto puede mejorar su
                    experiencia como cazadores.
                </Typography>
                <Grid container justifyContent="center" spacing={3}>
                    {loading ? (
                        <Box p={3}>
                            <CircularProgress />
                        </Box>
                        )
                        : 
                        (
                            <>
                                {blogs.length === 0 ?
                                    <div className=""><strong>No hay noticias/blogs actualmente.</strong></div>
                                    :
                                    (
                                        blogs.map((b) => {
                                            return (
                                                <Grid className="public-section-blog-card" item xs={12} md={3} key={"blog-id-" + b.id}>
                                                    <a href={"/blog/" + b.id}>
                                                        <figure>
                                                            <img src={b.image ? b.image : ""} alt={"Imagen " + b.title}/>
                                                        </figure>
                                                        {b.noticia_categoria && 
                                                            <Link href={"/blogs" + "?categories=" + b.categoria_id}>
                                                                <Typography className="public-category">{b.noticia_categoria}</Typography>
                                                            </Link>
                                                        }
                                                        <Typography variant="h2" title={b.title}>{b.title}</Typography>
                                                        <div className="public-section-blog-container-content">{parse(b.summary)}</div>
                                                        <Box className="public-section-blog-read" align="left">
                                                            <Link href={"/blog/" + b.id}>Leer más...</Link>
                                                        </Box>
                                                    </a>
                                                </Grid>
                                            );
                                        })
                                    )
                                }
                            </>
                        )
                    }
                </Grid>
            </Box>
        </Container>
    );
}