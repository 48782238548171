import { Card } from '@mui/material';
import { SearchInput,TextInput, TextField, DateField, BooleanField, Pagination, TopToolbar, SelectColumnsButton, FilterButton, ExportButton, ListBase, ListToolbar, DatagridConfigurable, useRecordContext, SelectInput } from 'react-admin';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const SorteosList = (props) => {

    const sorteosFilters = [
        <SearchInput source="q" alwaysOn/>,
        <TextInput label="Titulo" source="titulo"/>,
        <TextInput label="Catidad de Ganadores" source="adjudicatarios" />,
        <TextInput label="Precio" source="precio"/>,
        <SelectInput label="Estado" source="estadoSorteo" emptyText="-" choices={[
            { id: 'Celebrado', name: 'Celebrado' },
            { id: 'Activo', name: 'Activo' },
        ]} />,
        <SelectInput label="Tipo" source="category" emptyText="-" choices={[
            { id: 'Gratuito', name: 'Celebrado' },
            { id: 'Activo', name: 'Activo' },
        ]} />,
    ];

    const SorteosListActions = () => (
        <TopToolbar sx={{alignItems:"flex-end", padding: "0 !important"}}>
                <SelectColumnsButton />
                <FilterButton filters={sorteosFilters}/>
                <ExportButton/>
        </TopToolbar>
    );

    const MyButton = () => {
        const record = useRecordContext();
        const handleClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let url = window.location.href;
            window.open(url + "/" + record.id, '_blank');
        };
        return <button className="cdc-backoffice-newtab" onClick={(e) => handleClick(e)} type="button"><><OpenInNewIcon /></></button>;
    }

    const MyList = ({children, ...props}) => {
        return (
            <ListBase {...props} perPage={15} hasCreate={true}>
                <ListToolbar className="cdc-backoffice-filters" sx={{alignItems:"center"}}
                    filters={sorteosFilters}
                    actions={<SorteosListActions />}
                />
                <Card sx={{marginTop: "20px", paddingRight: "16px", paddingLeft: "8px"}}>
                    {children}
                </Card>
                <Pagination rowsPerPageOptions={[5, 10, 15, 25, 50]} />
            </ListBase>
        );
    };

    return (
        <MyList title="Listado de Documentos">
            <DatagridConfigurable bulkActionButtons={false} rowClick="edit" omit={['id']} sx={{
                '& .column-nombre': { overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }
            }}>
                <MyButton label="Abrir nueva pestaña" />
                <TextField label="Titulo" source="titulo" />
                <TextField label="Tipo de Sorteo" source="category" />
                <TextField label="Estado" source="estadoSorteo" />
                <TextField label="Cantidad de Ganadores" source="adjudicatarios" />
                <TextField label="Precio" source="precio" />
                <DateField label="Fecha Fin" source="fechaFin" options={{ year: 'numeric', month: 'numeric', day: 'numeric' }} />
                <DateField label="Fecha de Creación" source="fechaCreacion" options={{ year: 'numeric', month: 'numeric', day: 'numeric'}} />
            </DatagridConfigurable>
        </MyList>

    )

}