import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

export default function InstallAppIos({open, setOpen, ios}){

    const handleClose = () => {
        setOpen(false);
    }
    
    return(
        <Dialog open={open} onClose={handleClose} className="cdc-backoffice-modal-close" style={{padding: 0}} fullWidth>
            <DialogContent className="cdc-install-dialog">
                {ios ? (
                    <>
                    <Box className="cdc-install-dialog-top">
                        <DialogTitle className="noPaddingImp">Añadir a la página de inicio</DialogTitle>
                        <DialogActions className="noPaddingImp">
                            <Button label="Cerrar" variant="text" onClick={handleClose}>Cerrar</Button>
                        </DialogActions>
                    </Box>
                    <Box className="cdc-install-dialog-bottom">
                        <div className="cdc-install-dialog-sum">
                            <p>Esta web tiene disponible su uso en App, siendo necesario el empleo de Safari como navegador. Añadela a tu pantalla de inicio para poder usarla y a pantalla completa.</p>
                        </div>
                        <ul className="cdc-install-dialog-ul">
                            <li className="cdc-install-dialog-li">
                                <figure>
                                    <IosShareIcon style={{ fill: '#0179fe'}}/>
                                </figure>
                                <p>1) Pulsa el botón "Compartir"</p>
                            </li>
                            <li className="cdc-install-dialog-li">
                                <figure>
                                    <AddBoxOutlinedIcon style={{ fill: '#ffffff'}}/>
                                </figure>
                                <p>2) Pulsa en "Añadir a la pantalla de inicio"</p>
                            </li>
                        </ul>
                    </Box>
                    </>
                ) : (
                    <Box className="cdc-install-dialog-not">
                        <DialogTitle>No puedes instalar esta aplicación si no te encuentras en un dispositivo IOS.</DialogTitle>
                        
                        <DialogActions>
                            <Button className="button-toolbar" label="Cerrar" variant="text" onClick={handleClose}>Cerrar</Button>
                        </DialogActions>
                    </Box> 
                )}
                
            </DialogContent>
        </Dialog>
    );
}