import axios from "axios";

//DIRECCION DE PRE
//https://b8bxmklrd4.execute-api.eu-west-1.amazonaws.com/pre

//DIRECCION DE PRO
//https://4d7jcn0fef.execute-api.eu-west-1.amazonaws.com/pro

export const instance = axios.create({
  baseURL: "https://ulcbbyxid6.execute-api.eu-south-2.amazonaws.com/pro",
});

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const successHandler = (response) => {
  return response;
};

const errorHandler = async (error) => {
  const { status } = error.response;
  /*if(status === 403 && window.location.pathname.includes("backoffice-home")){
    return Promise.reject(goToLoginBackoffice());
  }*/
  console.log("INTERCEPTADO! " + status);

  return Promise.reject(error);
};

export const instanceAWS = axios.create({
  baseURL: "https://ulcbbyxid6.execute-api.eu-south-2.amazonaws.com/pro/clubCaza",
});

export const instanceUsers = axios.create({
  baseURL: "https://ulcbbyxid6.execute-api.eu-south-2.amazonaws.com/pro/users",
});

export const instanceAWSbo = axios.create({
  baseURL: "https://ulcbbyxid6.execute-api.eu-south-2.amazonaws.com/pro/gestiones",
});

export const instanceWP = axios.create({
  baseURL: "https://cms.tierradecaza.com/wp-json/wp/v2",
});
