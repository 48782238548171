import { Box, Button, Link, Typography } from "@mui/material";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import Joinmembers from "../../../components/public-components/joinmember/Joinmember";
import Laptopjoin from "../../../components/public-components/laptopjoin/Laptopjoin";
import Phonejoin from "../../../components/public-components/phonejoin/Phonejoin";
import Infodogs from "../../../components/public-components/info-dog/Infodog";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { instance } from "../../../utils/axios-config";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import pdfContratacion from "../../../assets/legal/CONDICIONES_PARTICULARES_DE_CONTRATACION.pdf";
import pdfCondicionesUso from "../../../assets/legal/CONDICIONES_GENERALES_DE_USO.pdf";
import pdfPrivacidad from "../../../assets/legal/POLITICA_DE_PRIVACIDAD.pdf";

export const JoinFormV2 = () => {

  const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [dni, setDni] = useState('');
  const [address, setAddress] = useState('');
  const [town, setTown] = useState('');
  const [province, setProvince] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [password, setPassword] = useState('');
  const [invcode, setInvcode] = useState('');
  const [errors, setErrors] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [token, setToken] = useState("");
  const [terms, setTerms] = useState(false);
  const [overAge, setOverAge] = useState(false);
  const [suscription, setSuscription] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const captcha = useRef();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const body = {
    email: email,
    nombre: name,
    apellido: surname,
    noTelefono: phone,
    dni: dni,
    direccion: address,
    poblacion: town,
    provincia: province,
    codigoPostal: zipcode,
    password: password,
    suscripcion: suscription,
    iviteCode: searchParams.get("promoCode") ? searchParams.get("promoCode") : invcode
};

  const handleSubmit = async (e) => {
      e.preventDefault();
      setErrors({});
      if(validateForm()){
        //window.localStorage.setItem("payment", JSON.stringify(body));
        navigate('/pago-socio', {state: body});
      }
  };

  const handleLinkClick = (pdfSrc) => {
    navigate('/documentos-legal', {state:  pdfSrc});
}

  const nifValidation = (dni) => {
    let numero;
    let letr;
    let letra;
    let expresion_regular_dni;
    let result = false;
   
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
   
    if(expresion_regular_dni.test(dni) === true){
       numero = dni.substr(0,dni.length-1);
       letr = dni.substr(dni.length-1,1);
       numero = numero % 23;
       letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
       letra = letra.substring(numero,numero+1);
      if (letra===letr.toUpperCase()) {
         result = true;
       }
    }
    return result;
  }

  const validateForm = () =>{
      let valid = true;
      let inputIds = "";
      if(name === undefined || name === ''){
          setErrors(errors => ({...errors, errorName: true, errorNameMsg: ""}));
          inputIds = inputIds ? inputIds +",namejoin" : "namejoin";
          valid = false;
      }
      if(surname === undefined || surname === ''){
          setErrors(errors => ({...errors, errorSurname: true, errorSurnameMsg: ""}));
          inputIds = inputIds ? inputIds +",surnamejoin" : "surnamejoin";
          valid = false;
      }
      if(dni === undefined || dni === ''){
        setErrors(errors => ({...errors, errorDni: true, errorDniMsg: ""}));
        inputIds = inputIds ? inputIds +",dnijoin" : "dnijoin";
        valid = false;
      }else if(!nifValidation(dni)){
          setErrors(errors => ({...errors, errorDni: true, errorDniMsg: "El DNI no es válido."}));
          inputIds = inputIds ? inputIds +",dnijoin" : "dnijoin";
          valid = false;
      }
      if(phone === undefined || phone === ''){
        setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: ""}));
        inputIds = inputIds ? inputIds +",phonejoin" : "phonejoin";
        valid = false;
      }else if(!/^[0-9]{9}$/i.test(phone)){
          setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: "El teléfono debe contener 9 dígitos."}));
          inputIds = inputIds ? inputIds +",phonejoin" : "phonejoin";
          valid = false;
      }
      if(address === undefined || address === ''){
        setErrors(errors => ({...errors, errorAddress: true, errorAddressMsg: ""}));
        inputIds = inputIds ? inputIds +",addressjoin" : "addressjoin";
        valid = false;
      }
      if(town === undefined || town === ''){
        setErrors(errors => ({...errors, errorTown: true, errorTownMsg: ""}));
        inputIds = inputIds ? inputIds +",townjoin" : "townjoin";
        valid = false;
      }
      if(province === undefined || province === ''){
        setErrors(errors => ({...errors, errorProvince: true, errorProvinceMsg: ""}));
        inputIds = inputIds ? inputIds +",provincejoin" : "provincejoin";
        valid = false;
      }
      if(zipcode === undefined || zipcode === ''){
        setErrors(errors => ({...errors, errorZipcode: true, errorZipcodeMsg: ""}));
        inputIds = inputIds ? inputIds +",zipcodejoin" : "zipcodejoin";
        valid = false;
      }else if(!/^[0-9]{5}$/i.test(zipcode)){
        setErrors(errors => ({...errors, errorZipcode: true, errorZipcodeMsg: "El código postal debe tener 5 dígitos."}));
        inputIds = inputIds ? inputIds +",zipcodejoin" : "zipcodejoin";
        valid = false;
      }
      if(email === undefined || email === ''){
        setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: ""}));
        inputIds = inputIds ? inputIds +",emailjoin" : "emailjoin";
        valid = false;
      }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
          setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es un correo válido."}));
          inputIds = inputIds ? inputIds +",emailjoin" : "emailjoin";
          valid = false;
      }
      if(password === undefined || password === ''){
        setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: ""}));
        inputIds = inputIds ? inputIds +",passwordjoin" : "passwordjoin";
        valid = false;
      }else if(password.length < 3){
        setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña debe ser de al menos 3 dígitos."}));
        inputIds = inputIds ? inputIds +",passwordjoin" : "passwordjoin";
        valid = false;
      }
      if(overAge === false){
        setErrors(errors => ({...errors, errorOverAge: true, errorOverAgeMsg: "Debes indicarnos que eres mayor de edad."}));
        inputIds = inputIds ? inputIds +",overAgejoin" : "overAgejoin";
        valid = false;
      }
      if(terms === false){
        setErrors(errors => ({...errors, errorTerms: true, errorTermsMsg: "Debes indicarnos que aceptas nuestros términos."}));
        inputIds = inputIds ? inputIds +",termsjoin" : "termsjoin";
        valid = false;
      }
      if(!token){
        setErrorCaptcha(true);
      }
      if(inputIds !== ""){
        let elem = document.getElementById(inputIds.split(",")[0]);
        elem.scrollIntoView({behavior: "smooth"});
        elem.focus();
      }
      
      return valid;
  }

  return (
    <>
        <Box className="public-join-container">
            <Box className="public-join-container-form-v2" component="form" onSubmit={handleSubmit} noValidate>
              <Box textAlign="center">
                  <div className="public-join-field">
                      <label htmlFor="name">Nombre*</label>
                      <input type="text" id="namejoin" name="name" onChange={(e) => {setName(e.target.value); setErrors(errors => ({...errors, errorName: false, errorNameMsg: ""}));}} required className={errors.errorName ? "red-flag": ""}/>
                      {errors.errorName && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorNameMsg}</span>}
                  </div>
                  <div className="public-join-field">
                      <label htmlFor="surname">Apellidos*</label>
                      <input type="text" id="surnamejoin" name="surname" onChange={(e) => {setSurname(e.target.value); setErrors(errors => ({...errors, errorSurname: false, errorSurnameMsg: ""}));}} required className={errors.errorSurname ? "red-flag": ""}/>
                      {errors.errorSurname && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorSurnameMsg}</span>}
                  </div>
                  <div className="public-join-form-field">
                    <div className="public-join-field">
                        <label htmlFor="dni">DNI*</label>
                        <input type="text" id="dnijoin" name="dni" onChange={(e) => {setDni(e.target.value); setErrors(errors => ({...errors, errorDni: false, errorDniMsg: ""}));}} required className={errors.errorDni ? "red-flag": ""}/>
                        {errors.errorDni && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorDniMsg}</span>}
                    </div>
                    <div className="public-join-field">
                        <label htmlFor="phone">Teléfono*</label>
                        <input type="text" id="phonejoin" name="phone" onChange={(e) => {setPhone(e.target.value); setErrors(errors => ({...errors, errorPhone: false, errorPhoneMsg: ""}));}} required className={errors.errorPhone ? "red-flag": ""}/>
                        {errors.errorPhone && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorPhoneMsg}</span>}
                    </div>
                  </div>
                  <div className="public-join-field">
                      <label htmlFor="adress">Dirección*</label>
                      <input type="text" id="addressjoin" name="address" onChange={(e) => {setAddress(e.target.value); setErrors(errors => ({...errors, errorAddress: false, errorAddressMsg: ""}));}} required className={errors.errorAddress ? "red-flag": ""}/>
                      {errors.errorAddress && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorAddressMsg}</span>}
                  </div>
                  <div className="public-join-field">
                      <label htmlFor="town">Población*</label>
                      <input type="text" id="townjoin" name="town" onChange={(e) => {setTown(e.target.value); setErrors(errors => ({...errors, errorTown: false, errorTownMsg: ""}));}} required className={errors.errorTown ? "red-flag": ""}/>
                      {errors.errorTown && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorTownMsg}</span>}
                  </div>
                  <div className="public-join-form-field">
                    <div className="public-join-field">
                        <label htmlFor="province">Provincia*</label>
                        <select name="province" id="provincejoin" value={province ? province : ""} onChange={(e) => {setProvince(e.target.value); setErrors(errors => ({...errors, errorProvince: false, errorProvinceMsg: ""}));}} className={errors.errorProvince ? "red-flag": ""}>
                            <option value="">{"-"}</option>
                            <option value="Álava/Araba">Álava/Araba</option>
                            <option value="Albacete">Albacete</option>
                            <option value="Alicante">Alicante</option>
                            <option value="Almería">Almería</option>
                            <option value="Asturias">Asturias</option>
                            <option value="Ávila">Ávila</option>
                            <option value="Badajoz">Badajoz</option>
                            <option value="Baleares">Baleares</option>
                            <option value="Barcelona">Barcelona</option>
                            <option value="Burgos">Burgos</option>
                            <option value="Cáceres">Cáceres</option>
                            <option value="Cádiz">Cádiz</option>
                            <option value="Cantabria">Cantabria</option>
                            <option value="Castellón">Castellón</option>
                            <option value="Ceuta">Ceuta</option>
                            <option value="Ciudad Real">Ciudad Real</option>
                            <option value="Córdoba">Córdoba</option>
                            <option value="Cuenca">Cuenca</option>
                            <option value="Gerona/Girona">Gerona/Girona</option>
                            <option value="Granada">Granada</option>
                            <option value="Guadalajara">Guadalajara</option>
                            <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                            <option value="Huelva">Huelva</option>
                            <option value="Huesca">Huesca</option>
                            <option value="Jaén">Jaén</option>
                            <option value="La Coruña/A Coruña">La Coruña/A Coruña</option>
                            <option value="La Rioja">La Rioja</option>
                            <option value="Las Palmas">Las Palmas</option>
                            <option value="León">León</option>
                            <option value="Lérida/Lleida">Lérida/Lleida</option>
                            <option value="Lugo">Lugo</option>
                            <option value="Madrid">Madrid</option>
                            <option value="Málaga">Málaga</option>
                            <option value="Melilla">Melilla</option>
                            <option value="Murcia">Murcia</option>
                            <option value="Navarra">Navarra</option>
                            <option value="Orense/Ourense">Orense/Ourense</option>
                            <option value="Palencia">Palencia</option>
                            <option value="Pontevedra">Pontevedra</option>
                            <option value="Salamanca">Salamanca</option>
                            <option value="Segovia">Segovia</option>
                            <option value="Sevilla">Sevilla</option>
                            <option value="Soria">Soria</option>
                            <option value="Tarragona">Tarragona</option>
                            <option value="Tenerife">Tenerife</option>
                            <option value="Teruel">Teruel</option>
                            <option value="Toledo">Toledo</option>
                            <option value="Valencia">Valencia</option>
                            <option value="Valladolid">Valladolid</option>
                            <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                            <option value="Zamora">Zamora</option>
                            <option value="Zaragoza">Zaragoza</option>
                        </select>
                        {errors.errorProvince && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorProvinceMsg}</span>}
                        {/*<label htmlFor="province">Provincia*</label>
                        <input type="text" id="provincejoin" name="province" onChange={(e) => {setProvince(e.target.value); setErrors(errors => ({...errors, errorProvince: false, errorProvinceMsg: ""}));}} required className={errors.errorProvince ? "red-flag": ""}/>
                            {errors.errorProvince && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorProvinceMsg}</span>}*/}
                    </div>
                    <div className="public-join-field">
                        <label htmlFor="zipcode">Código Postal*</label>
                        <input type="text" id="zipcodejoin" name="zipcode" onChange={(e) => {setZipcode(e.target.value); setErrors(errors => ({...errors, errorZipcode: false, errorZipcodeMsg: ""}));}} required className={errors.errorZipcode ? "red-flag": ""}/>
                        {errors.errorZipcode && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorZipcodeMsg}</span>}
                    </div>
                  </div>
                  <div className="public-join-field">
                      <label htmlFor="email">Email*</label>
                      <input type="email" id="emailjoin" name="email" onChange={(e) => {setEmail(e.target.value); setErrors(errors => ({...errors, errorEmail: false, errorEmailMsg: ""}));}} required className={errors.errorEmail ? "red-flag": ""}/>
                      {errors.errorEmail && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorEmailMsg}</span>}
                  </div>
                  <div className="public-join-field">
                      <label htmlFor="password">Crea tu contraseña*</label>
                      <input type="password" id="passwordjoin" name="password" onChange={(e) => {setPassword(e.target.value); setErrors(errors => ({...errors, errorPassword: false, errorPasswordMsg: ""}));}} required className={errors.errorPassword ? "red-flag": ""}/>
                      {errors.errorPassword && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorPasswordMsg}</span>}
                  </div>
                  <div className="public-join-field">
                      <label htmlFor="invCode">Código de amigo</label>
                      <input id="invCode"defaultValue={searchParams.get("promoCode")} name="invCodejoin" onChange={(e) => setInvcode(e.target.value)} required/>
                      {errors.errorInvcode && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorInvcodeMsg}</span>}
                  </div>
                  <div className="public-join-field c-box-1-v2">
                      <div className="checkbox">
                        <input type="checkbox" id="overAgejoin" name="overAge" onChange={(e) => {setOverAge(!overAge); setErrors(errors => ({...errors, errorOverAge: false, errorOverAgeMsg: ""}));}} required/>
                        <label htmlFor="overAge">Soy mayor de 18 años.</label>
                      </div>
                      {errors.errorOverAge && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorOverAgeMsg}</span>}
                  </div>
                  <div className="public-join-field c-box-1-v2">
                      <div className="checkbox">
                        <input type="checkbox" id="termsjoin" name="terms" onChange={(e) => {setTerms(!terms); setErrors(errors => ({...errors, errorTerms: false, errorTermsMsg: ""}));}} required/>
                        <label htmlFor="terms">Al registrarte declaras aceptar las Condiciones Generales de Uso
                            , así como la Política de privacidad 
                            y la Política de contratación
                            .</label>
                      </div>
                      {errors.errorTerms && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorTermsMsg}</span>}
                  </div>
                  <div className="public-join-field c-box-1">
                      <div className="checkbox">
                        <input type="checkbox" id="suscription" name="suscription" onChange={(e) => {setSuscription(!suscription)}} required/>
                        <label htmlFor="overAge">Deseo suscribirme a la newsletter del Club Tierra de Caza y recibir toda la información y noticias relaccionadas con el mismo, vía correo electrónico.</label>
                      </div>
                  </div>
                  <div className="public-join-field">
                    <HCaptcha
                        ref={captcha}
                        sitekey={CAPTCHA_KEY}
                        onVerify={token => {setToken(token); setErrorCaptcha(false)}}
                        onExpire={e => setToken("")}
                    />
                    {errorCaptcha && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>Debe completar el Captcha.</span>}
                  </div>
                  {isPending ?
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled>
                        ENVIANDO...
                    </Button> : 
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained">
                        ENVIAR
                    </Button>
                  }
                  <div style={{height: "40px"}}></div>
              </Box>
            </Box>
        </Box>
    </>
  );
}