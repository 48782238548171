import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import SearchComponent from "../../../components/public-components/search/Search";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import parse from "html-react-parser";
import CPagination from "../../../components/public-components/pagination/Pagination";
import { useSearchParams } from "react-router-dom";

export const PRaffles = () => {

    const [ searchParams ] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [ raffles, setRaffles ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ categories, setCategories ] = useState([]);
    const [ text, setText ] = useState();
    const [ category, setCategory ] = useState();

    useEffect(() => {
        setCategory(searchParams.get('categories') ? searchParams.get('categories') : "");
        setText(searchParams.get('text') ? searchParams.get('text') : "");
        loadRafflesData(1, searchParams.get('text'), searchParams.get('categories'));
      }, [searchParams]);

    const loadRafflesData = async (startPage = 1, text, categories) => {
        let buildUrl = `/sorteo?per_page=8&page=${startPage}&acf_format=standard`;
        buildUrl = buildUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);
        buildUrl = buildUrl + (categories !== null && categories !== "" && categories !== undefined ? `&sorteo_categoria=${categories}`: ``);
        try {
            await instanceWP.get(buildUrl).then((response) => {
                let acf = [];
                response.data.map(r => (
                    acf.push({...r.acf, id: r.id, title: r.title.rendered, categoria_id: r.sorteo_categoria[0], sorteo_categoria: r.sorteo_categoria[0]})
                ));
                let acfCopy = JSON.parse(JSON.stringify(acf));
                setTotalPages(parseInt(response.headers['x-wp-totalpages']));

                return {acf, acfCopy};

            }).then(async (args) => {
                await instanceWP.get(`/sorteo_categoria`).then((cat) =>{
                    args.acf.forEach((item, index) => {
                        for (var i = 0; i < cat.data.length; i++) {
                            if(item.sorteo_categoria === cat.data[i].id){
                                args.acfCopy[index]["sorteo_categoria"] = cat.data[i].name;
                            }
                        }
                    });
                    setCategories(cat.data);
                    setRaffles(args.acfCopy);
                });
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    const searchRafflesData = (e, text, category) => {
        e.preventDefault();
        setLoading(true);
        loadRafflesData(1, text, category);
    };
  
  return (
    <>
        <Box className="public-blog-container">
            <Box className="public-blog-container-text">
                <Typography variant="h1">Sorteos</Typography>
                <SearchComponent defaultValue={"Todos los sorteos"} categories={categories} text={text} setText={setText} category={category} setCategory={setCategory} searchBlogs={searchRafflesData}/>
            </Box>
            <Grid className="public-blog-container-list" container justifyContent="center" spacing={3}>
                {loading ? (
                    <Box p={3}>
                        <CircularProgress />
                    </Box>
                    )
                    : 
                    (
                        <>
                        {raffles.length === 0 ?
                            <div className="public-blog-container-content"><strong>No se han encontrado sorteos disponibles.</strong></div>
                            :
                            (raffles.map((r) => {
                                return (
                                    <Grid className="public-blog-container-card" item xs={12} md={raffles.length < 3 ? 6 : 3} key={"raffle-id-" + r.id}>
                                        <a href={"/sorteo/" + r.id + "?text=" + text + "&categories=" + category}>
                                            {r.image &&
                                            <figure>
                                                <img src={r.image ? r.image : ""} alt={"Imagen " + r.title}/>
                                            </figure>
                                            }
                                            {r.sorteo_categoria && 
                                                <Link className="public-blog-container-item" href="#">
                                                    <Typography className="public-blog-category" onClick={(e) => {
                                                        setCategory(r.categoria_id);
                                                        searchRafflesData(e , "", r.categoria_id);
                                                    }
                                                        }>{r.sorteo_categoria}</Typography>
                                                </Link>
                                            }
                                            <Typography variant="h2" title={r.title}>{r.title}</Typography>
                                            <div className="public-blog-container-content">{parse(r.summary)}</div>
                                            <Box className="public-blog-container-read" align="left">
                                                <Link href={"/sorteo/" + r.id + "?text=" + text + "&categories=" + category}>Leer más...</Link>
                                            </Box>
                                        </a>
                                    </Grid>
                                );
                              })
                            )
                        }
                        </>
                    )
                }
            </Grid>
            <CPagination setLoading={setLoading} loadData={loadRafflesData} totalPages={totalPages} text={text} category={category}/>
        </Box>
        <JoinIn />
        <Contact />
    </>
  );
}