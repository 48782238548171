import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useInstallation } from "../../../routes/InstallationProvider";

export default function UpdateBrowser({open, setOpen}){

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showButton, setShowButton] = useState(false);
    const { setInstalled } = useInstallation();

    function isInstalled() {
        // For iOS
        if(window.navigator.standalone) return true
    
        // For Android
        if(window.matchMedia('(display-mode: standalone)').matches) return true
    
        // If neither is true, it's not installed
        return false
    }

    const showInstall = (e) => {
        e.preventDefault();
        if(!deferredPrompt){
        return;
        }
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
        console.log(choiceResult.outcome);
        if (choiceResult.outcome === 'accepted') {
            setInstalled(true);
        }
        }).catch(error => {
        console.log(error);
        });
    }

    const handler = e => {
        e.preventDefault();
        console.log("Cae en el handler");
        setShowButton(true);
        setDeferredPrompt(e);
    };

    useEffect(() => {
        if(!isInstalled()){
        window.addEventListener('beforeinstallprompt', handler);
        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
    }
    
    return(
        <Dialog open={open} onClose={handleClose} className="cdc-backoffice-modal-close" style={{padding: 0}} fullWidth>
            <DialogContent className="cdc-install-dialog">

                <Box className="cdc-install-dialog-top">
                    <DialogTitle className="noPaddingImp">Versión de navegador antigua</DialogTitle>
                    <DialogActions className="noPaddingImp">
                        <Button label="Cerrar" variant="text" onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </Box>
                <Box className="cdc-install-dialog-bottom">
                    <div className="cdc-install-dialog-sum">
                        <p>Este navegador tiene una versión antigua. Es recomendable para instalar la App desde la Play Store tener una versión más actualizada de su navegador. En caso contrario puede que la App no funcione correctamente.</p>
                        <p>También puede instalar la app realizando una de las siguientes acciones:</p>
                    </div>
                    <ul className="cdc-install-dialog-ul">
                        <li className="cdc-install-dialog-li">
                            <figure>
                            </figure>
                            <p>1) Si está desde un dispositivo móvil, pulsa en la ventana emergente "Añadir a la pantalla de inicio".</p>
                        </li>
                        {showButton &&
                            <li className="cdc-install-dialog-li">
                                <figure>
                                </figure>
                                    
                                <p>2) O bien, pulsa <a onClick={showInstall}>aquí</a> para instalar.</p>
                            </li>
                        }
                    </ul>
                </Box>

                {/*<Box className="cdc-install-dialog-not">
                    <DialogTitle>Es recomendable para instalar la App desde la Play Store tener una versión más actualizada de su navegador actual. En caso contrario puede que la App no funcione correctamente.</DialogTitle>
                    <DialogActions>
                        <Button className="button-toolbar" label="Cerrar" variant="text" onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </Box>*/}
            </DialogContent>
        </Dialog>
    );
}