import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useAuth } from '../../firebase/Auth';
import sorteos from '../../assets/sorteosPWA.svg';
import socio from '../../assets/serviciosPWA.svg';
import noticias from '../../assets/noticiasPWA.svg';
import gestiones from '../../assets/invitar_nueva.png';
import licencias from '../../assets/licencias.svg';
import seguros from '../../assets/seguros.svg';
import ofertas from '../../assets/oferta_directa.jpg';
import documentacion from '../../assets/documentacion_nueva.png';
import { Link, useLocation } from 'react-router-dom';
import { Newsletter } from '../../components/newsletter/NewsLetter';


export const Home = () => {

  const { currentUser } = useAuth();

  let location = useLocation();

  useEffect(()=> {
    if (location?.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if(elem) {
        setTimeout(function(){
          elem.scrollIntoView({behavior: "smooth"})
      }, 500);
      }          
    } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location])
  
    return (
      <>
        <div className="cdc-home">
          {currentUser.tipo?.toLowerCase() === "basic" && 
            (
              <Link to="/hacerse-socio">
                <Grid className="cdc-home-servicios">
                  <figure>
                    <img src={socio} alt={"Sección Hacerme socio"}/>
                  </figure>
                  <h1>Hacerme socio</h1>
                  <p>Date de alta ahora y empieza a disfrutar de todas las ventajas del club.</p>
                </Grid>
              </Link>
            )
          }
          <Link to="/sorteos">
            <Grid className="cdc-home-sorteos">
              <figure>
                <img src={sorteos} alt={"Sección Sorteos"}/>
              </figure>
              <h1>Vámonos de caza</h1>
              <p>Descubre la oferta cinegética del club.</p>
            </Grid>
          </Link>
        
          {/*<Link to="/servicios">
            <Grid className="cdc-home-servicios">
              <figure>
                <img src={servicios} alt={"Sección Servicios"}/>
              </figure>
              <h1>Servicios</h1>
              <p>Lorem ipsum dolor sit amet consectetur. Laoreet ultricies elit volutpat eget. Faucibus tincidunt in nisi laoreet quis tristique consectetur ipsum elitonle...</p>
            </Grid>
        </Link>*/}

          <Link to="/licencias">
            <Grid className="cdc-home-licencias">
              <figure>
                <img src={licencias} alt={"Sección Licencias"}/>
              </figure>
              <h1>Licencias de caza</h1>
              <p>Desde esta sección puedes tramitar tus licencias de caza de forma rápida, eficaz y sencilla y sin ningún coste añadido. </p>
            </Grid>
          </Link>

          <Link to="/seguros">
            <Grid className="cdc-home-seguros">
              <figure>
                <img src={seguros} alt={"Sección Seguros"}/>
              </figure>
              <h1>Seguros</h1>
              <p>Tramita o renueva tus seguros de caza tanto nacional como internacional sin costes adicionales. Nosotros nos encargamos de todo. </p>
            </Grid>
          </Link>

          <Link to="/mis-documentos">
            <Grid className="cdc-home-documentacion">
              <figure>
                <img src={documentacion} alt={"Sección Documentacion"}/>
              </figure>
              <h1>Documentación</h1>
              <p>Descarga en este apartado tu documentación cinegética y la tendrás disponible siempre en tu teléfono móvil. Además te avisamos de las fechas de caducidad si así nos lo indicas. </p>
            </Grid>
          </Link>

          <Link to="/blogs">
            <Grid className="cdc-home-noticias">
              <figure>
                <img src={noticias} alt={"Sección Noticias"}/>
              </figure>
              <h1>Blog/Noticias</h1>
              <p>Descubre todas las novedades del club y del sector cinegético a través de nuestro blog, con las mejores firmas del sector. </p>
            </Grid>
          </Link>

          <Link to="/ofertas">
            <Grid className="cdc-home-noticias">
              <figure>
                <img src={ofertas} alt={"Sección Ofertas"}/>
              </figure>
              <h1>Complementos</h1>
              <p> Ofrecemos una amplia variedad de opciones de caza para
                        nuestros socios. Cada cacería que adquieres tiene un trato personalizado para garantizar la mejor
                        experiencia de caza posible.
              </p>
            </Grid>
          </Link>

          <Link to="/invitar-amigo">
            <Grid className="cdc-home-invitar">
              <figure>
                <img src={gestiones} alt={"Sección Invitar Amigo"}/>
              </figure>
              <h1>Invitar Amigo</h1>
              <p>Invitar a tus amigos o contactos tiene muchas ventajas para ti. Te damos un código personal para que lo compartas con quien quieras, y por cada alta que recibamos con este código acumulas 20 puntos que podrás canjear por caza.</p>
            </Grid>
          </Link>

          <Newsletter />
        </div>
    </>
      );
}