import { Stack, Pagination } from "@mui/material";

/**params
 * setLoading para sustituir el contenido por un gif de carga mientras espera
 * loadData función definida en el componente padre con la carga concreta de cada componente
 * totalPages número total de páginas a mostrar
 */
export default function CPagination({setLoading, loadData, totalPages, text, category}){

    const handlePageChange = (event, page) => {
        setLoading(true);
        loadData(page, text, category);
    };

    return(
        <Stack spacing={6} className="public-blog-pagination">
            <Pagination count={totalPages} onChange={handlePageChange}/>
        </Stack>
    );
}