import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useReducer } from "react";
import { Box } from "@mui/system";
import { CircularProgress} from "@mui/material";

export const Payment = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const initialForm = {
        merchantBase64: null,
        signatureBase64: null,
    };
    const [ formState, setFormState ] = useReducer((currForm, newValues) => ({...currForm, ...newValues}), initialForm);
    const cryptojs = require("crypto-js");
    const formRef = useRef(null);
    //const body = window.localStorage.getItem("payment");
    useEffect(() => {
        if(state){
            if (checkForm(formState)) {
                formRef.current.submit();
            }
        }else{
            navigate("/home");
        }
      }, [formState]);
    const checkForm = (values) => {
        console.log(values);
        if(values.merchantBase64 === null && values.signatureBase64 === null){
            setSignVariables();
            return false;
        }
        return true;
    }

    /*
        let tpvdata={
            "DS_MERCHANT_AMOUNT":"9900",
            "DS_MERCHANT_CURRENCY":"978",
            "DS_MERCHANT_MERCHANTCODE":"999008881",
            "DS_MERCHANT_MERCHANTURL":"https://b8bxmklrd4.execute-api.eu-west-1.amazonaws.com/pre/clubcaza/redsys",
            "DS_MERCHANT_ORDER": menchantOrderGenerator(),
            "DS_MERCHANT_TERMINAL":"049",
            "DS_MERCHANT_TRANSACTIONTYPE":"0",
            "DS_MERCHANT_URLKO":"http://localhost:3000/pago",
            "DS_MERCHANT_URLOK":"http://localhost:3000/pago",
            "DS_MERCHANT_IDENTIFIER":"REQUIRED"
        } 

        let merchant_key="sq7HjrUOBfKmC576ILgskD5srU870gJ7" 

        <form style={{display: "none"}} name="from" id="formPayment" action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" ref={formRef}>  

        Información tarjeta de prueba: 

        Nº de tarjeta: 4548810000000003 
        Caducidad 12/49 
        Cvv: 123 
    */

    const setSignVariables = () => {
        let tpvdata={
            "DS_MERCHANT_AMOUNT":"9900",
            "DS_MERCHANT_CURRENCY":"978",
            "DS_MERCHANT_MERCHANTCODE":"97888812",
            "DS_MERCHANT_MERCHANTURL":"https://ulcbbyxid6.execute-api.eu-south-2.amazonaws.com/pro/clubcaza/redsys", 
            "DS_MERCHANT_ORDER": menchantOrderGenerator(), 
            "DS_MERCHANT_TERMINAL":"1", 
            "DS_MERCHANT_TRANSACTIONTYPE":"0", 
            "DS_MERCHANT_URLKO":"https://www.tierradecaza.com/pago-resultado", 
            "DS_MERCHANT_URLOK":"https://www.tierradecaza.com/pago-resultado", 
            "DS_MERCHANT_IDENTIFIER":"REQUIRED",
            "DS_MERCHANT_MERCHANTDATA": JSON.stringify(state)
        };

        let merchant_key="5vTr8JM+/oOu4RqMtGRfgFDhuCKwj6GL"
        var merchantWordArray = cryptojs.enc.Utf8.parse(JSON.stringify(tpvdata));
        let baseMerchant = merchantWordArray.toString(cryptojs.enc.Base64)
        // Decode key
        var keyWordArray = cryptojs.enc.Base64.parse(merchant_key);
        // Generate transaction key
        var iv = cryptojs.enc.Hex.parse("0000000000000000");
        var cipher = cryptojs.TripleDES.encrypt(tpvdata.DS_MERCHANT_ORDER, keyWordArray, {
            iv:iv,
            mode: cryptojs.mode.CBC,
            padding: cryptojs.pad.ZeroPadding
        });
        // Sign
        let sign = cryptojs.HmacSHA256(baseMerchant, cipher.ciphertext);
        setFormState({...formState, merchantBase64: baseMerchant, signatureBase64: sign.toString(cryptojs.enc.Base64)});
    };
    
    const generateRandomString = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const strLength = 1;
        let randStr  = "";
        for (let i = 0; i <= strLength; i++) {
            let randomNumber = Math.floor(Math.random() * chars.length);
            randStr += chars.substring(randomNumber, randomNumber +1);
        }
        return randStr;
    }
    const menchantOrderGenerator = () =>{
        const today = new Date();
        const dateString = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}${today.getHours().toString().padStart(2, '0')}`;
        const randomNumber = generateRandomString();
        return dateString + randomNumber.toString();
    }
    
    return(
        <>
            <form style={{display: "none"}} name="from" id="formPayment" action="https://sis.redsys.es/sis/realizarPago" method="POST" ref={formRef}> 
            <input name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
                <input id="Ds_MerchantParameters" name="Ds_MerchantParameters" value={formState.merchantBase64}/>
                    <input id="Ds_Signature" name="Ds_Signature" value={formState.signatureBase64}/>
                    <input type="submit"/>
            </form>
        
            <Box p={3} align="center">
                <CircularProgress />
            </Box>
                
        </>
            
    );

}
