import fondo from "../../../assets/fondo_bloque1.png";
import { Box, Container, Typography } from "@mui/material";
import AboutUs from "../../../components/public-components/about-us/AboutUs";
import HunterOffers from "../../../components/public-components/hunter-offers/HunterOffers";
import Raffles from "../../../components/public-components/raffles/Raffles";
import Blog from "../../../components/public-components/blog/Blog";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import Joinmembers from "../../../components/public-components/joinmember/Joinmember";
import { useNavigate } from 'react-router-dom';

export const Landing = () => {

  const navigate = useNavigate();
  
  return (
    <>
      <Container className="public-section-member" maxWidth={false} style={{ backgroundImage: `url(${fondo})` }}>
        <Box className="public-section-member-box">
          <Typography variant="h1">¡Únete a la Red Digital de Cazadores y empieza a disfrutar de todas sus ventajas!</Typography>
          <Box>
            <Box className="public-section-member-text">
              <ul>
                <li>✓  Sorteos en Las Reservas.</li>
                <li>✓  Sorteos gratuitos para socios.</li>
                <li>✓  Oferta directa del club.</li>
                <li>✓  Revista Trofeo.</li>
                <li>✓  Gestoría cinegética.</li>
                <li>✓  Market place.</li>
              </ul>
              <br/>
            </Box>
            <button className="public-section-member-button" onClick={() => navigate("/hacerse-socio")}>Hazte socio</button>
          </Box>
        </Box>
      </Container>
      <AboutUs />
      <Joinmembers />
      <HunterOffers />
      <Raffles />
      <Blog />
      <JoinIn />
      <Contact />
    </>
  );
}
