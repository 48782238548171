import React from 'react';
import { SearchInput, TextInput, TextField, DateField, TopToolbar, SelectColumnsButton, FilterButton, ExportButton, ListBase, ListToolbar, Pagination, DatagridConfigurable, useRecordContext, SelectInput } from 'react-admin';
import { Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';



export let tipoGestion = "";

export const GestionesList = (props) => {

    const gestionesFilters = [
        <SearchInput className="cdc-backoffice-filters-search" source="q" alwaysOn/>,
        <TextInput label="Titulo" source="titulo" />,
        <SelectInput label="Estado" source="status" emptyText="-" choices={[
            { id: 'En Revisión', name: 'En Revisión' },
            { id: 'Nuevo', name: 'Nuevo' },
            { id: 'Cerrada', name: 'Cerrada' },
        ]} />,
        <TextInput label="Tipo de Gestión" source="tipoGestion" />
    ];

    const GestionesListActions = () => (
        <TopToolbar sx={{alignItems:"flex-end", padding: "0 !important"}}>
                <SelectColumnsButton />
                <FilterButton filters={gestionesFilters}/>
                <ExportButton/>
        </TopToolbar>
    );

    const MyList = ({children, ...props}) => (
        <ListBase {...props} perPage={15}>
            <ListToolbar className="cdc-backoffice-filters" sx={{alignItems:"center"}}
                filters={gestionesFilters}
                actions={<GestionesListActions/>}
            />
            <Card sx={{marginTop: "20px", paddingRight: "16px", paddingLeft: "8px"}}>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[5, 10, 15, 25, 50]}/>
        </ListBase>
    );

    const MyButton = () => {
        const record = useRecordContext();
        const handleClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let url = window.location.href;
            window.open(url + "/" + record.id,'_blank');
            // the current row id is record.id
            // do something with it
        };
        return <button className="cdc-backoffice-newtab" onClick={(e) => handleClick(e)} type="button"><><OpenInNewIcon /></></button>;
    }
    
    return (
        <MyList title="Lista de Tickets">
            <DatagridConfigurable bulkActionButtons={false} rowClick="edit" omit={['id']} sx={{
                '& .column-titulo': { overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }
            }}>
                <MyButton label="Abrir nueva pestaña" />
                <DateField label="Fecha de Modificación" source="fechaModificacionGestion" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
                <TextField label="Estado" source="status" />
                <TextField source="id"/>
                <TextField source="titulo"/>
                <TextField label="Usuario" source="idUsuario"/>
                <TextField label="Tipo de Gestión" source="tipoGestion" />
                <DateField label="Fecha de Creacion" source="fechaCreacion" showTime={true} options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}}/>
            </DatagridConfigurable>
        </MyList>

    )

}