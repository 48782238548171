import { Box, Container, Link } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/cdc-logo-footer.svg';
import googleStore from "../../../assets/google-play.svg";
import linkedin from "../../../assets/linkedin.svg";
import twitter from "../../../assets/twitter.svg";
import facebook from "../../../assets/facebook.svg";
import instagram from "../../../assets/instagram.svg";
import { InstallAppStore } from "../../installationapp/InstallAppStore";
import { useState } from "react";
import { InstallPlayStore } from "../../installationapp/InstallPlayStore";


export default function Footer() {
    const navigate = useNavigate();
    
    const handleLinkClick = (pdfSrc) => {
        navigate('/documentos-legal', {state:  pdfSrc});
    }

    function FooterView() {
        return (
            <>
                <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/AVISO_LEGAL.pdf")}>Avisos Legales</Link> |
                <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/POLITICA_DE_PRIVACIDAD.pdf")}>Política de privacidad</Link> |
                <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CONDICIONES_PARTICULARES_DE_CONTRATACION.pdf")}>Política de contratación</Link> |
                <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CONDICIONES_GENERALES_DE_USO.pdf")}>Condiciones Generales de Uso</Link> |
                <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CERTIFICADO_RGPD-LOPD-GDD.pdf")}>Certificado RGPD</Link> |
                <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CERTIFICADO_LSSICE.pdf")}>Certificado LSSICE</Link> .
            </>
        );
    }

    return(
        <>
            <Container component="footer" className="public-footer" maxWidth={false}>
                <Box>
                    <figure className="public-footer-logo">
                        <img src={logo} alt="Logo de Club de Caza"/>
                    </figure>
                    <Box className="public-footer-rrss">
                        <Link href="https://es.linkedin.com/company/club-tierra-de-caza/" target="_blank" rel="noopener noreferrer">
                            <figure>
                                <img src={linkedin} alt="Imagen enlace a LinkedIn"/>
                            </figure>
                        </Link>
                        <Link href="https://twitter.com/tierradecaza" target="_blank" rel="noopener noreferrer">
                            <figure>
                                <img src={twitter} alt="Imagen enlace a Twitter"/>
                            </figure>
                        </Link>
                        <Link href="https://es-es.facebook.com/clubtierradecaza/" target="_blank" rel="noopener noreferrer">
                            <figure>
                                <img src={facebook} alt="Imagen enlace a Facebook"/>
                            </figure>
                        </Link>
                        <Link href="https://www.instagram.com/clubtierradecaza/" target="_blank" rel="noopener noreferrer">
                            <figure>
                                <img src={instagram} alt="Imagen enlace a Instagram"/>
                            </figure>
                        </Link>
                    </Box>
                    <Box className="public-footer-buttons">
                        <InstallAppStore />
                        <InstallPlayStore />
                    </Box>    
                </Box>
            </Container>
            <Box className="public-footer-legal">
                <p>©2023 clubtierradecaza.es | 
                    <FooterView />
                </p>
             </Box >
        </>
    );
}