import { JoinForm } from '../join/JoinForm';
import { Box, Typography } from "@mui/material";

export function Managment() {
    return(
        <>
            <Box className="public-managment">
                <Typography variant="h1">Gestoría Cinegética</Typography>
                <Typography>
                    En nuestra empresa, estamos comprometidos en brindar a los socios del club las mejores
                    experiencias relacionadas con el mundo de la caza, y una de las ventajas más destacadas que
                    ofrecemos es el acceso exclusivo y sin costes adicionales a nuestra Gestoría Cinegética. Este
                    servicio ofrece asesoramiento y gestión de trámites relacionados con la caza, como la obtención
                    de permisos, seguros, licencias, entre otros, de manera eficiente y profesional.
                </Typography>
            </Box>
            <JoinForm/>
        </>
         
    );
}