import {Avatar, CssBaseline, Box, Typography, Container} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginFormBackoffice from '../../components/forms/formlogin/LoginFormBackoffice'
import Copyright from '../../components/copyright/Copyright'

export default function BOLogin() {
 
    return(
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Gestión Web Tierra
                </Typography>
                <Typography component="h1" variant="h5">
                    de
                </Typography>
                <Typography component="h1" variant="h5">
                    Caza 
                </Typography>
                <LoginFormBackoffice/>    
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
    );
}