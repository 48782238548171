export function SortingService(json, query, resource) {

    function useSortingFields(prop, asc){
        if(resource === "gestiones/allMessages" || resource === "documentos"){
            json.data?.Items ? (json.data.Items.sort(function (a,b) {
                if(asc === "ASC"){
                    return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                }else{
                    return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                }
            })) : (json.Items.sort(function (a,b) {
                if(asc === "ASC"){
                    return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                }else{
                    return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                }
            }))
        }
        else if(resource === "sorteos"){
            json.Items.sort(function (a,b) {
                if(asc === "ASC"){
                    return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                }else{
                    return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                }
            });
        }else{
            json.data.sort(function (a,b) {
                if(asc === "ASC"){
                    return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
                }else{
                    return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
                }
            });
        }
    };

    useSortingFields(query.sort.field, query.sort.order);

};
export default SortingService