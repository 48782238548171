import { Box, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { instance, instanceWP } from "../../../src/utils/axios-config";
import { format } from "date-fns";
import parse from "html-react-parser";
import { RRSS } from "../../components/rrss/RRSS";
import Swal from "sweetalert2";
import { useAuth } from "../../firebase/Auth";

export const RaffleDetails = () => {

    const params = useParams();
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [ raffle, setRaffle ] = useState();
    const [ error, setError ] = useState(false);
    const idSorteo = params.id;

    useEffect(() => {
        loadRaffleDetails(idSorteo);
    },[params]);

    const handleCloseCancel = () => {
        setOpen(false);
        setOpenError(false);
    }

    const loadRaffleDetails = async (id) => {
        instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`};
        const raffleInfo = instanceWP.get(`/sorteo/${id}?acf_format=standard`);
        const raffleInfoAWS = instance.get(`/sorteos/sorteo/${id}`);
        try {
            await Promise.all([raffleInfo,raffleInfoAWS]).then(function(values) {
                setRaffle({...values[0].data.acf, title: values[0].data.title.rendered, sorteo_categoria: values[0].data.sorteo_categoria[0], date: values[0].data.date, active: values[1].data.estadoSorteo});
                return {...values[0].data.acf, title: values[0].data.title.rendered, sorteo_categoria: values[0].data.sorteo_categoria[0], date: values[0].data.date, active: values[1].data.estadoSorteo};
            }).then(async (resp) => {
                if(resp.sorteo_categoria === 9){
                    const subscriptorsNumber = instance.get(`/sorteos/infoSorteoSubscribers/${id}`);
                    const subscriptorsData = instance.get(`/sorteos/subscribers/${id}`);
                    
                    await Promise.all([subscriptorsData,subscriptorsNumber]).then(function(values) {
                        let isSubscribed = values[0].data.some((s) =>{
                            return s.tipo.toLowerCase() === "premium" && s.pk === currentUser.pk;
                        });
                        setRaffle({...resp, apuntados: values[1].data.totalSubscribed, numGanadores: values[1].data.maxWinners, suscrito: isSubscribed});
                    });
                }
            });
        } catch (error) {
            setError(true);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = async () => {
        const body = {
            idUsuario: currentUser.pk,
            idSorteo: idSorteo
        }
        setPending(true);
        try{
            await instance.post(`/sorteos/subscribe`, body).then(function(resp) {
                setOpen(true);
                setRaffle({...raffle, suscrito: true});
            });
        }catch(error){
            setOpenError(true);
        } finally{
            setPending(false);
        }
    };
  
  return (
    <>
        <Box className="cdc-raffles-details">
            <Grid className="cdc-raffle-details-container" container justifyContent="center">
                {loading ? (
                    <Box p={3}>
                        <CircularProgress />
                    </Box>
                    )
                    : 
                    (
                        <>
                            {!error ?
                                <Grid item xs={12}>
                                    {raffle.image &&
                                    <figure className="cdc-raffles-details-img">
                                        <img src={raffle.image ? raffle.image : ""} alt={"Imagen " + raffle.title}/>
                                    </figure>
                                    }
                                    <p className="cdc-raffles-date">Celebración: {raffle.fecha_fin}</p>
                                    <Typography variant="h2" className="cdc-raffles-details-title">{raffle.title}</Typography>
                                    <div className="cdc-raffles-details-content">
                                        {parse(raffle.summary)}
                                    </div>
                                    {(raffle.sorteo_categoria === 9) && !pending && !raffle.suscrito && raffle.active.toLowerCase() === "activo" && currentUser.tipo.toLowerCase() === "premium" &&
                                        
                                        <div className="cdc-raffles-exclusive-subscribe">
                                            <button onClick={handleSubscribe}>Quiero inscribirme</button>
                                        </div>
                                    }
                                    {(raffle.sorteo_categoria === 9) && !pending && !raffle.suscrito && raffle.active.toLowerCase() === "activo" && currentUser.tipo.toLowerCase() === "basic" &&
                                        <div className="cdc-raffles-exclusive-subscribe">
                                            <p className="cdc-raffles-exclusive-basic">Para poder inscribirte primero tienes que ser socio.</p>
                                            <button onClick={() => navigate("/hacerse-socio")}>HACERME SOCIO</button>
                                        </div>
                                    }
                                    {(raffle.sorteo_categoria === 9) && !pending && raffle.suscrito && raffle.active.toLowerCase() === "activo" &&
                                        <div className="cdc-raffles-exclusive-subscribed">
                                            <button>Inscrito</button>
                                        </div>
                                    }
                                    {(raffle.sorteo_categoria === 9) && !pending && raffle.active.toLowerCase() === "celebrado" &&
                                        <div className="cdc-raffles-exclusive-subscribed">
                                            <button>Ya celebrado</button>
                                        </div>
                                    }
                                    {pending &&
                                        <div className="cdc-raffles-exclusive-subscribed">
                                            <button>Enviando...</button>
                                        </div>
                                    }
                                    <h3 style={{alignItems:"center"}}>Precio: {raffle.precio === "0" ? "Gratuito": raffle.precio + " €"}</h3>
                                    {(raffle.sorteo_categoria === 9)  &&
                                    <> 
                                        <div className="cdc-raffles-exclusive-info">
                                            <div className="item-one">
                                                <p className="number">{raffle.apuntados}</p>
                                                <p className="text">Socios apuntados</p>
                                            </div>
                                            <div className="item-two">
                                                <p className="number">{raffle.numGanadores}</p>
                                                <p className="text">Plazas disponibles</p>
                                            </div>
                                        </div>
                                    </>
                                    }
                                    <div className="cdc-raffles-details-content">
                                        {parse(raffle.content)}
                                    </div>
                                </Grid>
                                :
                                <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                            }
                        </>
                    )}
                    <RRSS />
            </Grid>
        </Box>
        <Dialog open={open} onClose={handleCloseCancel} className="cdc-raffles-modal-success">
            <DialogContent className="cdc-raffles-modal-content">
                <h2>¡Te has suscrito al sorteo con éxito!</h2>
                <p>Lorem ipsum...</p>
                <DialogActions className="cdc-raffles-modal-close">
                    <button onClick={handleCloseCancel} variant="contained">Aceptar</button>
                </DialogActions>
            </DialogContent>
        </Dialog>
        <Dialog open={openError} onClose={handleCloseCancel} className="cdc-raffles-modal-success">
            <DialogContent className="cdc-raffles-modal-content">
                <h2>¡Vaya, parece que algo ha fallado!</h2>
                <p>Vuelve a intentarlo más tarde.</p>
                <DialogActions className="cdc-raffles-modal-close">
                    <button onClick={handleCloseCancel} variant="contained">Aceptar</button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </>
  );
}
