import { useEffect, useState } from "react";
import UpdateBrowser from "../public-components/dialogs/UpdateBrowser";
import googleStore from "../../assets/google-play.svg";
import { Link } from "@mui/material";

export const InstallPlayStore = () => {

  const [open, setOpen] = useState(false);
  const [ version, setVersion ] = useState(false);

  const showInstall = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  useEffect(() => {   
    let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    setVersion(raw ? parseInt(raw[2], 10) : false);
  },[]);


  return(
    <>
    {version !== false && version >= 80 ?
        <Link href="https://play.google.com/store/apps/details?id=com.amplifyapp.d3l19eidgr2o8q.main.twa">
            <figure className="google-store">
                <img className="public-footer-img-google" src={googleStore} alt="Imagen Google Play"/>
            </figure>
        </Link>
    :
        <div>
            <button className="cdc-install-button" type="button" onClick={showInstall}>
                <figure className="apple-store">
                    <img className="public-footer-img-google" src={googleStore} alt="Imagen Play Store"/>
                </figure>
            </button>
        </div>
    }
      <UpdateBrowser open={open} setOpen={setOpen}/>
    </>
  );
}