import { JoinForm } from '../join/JoinForm';
import { Box, Typography } from "@mui/material";

export function MarketPlace() {
    return(
        <>
            <Box className="public-marketplace">
                <Typography variant="h1">Market Place</Typography>
                <Typography>
                Una de las ventajas más destacadas que ofrecemos  a nuestros socios es el acceso exclusivo a nuestro market place.
                Con esta herramienta, nuestros socios pueden acceder a una amplia variedad de productos y servicios exclusivos
                relacionados con la caza, como equipamiento, ropa, accesorios y mucho más. 
                <Box fontWeight='700'>
                    !ESTAMOS REMODELANDO ESTA PÁGINA, MUY PRONTO ESTARÁ DISPONIBLE.¡
                </Box>
                </Typography>
            </Box>
            <JoinForm/>
        </>
         
    );
}