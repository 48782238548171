import { Box, Container, Grid, Link, Typography } from "@mui/material";
import  sorteosreservas from "../../../assets/servicios/rebeco.png";
import sorteosgratuitos from "../../../assets/servicios/macho_montes.png";
import ofertadirecta from "../../../assets/servicios/ciervo1.png";
import revistatrofeo from "../../../assets/servicios/gamo.png";
import marketplace from "../../../assets/servicios/ciervo2.png";
import gestoriacinegetica from "../../../assets/servicios/arbol.png";

export default function HunterOffers() {

    return(
        <Container className="public-section-offers" maxWidth={false}>
            <Box className="public-section-offers-box">
                <Typography variant="h1" sx={{marginBottom: "32px"}}><Link href="/ofertas">Servicios del Club</Link></Typography>
                <Typography>
                    En nuestra empresa, estamos dedicados a brindar una experiencia única y completa a los cazadores,
                    amantes del campo y los grandes espacios naturales. Para lograr esto, ofrecemos una amplia variedad de
                    servicios exclusivos a través de nuestro club de caza. Desde la organización de sorteos y actividades de
                    caza, hasta la gestión de permisos y asesoramiento profesional, nuestro club de caza es el lugar ideal para
                    aquellos que desean disfrutar de la caza de manera responsable y sostenible.
                </Typography>
                <Grid container justifyContent="center" spacing={4} mt={0}>
                    <Grid item xs={12} md={4} className="public-section-offers-items">
                        <a href="/sorteos?categories=19">
                            <div className="public-section-offers-items-image">
                                <figure>
                                    <img src={sorteosreservas} alt="Imagen Sorteo Reservas"/>
                                </figure>
                            </div>
                            <Typography variant="h2">SORTEOS RESERVAS</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={4} className="public-section-offers-items">
                        <a href="/sorteos?categories=8">
                            <div className="public-section-offers-items-image">
                                <figure>
                                    <img src={sorteosgratuitos} alt="Imagen Sorteo Gratuitos"/>
                                </figure>
                            </div>
                            <Typography variant="h2">SORTEOS GRATUITOS</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={4} className="public-section-offers-items">
                        <a href="/sorteos?categories=9">
                            <div className="public-section-offers-items-image">
                                <figure>
                                    <img src={ofertadirecta} alt="Imagen Oferta Directa"/>
                                </figure>
                            </div>
                            <Typography variant="h2">OFERTA DIRECTA</Typography>
                        </a>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={4} mt={0}>
                    <Grid item xs={12} md={4} className="public-section-offers-items">
                        <a href="/revista-trofeo">
                            <div className="public-section-offers-items-image">
                                <figure>
                                    <img src={revistatrofeo} alt="Imagen Oferta pública"/>
                                </figure>
                            </div>
                            <Typography variant="h2">REVISTA TROFEO</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={4} className="public-section-offers-items">
                        <a href="/marketplace">
                            <div className="public-section-offers-items-image">
                                <figure>
                                    <img src={marketplace} alt="Imagen Oferta privada"/>
                                </figure>
                            </div>
                            <Typography variant="h2">MARKET PLACE</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={4} className="public-section-offers-items">
                        <a href="/gestoria">
                            <div className="public-section-offers-items-image">
                                <figure>
                                    <img src={gestoriacinegetica} alt="Imagen Oferta pública"/>
                                </figure>
                            </div>
                            <Typography variant="h2">GESTORÍA CINEGÉTICA</Typography>
                        </a>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}