import { Box, CircularProgress, Container, Grid, Link, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { instanceWP } from "../../../utils/axios-config";
import parse from "html-react-parser";

export default function Related({type, excludeId, catId, text, category}) {

    const [loading, setLoading] = useState(true);
    const [ related, setRelated ] = useState([]);

    const mapping = {
        noticia : "blogs",
        oferta : "ofertas",
        sorteo : "sorteos"
    };

    const loadRelatedData = useCallback (async () => {
        try {
            await instanceWP.get(`/${type}?per_page=4&${type}_categoria=${catId}&exclude=${excludeId}&acf_format=standard`).then((response) => {
                let acf = [];
                response.data.map(r => (
                    acf.push({...r.acf, id: r.id, title: r.title.rendered, [type + "_categoria"]: r[type + "_categoria"][0]})
                ));
                let acfCopy = JSON.parse(JSON.stringify(acf));
                return {acf, acfCopy};

            }).then(async (args) => {
                await instanceWP.get(`/${type}_categoria`).then((cat) =>{
                    args.acf.forEach((item, index) => {
                        for (var i = 0; i < cat.data.length; i++) {
                            if(item[type + "_categoria"] === cat.data[i].id){
                                args.acfCopy[index][type + "_categoria"] = cat.data[i].name;
                            }
                        }
                    });
                    setRelated(args.acfCopy);
                });
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    },[type, catId, excludeId]);

    useEffect(() => {
        if(catId){
            loadRelatedData();
        }else{
            setLoading(false);
        }
      }, [loadRelatedData, catId]);

    return(
        <Container className="public-related" maxWidth={false}>
            <Box className="public-related-box">
                <Typography variant="h1">Elementos Relacionados</Typography>
                <Grid container justifyContent="center" spacing={3}>
                    {loading ? (
                        <Box p={3}>
                            <CircularProgress />
                        </Box>
                        )
                        : 
                        (
                            <>
                            {related.length === 0 ?
                                <div className="public-related-none"><strong>No hay elementos relacionados disponibles.</strong></div>
                                :
                                (
                                    related.map((r) => {
                                        return (
                                            <Grid className="public-related-card" item xs={12} md={related.length <= 2 ? 6 : (related.length === 3 ? 4 : 3)} key={"related-id-" + r.id}>
                                                <figure>
                                                    <img src={r.image ? r.image : ""} alt={"Imagen " + r.title}/>
                                                </figure>
                                                {r[type + "_categoria"] &&
                                                    <Link href={"/" + mapping[type] + "?categories=" + catId}>
                                                        <Typography className="public-category">{r[type + "_categoria"]}</Typography>
                                                    </Link>
                                                }
                                                <Typography variant="h2" title={r.title}>{r.title}</Typography>
                                                <div className="public-related-box-container">{parse(r.summary)}</div>
                                                <Box className="public-related-read" align="left">
                                                    <Link href={
                                                        (type === "noticia" ? "/blog/" + r.id : "/" + type + "/" + r.id) + "?text=" + text + "&categories=" + category
                                                        }>Leer más...</Link>
                                                </Box>
                                            </Grid>
                                        );
                                    })
                                )
                            }
                            </>
                        )
                    }
                </Grid>
            </Box>
        </Container>
    );
}