import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Create, DateInput, PasswordInput, required, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useNotify } from "react-admin";
import { useAuth } from "../../../firebase/Auth";
import { useFormContext } from "react-hook-form";

export const UsuariosCreate= () => {

    //const [open, setOpen] = useState(false);
    //const [nroSocio, setNroSocio] = useState();
    //const [premium, setPremium] = useState();
    const notify = useNotify();

    const PostCreateToolbar = props => {
        //const notify = useNotify();
        //const navigate = useNavigate();
    
        return (
            <Toolbar {...props}>
                <SaveButton
                    label="Guardar"
                    mutationOptions={{
                        onSuccess: () => {
                            //notify(`El documento se ha creado correctamente`, { type: 'success' });
                            //navigate(-1);
                        },
                        onError: (error) => {
                            //notify(`Ha habido un problema creando el documento`, { type: 'error' });
                        },
                    }}
                />
            </Toolbar>
        );
    };

    {/*const handleChangeTipo = (event) => {
        if (event.target.value === "Premium"){
            getNroSocio();
        }else{
            setPremium(false);
        }
    }*/}

    const validatePhone = (value) => {
        const error = {};
        if (!/^[0-9]{9}$/i.test(value) && value) {
            error.message = 'El teléfono debe contener 9 dígitos';
            return error;
        }
        return undefined;
    }

    {/*const getNroSocio = async () => {
        setOpen(true);
        instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
        try{
            await instance.get(`/clubCaza/nroSocio`).then(function(resp){
                setPremium(true);
                setNroSocio(resp.data);
            });
        }catch(error){
            setPremium(false);
            notify(`Se ha producido un error al intentar obtener el código de usuario`, { type: 'error' });
        }finally{
            setOpen(false);
        }
    }*/}
/*
    const validateIban = (iban) => {
        if(!/^[A-Z]{2}[0-9A-Z]*$/.test(iban)){
            return "El iban no es válido";
        }
        return undefined;
    }
    */

    const validateEmail = (email) => {
        let result = "Debe ser un correo válido";
        
        let expresion_regular_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
       
        if(expresion_regular_email.test(email) === true){
            result = undefined;
        }
        return result;
    }

    const validateCodigoPostal = (codigoPostal) => {
        let result = undefined;
        if(codigoPostal !== "" && codigoPostal !== null && codigoPostal !== undefined && !/^[0-9]{5}$/i.test(codigoPostal)){
            result = "El código postal debe tener 5 dígitos"
        }
        return result;
    }

    const validateFechaRenovacion = (fecha) => {
        const inputDate = new Date(fecha);
        const currentDate = new Date();
        if(inputDate.getTime() < currentDate.getTime()){
            return "La fecha de renovación debe ser mayor que la fecha actual";
        }
        return undefined;
    }

    const validateDni = (dni) => {
        let numero;
        let letr;
        let letra;
        let expresion_regular_dni;
        let result = "Debe ser un DNI válido";
       
        expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
       
        if(expresion_regular_dni.test(dni) === true){
           numero = dni.substr(0,dni.length-1);
           letr = dni.substr(dni.length-1,1);
           numero = numero % 23;
           letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
           letra = letra.substring(numero,numero+1);
          if (letra===letr.toUpperCase()) {
             result = undefined;
           }
        }
        return result;
    }

    /*const FechaRenovacion = () => {
        const {setValue} = useFormContext();
        useEffect(() => {
            let aYearFromNow = new Date();
            aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

            setValue("fechaRenovacion", aYearFromNow);
        },[]);

        return(
            <>
            <DateInput label="Fecha de renovación" source="fechaRenovacion" validate={[required(), validateFechaRenovacion]}/>
            </>
        )
    }*/

    return(
        <>
            <Create>
                <SimpleForm toolbar={<PostCreateToolbar />}>
                    <div className="edicion-title">
                        <div className="edicion-title-titulo">
                            Creación nuevo usuario
                        </div>
                    </div>
                    <div className="edicion-user-inputs">
                        <div className="edicion-user-inputs-1">
                            <TextInput source="nombre" validate={[required()]} />
                            <TextInput source="apellido" validate={[required()]} />
                            <TextInput label="Correo" source="id" validate={[required(), validateEmail]} />
                            <TextInput label="Telefono "source="nTelefono" validate={[required(), validatePhone]}/>
                            <TextInput source="dni" validate={[required(), validateDni]}/>
                            <PasswordInput source="password" validate={[required()]}/>
                        </div>
                        <div className="edicion-user-inputs-2">
                            <TextInput source="direccion"/>
                            <TextInput validate={validateCodigoPostal} source="codigoPostal"/>
                            <TextInput source="iban"/>
                            <TextInput source="poblacion"/>
                            <SelectInput source="provincia" className="noMarginImp" emptyText="-" choices={[
                                    { id: 'Álava/Araba', name: 'Álava/Araba' },
                                    { id: 'Albacete', name: 'Albacete' },
                                    { id: 'Alicante', name: 'Alicante' },
                                    { id: 'Almería', name: 'Almería' },
                                    { id: 'Asturias', name: 'Asturias' },
                                    { id: 'Ávila', name: 'Ávila' },
                                    { id: 'Badajoz', name: 'Badajoz' },
                                    { id: 'Basic', name: 'Basic' },
                                    { id: 'Baleares', name: 'Baleares' },
                                    { id: 'Barcelona', name: 'Barcelona' },
                                    { id: 'Burgos', name: 'Burgos' },
                                    { id: 'Cáceres', name: 'Cáceres' },
                                    { id: 'Cádiz', name: 'Cádiz' },
                                    { id: 'Cantabria', name: 'Cantabria' },
                                    { id: 'Castellón', name: 'Castellón' },
                                    { id: 'Ceuta', name: 'Ceuta' },
                                    { id: 'Ciudad Real', name: 'Ciudad Real' },
                                    { id: 'Córdoba', name: 'Córdoba' },
                                    { id: 'Cuenca', name: 'Cuenca' },
                                    { id: 'Gerona/Girona', name: 'Gerona/Girona' },
                                    { id: 'Granada', name: 'Granada' },
                                    { id: 'Guadalajara', name: 'Guadalajara' },
                                    { id: 'Guipúzcoa/Gipuzkoa', name: 'Guipúzcoa/Gipuzkoa' },
                                    { id: 'Huelva', name: 'Huelva' },
                                    { id: 'Huesca', name: 'Huesca' },
                                    { id: 'Jaén', name: 'Jaén' },
                                    { id: 'La Coruña/A Coruña', name: 'La Coruña/A Coruña' },
                                    { id: 'La Rioja', name: 'La Rioja' },
                                    { id: 'Las Palmas', name: 'Las Palmas' },
                                    { id: 'León', name: 'León' },
                                    { id: 'Lérida/Lleida', name: 'Lérida/Lleida' },
                                    { id: 'Lugo', name: 'Lugo' },
                                    { id: 'Madrid', name: 'Madrid' },
                                    { id: 'Málaga', name: 'Málaga' },
                                    { id: 'Melilla', name: 'Melilla' },
                                    { id: 'Murcia', name: 'Murcia' },
                                    { id: 'Navarra', name: 'Navarra' },
                                    { id: 'Palencia', name: 'Palencia' },
                                    { id: 'Pontevedra', name: 'Pontevedra' },
                                    { id: 'Salamanca', name: 'Salamanca' },
                                    { id: 'Segovia', name: 'Segovia' },
                                    { id: 'Sevilla', name: 'Sevilla' },
                                    { id: 'Soria', name: 'Soria' },
                                    { id: 'Tarragona', name: 'Tarragona' },
                                    { id: 'Tenerife', name: 'Tenerife' },
                                    { id: 'Teruel', name: 'Teruel' },
                                    { id: 'Toledo', name: 'Toledo' },
                                    { id: 'Valencia', name: 'Valencia' },
                                    { id: 'Valladolid', name: 'Valladolid' },
                                    { id: 'Vizcaya/Bizkaia', name: 'Vizcaya/Bizkaia' },
                                    { id: 'Zamora', name: 'Zamora' },
                                    { id: 'Zaragoza', name: 'Zaragoza' }
                            ]} />
                            <TextInput source="promoCode"/>
                        </div>
                    </div>
                </SimpleForm>
            </Create>
            {/*<Backdrop 
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress style={{'color': 'white'}}/>
                        </Backdrop>*/}
        </>
    );
}