import { Button, CircularProgress, Link, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { instance } from "../../../utils/axios-config";
import { useNotify } from 'react-admin';
import ListIcon from '@mui/icons-material/List';
import { useAuth } from "../../../firebase/Auth";
import parse from "html-react-parser";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ClearIcon from '@mui/icons-material/Clear';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Box } from "@mui/system";
import { compressImageUpload, validFileSize } from "../../../services/Commons";
import PreviewDoc from "../dialogs/PreviewDoc";

export const ThreadDetail = ({openMsgList, setOpenMsgList, setOpen, record, thread, handleOpenThread}) => {

    const [ message, setMessage ] = useState({
        text: "",
        files: [],
    });

    const [ attachment, setAttachment ] = useState();
    const [ typeFile, setTypeFile ] = useState();
    const [ errors, setErrors ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ loadingButton, setLoadingButton ] = useState(false);
    const [ openPreview, setOpenPreview ] = useState(false);
    const { backofficeToken } = useAuth();
    const [loadingCompress, setLoadingCompress] = useState(false);

    useEffect(() => {
        handleLoadFiles();
    },[thread]);

    const handleLoadFiles = async () => {
        if(thread.attach !== undefined){
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
            try{
                await instance.get(`/gestiones/getAttach/${encodeURIComponent(thread.attach)}`).then(function(resp){
                    let arr = thread.attach.split("/"); 
                    let txt = arr[arr.length -1];
                    let arrTxt = txt.split(".");
                    setTypeFile(arrTxt[arrTxt.length -1]);
                    setAttachment(resp.data);
                });
            }catch(error){
                console.log(error);
            }finally{
                setLoading(false);
            }
        }else{
            setAttachment(undefined);
            setLoading(false);
        }
        
    }

    const StatusTicket = () => {
        let estado = "revision";
        if(record.status.toLowerCase() === "nuevo"){
            estado = "nuevo";
        }else if(record.status.toLowerCase() === "cerrada"){
            estado = "cerrada";
        }
        return (<span className={"edicion-title-status " + estado + " edicion-title-details"}>{record.status}</span>);
    }

    const HandleSubmit = (e) => {
        const notify = useNotify();
        const CheckAndSubmit = () => {
            setErrors({});
            if(validateForm()){
                sendForm(notify);
            }
        }

        return(
            <>
            {!loadingButton ?
                <Button variant="contained" type="submit" onClick={CheckAndSubmit} disabled={loadingCompress}>
                    Responder
                </Button>
                :
                <Button disabled variant="contained" type="button">
                    Enviando...
                </Button>}
            </>
        );
    };

    const validateForm = () => {
        let valid = true;
        if(message.text === "" || message.text === undefined || message.text === null){
            valid = false;
            setErrors(errors => ({...errors, errorMessage: true, errorMessageMsg: "Requerido"}));
        }
        return valid;
    }

    const handleBack = () => {
        setOpen(false);
    }

    const handleOpenMsgList = () => {
        if(openMsgList){
            setOpenMsgList(false);
        }else{
            setOpenMsgList(true);
        }
    }

    const changeMessage = (e) =>{
        setMessage({...message, text: e.target.value});
        //setMessage({...message, editorState: e});
    }

    const validFileType = (file) => {
        let fileType = null;
        let validType = false;
        try{
            fileType = file.type.split("/").pop();
            if(fileType === "pdf" || fileType === "jpg" || fileType === "png" || fileType === "jpeg"){
                validType = true;
            }
        }catch(error){
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "Se ha producido un error al comprobar el archivo."}));
        }finally{
            return validType;
        }
    }

    const handleChangeFile = async (e) => {
        let validType = validFileType(e.target.files[0]);
        let validSize = validFileSize(e.target.files[0]);
        if(!validType){
            setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El formato del archivo debe ser png, jpg o pdf."}));
        }
        else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
            }
        }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                let result = e.target.files[0];
                try{
                    setLoadingCompress(true);
                    result = await compressImageUpload(e.target.files[0]);
                }catch(error){

                }finally{
                    //if(previusCount === parseInt(countInput.current.innerText)){
                        setLoadingCompress(false);
                        readFile(result);
                    //}
                }
            }
        }
    };

    const readFile = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let temp = [];
            temp = message.files;
            temp.push({
                content: reader.result.split(",")[1],
                formato: "." + file.type.split("/").pop(),
                name: file.name
            });
            setMessage({...message, files: temp});

            setErrors(errors => ({...errors, errorContent: false, errorContentMsg: ""}));
        };
        reader.onerror = function (error) {
            setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "Vaya, parece que el algo ha salido mal..."}));
        };
    }

    const handleDeleteFile = (fileName) => {
        let temp = message.files.filter(function(f) { return f.name !== fileName });
        setMessage({...message, files: temp});
    };
    
    const ThreadList = () => {
        record.thread.sort((a,b) => {
            return new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime();
        });
        return record.thread.map((thr, index) => {
            return (<tr className="cdc-backoffice-msg" key={"message-" + index} onClick={() => {handleOpenThread(record, thr);}}>
                    <td className="cdc-backoffice-msg-data">
                        <p className="cdc-backoffice-msg-content-date">{format(new Date(thr.fechaCreacion), "dd/MM/yyyy HH:mm")}</p>
                    </td>
                    <td>
                        <p className="cdc-backoffice-msg-content-sender">{thr.idRemitente}</p>
                    </td>
                    
                    {thr.mensaje && <td className="cdc-backoffice-msg-summary">{parse(thr?.mensaje)}</td>}
            </tr>);
        });
    };

    const sendForm = async (notify) => {
        setLoadingButton(true);
        instance.defaults.headers.common = {'Authorization': `Bearer ${backofficeToken}`}
        try{
            let body = {
                remitente : "Admin",
                idUsuario :  record.idUsuario,
                mensaje : message.text
            }
            await instance.post(`/gestiones/newMesage/${record.id}`, body).then( async (response) => {
                let bodyFile;
                let allFiles = [];
                message.files.map((f) => {
                    bodyFile = {
                        idUsuario: record.idUsuario,
                        idGestion: record.id,
                        nombre: "Archivo adjunto",
                        formato: f.formato,
                        content: f.content
                    }
                    allFiles.push(instance.post(`/gestiones/attachToThread/${response.data.idThread}`, bodyFile));
                });
                Promise.all(allFiles).then(function(values) {
                    setOpen(false);
                    notify(`Mensaje enviado`, { type: 'success' });
                }).catch(function(e) {
                    notify(`Se ha producido un error al enviar el mensaje`, { type: 'error' });
                });
            }).catch((error) => {
                notify(`Se ha producido un error al enviar el mensaje`, { type: 'error' });
            });
        }catch(error){
            notify(`Se ha producido un error al enviar el mensaje`, { type: 'error' });
        }finally{
            setLoadingButton(false);
        }
    }

    return(
        <>
        <div className="cdc-backoffice-thread-main">
            <div className="cdc-backoffice-thread-container">
                <form className="cdc-backoffice-thread-form">
                    <div className="cdc-backoffice-thread-form-container">
                        {loading ? 
                            (
                            <Box align="center">
                                <CircularProgress />
                            </Box>
                            )
                            :
                            <>
                                <div className="edicion-title">
                                    <StatusTicket />
                                    <div>
                                        <div>
                                            <span className="edicion-title-titulo">{record.titulo}</span>
                                            <span style={{fontWeight:"500", fontSize:"1rem"}}>#</span>
                                            <span className="edicion-title-id">{record.id}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="edicion-ticket-inputs cdc-backoffice-thread-space">
                                    <div className="edicion-ticket-inputs-1">
                                        <div>
                                            <Typography variant="subtitle1" className="edicion-ticket-inputs-typo">Información del hilo:</Typography>
                                            <div className="edicion-ticket-inputs-1-2 row">
                                                <TextField className="cdc-backoffice-thread-textfield row" disabled label="Remitente" value={thread.idRemitente} sx={{flex: 1}}/>
                                                <TextField className="cdc-backoffice-thread-textfield row" disabled label="Fecha de respuesta" value={format(new Date(thread.fechaCreacion), "dd/MM/yyyy HH:mm")} />
                                            </div>
                                            {attachment && 
                                                <div style={{marginBottom: "15px"}}>
                                                    <Button label="Previsualizar documento" onClick={() => setOpenPreview(true)}>Previsualizar archivo adjunto</Button>
                                                    {typeFile !== "pdf" &&
                                                        <Link href={attachment} className="cdc-backoffice-button-download" underline="none" download>Descargar documento</Link>
                                                    }
                                                    {/*<div className="edicion-ticket-inputs-1-2 row">
                                                        <a className="cdc-backoffice-thread-download" href={attachment} download>Descargar archivo adjunto</a>
                                                    </div>*/}
                                                </div>
                                            }
                                            <div className="edicion-ticket-inputs-1-2 row">
                                                <TextField label="Mensaje" disabled multiline minRows={2} maxRows={8} value={thread.mensaje} fullWidth/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!(record.status.toLowerCase() === "cerrada") &&
                                    <div className="edicion-ticket-inputs cdc-backoffice-thread-response">
                                        <div className="edicion-ticket-inputs-1">
                                            <div className="flex-container align-center">
                                                {message.files.length > 0 ?
                                                    <div style={{marginBottom: "12px"}}>
                                                        {message.files.map((file, index) => {
                                                            return (
                                                                <div className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)"}}>
                                                                    <div key={"archivo-adjunto-" + index} className="flex-container align-center">
                                                                        <FilePresentIcon style={{ fill: '#1565c0', width: '30px', height: '30px' }}/>
                                                                        <p>{file.name}</p>
                                                                    </div>
                                                                    <button className="cdc-backoffice-file-delete" type="button" style={{marginLeft: "5px"}} onClick={() => handleDeleteFile(file.name)}><ClearIcon style={{ fill: '#1565c0'}}/></button>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </div>
                                            <div>
                                                {/*<Editor editorState={message.editorState} onEditorStateChange={changeMessage}/>*/}
                                                <TextField InputLabelProps={{ shrink: true }} label="Respuesta *" placeholder="" multiline minRows={2} maxRows={8} value={message.text} onChange={changeMessage} fullWidth
                                                error={errors.errorMessage}
                                                helperText={errors.errorMessageMsg}/>
                                            </div>
                                            <div>

                                            </div>
                                            {message.files.length !== 1 ?
                                                <>
                                                    {loadingCompress ?
                                                        <Box className="loading-inline">
                                                            <CircularProgress align="center"/>
                                                            <p>Comprimiendo imagen...</p>
                                                        </Box>
                                                    : 
                                                        <div>
                                                            <button type="button" className="cdc-backoffice-file" >
                                                                <input className="cdc-backoffice-file-upload" id="cdc-backoffice-file-upload" type="file" name="cdc-backoffice-file-upload" onChange={handleChangeFile} onClick={(event)=> { event.target.value = null}}/>
                                                                <label htmlFor="cdc-backoffice-file-upload" className="cdc-backoffice-file-label">
                                                                    <DriveFolderUploadIcon alt={"Subir nuevo documento"}/>
                                                                    Añadir archivo
                                                                </label>
                                                            </button>
                                                            {errors.errorFile && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorFileMsg}</span>}
                                                        </div>
                                                    }
                                                </>
                                            :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                }
                                {openMsgList && <>
                                    <Typography variant="subtitle1" className="edicion-ticket-inputs-typo">Hilo de mensajes:</Typography>
                                    <table className="cdc-backoffice-msg-list">
                                        <tbody>
                                            <ThreadList />
                                        </tbody>
                                    </table>
                                    </>
                                }
                            </>
                        }
                    </div>
                    {loading ? <></>: 
                        <div className="cdc-backoffice-thread-buttons">
                            <Button variant="outlined" onClick={handleBack}>
                                Volver
                            </Button>
                            <Button className="button-toolbar" variant="contained" size="large" label="Lista de Mensajes" title="Lista de Mensajes" onClick={handleOpenMsgList}><ListIcon/>Lista de mensajes</Button>
                            {!(record.status.toLowerCase() === "cerrada") && <HandleSubmit /> }
                        </div>
                    }
                </form>
            </div>
        </div>
        <PreviewDoc open={openPreview} setOpen={setOpenPreview} document={attachment} type={typeFile}/>
        </>
    );
}