import { JoinForm } from '../join/JoinForm';
import { Box, Typography } from "@mui/material";

export function Magazine() {
    return(
        <>
            <Box className="public-magazine">
                <Typography variant="h1">Revista Trofeo</Typography>
                <Typography>
                    En nuestra empresa, estamos comprometidos en brindar a nuestros socios del club las mejores
                    experiencias relacionadas con el mundo de la caza, y una de las ventajas más destacadas de
                    nuestra oferta es la suscripción gratuita a la revista Trofeo Caza y Conservación. Esta revista es
                    reconocida en el mundo de la caza por su calidad editorial y contenido exclusivo, y ofrece a
                    nuestros socios una amplia variedad de artículos, noticias y reportajes sobre la caza y la
                    conservación de la fauna.
                </Typography>
            </Box>
            <JoinForm/>
        </>
         
    );
}