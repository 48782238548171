import React, { useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import  {CssBaseline, Input, FormControl, InputAdornment, InputLabel, Button, TextField, Link, Grid, Box, Typography, CircularProgress} from '@mui/material';
import SegmentIcon from '@mui/icons-material/Segment';
import { instanceAWS, instance } from "../../../utils/axios-config";
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';


const FirstForm = ({formData, setFormData, setCompleted}) => {

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [terms, setTerms] = useState(false);
    const [overAge, setOverAge] = useState(false);
    const [suscription, setSuscription] = useState(false);

    const navigateToWelcome = () => {
        navigate('/welcome');
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleLinkClick = (pdfSrc) => {
        navigate('/documentos-publicos-legales', {state:  pdfSrc});
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const upModalError = (error) => {
        Swal.fire({
            icon: "error",
            title: "error",
            text: error,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Aceptar"
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            sendForm();
        }
    };

    const validateForm = () =>{
        let valid = true;
        if(formData.nombre === undefined || formData.nombre === ''){
            setErrors(errors => ({...errors, errorName: true, errorNameMsg: "El nombre no debe estar vacío"}));
            valid = false;
        }
        if(formData.apellido === undefined || formData.apellido === ''){
            setErrors(errors => ({...errors, errorSurname: true, errorSurnameMsg: "El apellido no debe estar vacío"}));
            valid = false;
        }
        if(formData.email === undefined || formData.email === ''){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no debe estar vacío"}));
            valid = false;
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es válido"}));
            valid = false;
        }
        if(formData.nTelefono === undefined || formData.nTelefono === ''){
            setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: "El teléfono no debe estar vacío"}));
            valid = false;
        }else if(!/^[0-9]{9}$/i.test(formData.nTelefono)){
            setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: "El teléfono debe contener 9 dígitos"}));
            valid = false;
        }
        if(formData.password === undefined || formData.password === ''){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña no puede estar vacía"}));
            valid = false;
        }else if(formData?.password.length < 3){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña debe ser de al menos 3 dígitos"}));
            valid = false;
        }
        if(overAge === false){
            setErrors(errors => ({...errors, errorOverAge: true, errorOverAgeMsg: "Debes indicarnos que eres mayor de edad."}));
            valid = false;
          }
          if(terms === false){
            setErrors(errors => ({...errors, errorTerms: true, errorTermsMsg: "Debes indicarnos que aceptas nuestros términos."}));
            valid = false;
          }
        return valid;
    }

    const sendForm = async() =>{
        try {
            setLoading(true);
            await instanceAWS.post(`/registerUser`, formData).then(() => {
                setCompleted(true);
                setLoading(false);
            });
        } catch (error) {
            let errorsLogin = "Se ha producido un error inesperado, vuelve a intentarlo más tarde.";
            upModalError(errorsLogin);
            setLoading(false);
        }
    }

    const changeSuscription = () => {
        setSuscription(!suscription);
        setFormData({
            ...formData,
            suscripcion: !suscription,
        });
    }
        
    return(
        <>
            <CssBaseline />
                <Box
                sx={{
                    background: '#FFFFFF',
                    paddingTop: "56px",
                    position: "relative",
                    height: "100%"
                }}
                >
                <div className='login-header'component="form">
                    <Box className='login-header-left'>
                        <ArrowBackIosNewIcon onClick={navigateToWelcome} className='login-header-backicon'/>
                        <Typography component="h1" variant="h5" className="login-header-typography">
                            Registro
                        </Typography>
                    </Box>
                        <SegmentIcon className='login-header-listicon'/>
                    </div>
                    <Box component="form" onSubmit={handleSubmit} sx={{width: "100%"}} className="login-form" noValidate>
                        <div className='´login-form-container'>
                                <FormControl sx={{ m: 1, marginBottom:"20px"}} variant="standard" className="login-form-container-field">
                                    <InputLabel htmlFor="nombre">NOMBRE*</InputLabel>
                                    <Input
                                        id="nombre"
                                        className='login-form-email'
                                        label="Nombre"
                                        required
                                        name="nombre"
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                nombre: e.target.value,
                                            });
                                        }}
                                        value={formData.nombre}
                                    />
                                    {errors.errorName && <span style={{color: "red"}}>{errors.errorNameMsg}</span>}
                                </FormControl>
                                <FormControl sx={{ m: 1, marginBottom:"20px"}} variant="standard" className="login-form-container-field">
                                    <InputLabel htmlFor="apellido">APELLIDOS*</InputLabel>
                                    <Input
                                        id="apellido"
                                        className='login-form-email'
                                        label="Apellidos"
                                        required
                                        name="apellido"
                                        value={formData.apellido}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                apellido: e.target.value,
                                            });
                                        }}
                                    />
                                    {errors.errorSurname && <span style={{color: "red"}}>{errors.errorSurnameMsg}</span>}
                                </FormControl>
                                <FormControl sx={{ m: 1, marginBottom:"20px"}} variant="standard" className="login-form-container-field">
                                    <InputLabel htmlFor="email">EMAIL*</InputLabel>
                                    <Input
                                        id="email"
                                        className='login-form-email'
                                        label="Correo electrónico"
                                        required
                                        name="email"
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                email: e.target.value,
                                            });
                                        }}
                                        value={formData.email}
                                    />
                                    {errors.errorEmail && <span style={{color: "red"}}>{errors.errorEmailMsg}</span>}
                                </FormControl>
                                <FormControl sx={{ m: 1, marginBottom:"20px"}} variant="standard" className="login-form-container-field">
                                    <InputLabel htmlFor="ntelefono">TELÉFONO*</InputLabel>
                                    <Input
                                        id="ntelefono"
                                        className='login-form-email'
                                        label="Teléfono"
                                        required
                                        name="ntelefono"
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                nTelefono: e.target.value,
                                            });
                                        }}
                                        value={formData.nTelefono}
                                    />
                                    {errors.errorPhone && <span style={{color: "red"}}>{errors.errorPhoneMsg}</span>}
                                </FormControl>
                                <FormControl sx={{ m: 1, marginBottom: "50px"}} variant="standard" className="login-form-container-field">
                                    <InputLabel htmlFor="password">CONTRASEÑA*</InputLabel>
                                    <Input
                                        id="password"
                                        required
                                        className='login-form-passwd'
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                password: e.target.value,
                                            });
                                        }}
                                        value={formData.password}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Contraseña"
                                    />
                                    {errors.errorPassword && <span style={{color: "red"}}>{errors.errorPasswordMsg}</span>}
                                </FormControl>
                                <div className="public-join-field c-box-1" style={{lineHeight:"1.5"}}>
                                    <div className="checkbox">
                                        <input type="checkbox" id="overAgejoin" name="overAge" onChange={(e) => { setOverAge(!overAge); setErrors(errors => ({ ...errors, errorOverAge: false, errorOverAgeMsg: "" })); } } required />
                                        <label htmlFor="overAge">Soy mayor de 18 años.</label>
                                    </div>
                                    {errors.errorOverAge && <span style={{ color: "red", fontWeight: "600", fontSize: "14px" }}>{errors.errorOverAgeMsg}</span>}
                                </div>
                                
                                <div className="public-join-field c-box-1" style={{lineHeight:"1.5"}}>
                                    <div className="checkbox">
                                        <input type="checkbox" id="termsjoin" name="terms" onChange={(e) => { setTerms(!terms); setErrors(errors => ({ ...errors, errorTerms: false, errorTermsMsg: "" })); } } required />
                                        <label htmlFor="terms">Al registrarte declaras aceptar las
                                            <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CONDICIONES_GENERALES_DE_USO.pdf")}> Condiciones Generales de Uso</Link>
                                            , así como la
                                            <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/POLITICA_DE_PRIVACIDAD.pdf")}> Política de privacidad </Link>
                                            y la
                                            <Link onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CONDICIONES_PARTICULARES_DE_CONTRATACION.pdf")}> Política de contratación </Link>
                                            .</label>
                                    </div>
                                    {errors.errorTerms && <span style={{ color: "red", fontWeight: "600", fontSize: "14px" }}>{errors.errorTermsMsg}</span>}
                                </div>

                                <div className="public-join-field c-box-1" style={{lineHeight:"1.5"}}>
                                    <div className="checkbox">
                                        <input type="checkbox" id="suscription" name="suscription" onChange={(e) => { changeSuscription() } } required />
                                        <label htmlFor="suscription">Deseo suscribirme a la newsletter del Club Tierra de Caza y recibir toda la información y noticias relaccionadas con el mismo, vía correo electrónico.</label>
                                    </div>
                                </div>
                            </div>
                            {loading ? 
                                (<Box align="center">
                                    <CircularProgress />
                                </Box>
                                ) : (
                                    <Button
                                    type="submit"
                                    className="login-form-buttom"
                                    >
                                    Registrate
                                    </Button>
                                )
                            }
                        <Grid container sx={{ marginTop: "20px"}}>
                            <Grid item xs>
                                <Typography variant="caption" sx={{color: "#828282"}}>
                                    ¿Ya tienes una cuenta?
                                </Typography>
                                <Link href="/login" variant="body2" sx={{color: "#D99B72", marginBottom:"5px", marginLeft:"8px", textDecoration: "none"}}>
                                    Acceso
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
        </>
                
    );
};

export default FirstForm;